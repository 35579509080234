export const DASHBOARD_FIRST_LOADING = 'DASHBOARD_FIRST_LOADING';
export const DASHBOARD_FIRST_SUCCESS = 'DASHBOARD_FIRST_SUCCESS';
export const DASHBOARD_FIRST_ERROR = 'DASHBOARD_FIRST_ERROR';

export const DASHBOARD_SECOND_LOADING = 'DASHBOARD_SECOND_LOADING';
export const DASHBOARD_SECOND_SUCCESS = 'DASHBOARD_SECOND_SUCCESS';
export const DASHBOARD_SECOND_ERROR = 'DASHBOARD_SECOND_ERROR';

export const DASHBOARD_CHART_LOADING = 'DASHBOARD_CHART_LOADING';
export const DASHBOARD_CHART_SUCCESS = 'DASHBOARD_CHART_SUCCESS';
export const DASHBOARD_CHART_ERROR = 'DASHBOARD_CHART_ERROR';

export const DASHBOARD_TOP_ITEMS_LOADING = 'DASHBOARD_TOP_ITEMS_LOADING';
export const DASHBOARD_TOP_ITEMS_SUCCESS = 'DASHBOARD_TOP_ITEMS_SUCCESS';
export const DASHBOARD_TOP_ITEMS_ERROR = 'DASHBOARD_TOP_ITEMS_ERROR';
