import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ImageIcon from '@material-ui/icons/Image';
import MovieIcon from '@material-ui/icons/Movie';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, MenuItem, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { getSearchUsersAction } from '../../store/user/asyncActions';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import { createPopupsAction, deletePopupsAction, getAllPopupsAction, updatePopupsAction } from '../../store/popup/asyncActions';
import { getConfigDataAction, setHowItWorkVideoAction } from '../../store/configData/asyncActions';
import { getAllPermissionAction } from '../../store/role/asyncActions';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function HowItWorkVideo() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const configData = useSelector((state) => state?.configData?.getConfigData);
  const setHowItWorkVideo = useSelector((state) => state?.configData?.setHowItWorkVideo);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    document.title = 'How it work video - Referral App';
    dispatch(getConfigDataAction({ key: 'how_it_works_image' }));
    dispatch(getConfigDataAction({ key: 'how_it_works_video' }));
  }, []);

  useEffect(() => {
    if (configData?.data?.status && configData?.data?.data) {
      if (Array.isArray(configData?.data?.data)) {
        if (configData?.data?.data?.[0]?.key === 'how_it_works_image') {
          setFormData({
            ...formData,
            image: configData?.data?.data?.[0]?.value || null
          })
        }
        if (configData?.data?.data?.[0]?.key === 'how_it_works_video') {
          setFormData({
            ...formData,
            video: configData?.data?.data?.[0]?.value || null
          })
        }
      }
    }
  }, [configData]);

  useEffect(() => {
    if (setHowItWorkVideo?.data?.status) {
      dispatch(getConfigDataAction({ key: 'how_it_works_video' }));
      resetForm();
      setHowItWorkVideo.data = null;
    }
  }, [setHowItWorkVideo]);

  const [formData, setFormData] = useState({
    image: null,
    video: null,
  });

  const resetForm = () => {
    setVideoPreview(null);
    setErrors({});
    setFormData({
      image: null,
      video: null,
    });
  }

  const dataHandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event?.target?.files[0]
    });
  };

  useEffect(() => {
    if (formData?.image !== '' && formData?.image !== 'remove' && formData?.image !== null) {
      if (typeof formData?.image === 'string') {
        setImagePreview(process.env.REACT_APP_API_URL + formData?.image);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(formData?.image);
      }
    }
    // if (formData?.image === 'remove') {
    //   save();
    // }
  }, [formData?.image]);

  useEffect(() => {
    if (formData?.video !== '' && formData?.video !== 'remove' && formData?.video !== null) {
      if (typeof formData?.video === 'string') {
        setVideoPreview(process.env.REACT_APP_API_URL + formData?.video);
      } else {
        const videoURL = URL.createObjectURL(formData?.video);
        setVideoPreview(videoURL);
      }
    }
    // if (formData?.video === 'remove') {
    //   save();
    // }
  }, [formData?.video]);

  const removeFile = (key) => {
    setFormData({
      ...formData,
      [key]: 'remove'
    });
    if (key === 'image') {
      setImagePreview(null);
    }
    if (key === 'video') {
      setVideoPreview(null);
    }
  }

  const setFormDataFunc = () => {
    const Data = new FormData();
    Data.append('image', formData?.image);
    Data.append('video', formData?.video);
    return Data;
  }

  const setVideo = () => {
    const data = setFormDataFunc();
    dispatch(setHowItWorkVideoAction(data))
  }

  const [errors, setErrors] = useState({});
  const validationSchema = Joi.object({
    image: Joi.allow(null, ''),
    video: Joi.allow(null, ''),
  });

  const save = () => {
    const validationResult = validationSchema.validate(formData, { abortEarly: false });
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
    } else {
      setErrors({});
      setVideo()
    }
  }

  return (
    <div>

      <AppBar position="static" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant='h5' color="textSecondary" className="flexSpacer">
            How it work video
          </Typography>
        </Toolbar>
      </AppBar>

      {!imagePreview ?
        adminPermission && (adminPermission.includes('Add / Update VIdeo') || adminPermission.includes('All')) &&
        <Grid item xs={12} sm={12} md={12} xl={12} className='my-1 px-1'>
          <div className='fileDragArea' style={{ height: '185px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <input type="file" name='image' accept="image/*" onChange={(e) => handleFileChange(e)} />
            <div className='iconDiv'><ImageIcon /></div>
            <div className='fileupTxt'>Drag & Drop to upload - video thumbnail</div>
            <div className='fileupTxt'>or</div>
            <div style={{ paddingTop: '10px' }}>
              <Button color="primary" variant='contained'>
                Browse file
              </Button>
            </div>
          </div>
        </Grid>
        :
        <Grid item xs={12} sm={12} md={12} xl={12} className='my-1 px-1'>
          <div className='fileDragArea' style={{ height: '185px' }}>
            <div className='imgPrevOuter'>
              <img src={imagePreview} alt="Image Preview" />
              {adminPermission && (adminPermission.includes('Add / Update VIdeo') || adminPermission.includes('All')) &&
                <div className='imgRemove' onClick={() => removeFile('image')}>
                  <CloseIcon />
                </div>
              }
            </div>
          </div>
        </Grid>
      }

      {!videoPreview ?
        adminPermission && (adminPermission.includes('Add / Update VIdeo') || adminPermission.includes('All')) &&
        <Grid item xs={12} sm={12} md={12} xl={12} className='my-2 px-1'>
          <div className='fileDragArea' style={{ height: '185px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <input type="file" name='video' accept="video/*" onChange={(e) => handleFileChange(e)} />
            <div className='iconDiv'><MovieIcon /></div>
            <div className='fileupTxt'>Drag & Drop to upload - video</div>
            <div className='fileupTxt'>or</div>
            <div style={{ paddingTop: '10px' }}>
              <Button color="primary" variant='contained'>
                Browse file
              </Button>
            </div>
          </div>
        </Grid>
        :
        <Grid item xs={12} sm={12} md={12} xl={12} className='my-2 px-1'>
          <div className='fileDragArea' style={{ height: '185px' }}>
            <div className='imgPrevOuter'>
              <video controls style={{ height: '100%' }}>
                <source src={videoPreview} type="video/mp4" />
                <source src={videoPreview} type="video/webm" />
                <source src={videoPreview} type="video/wmv" />
                <source src={videoPreview} type="video/ogg" />
                <source src={videoPreview} type="video/avi" />
                <source src={videoPreview} type="video/m4v" />
                <source src={videoPreview} type="video/mov" />
                <source src={videoPreview} type="video/mpeg" />
                <source src={videoPreview} type="video/3gpp" />
                <source src={videoPreview} type="video/x-matroska" />
                <source src={videoPreview} type="video/quicktime" />
                <source src={videoPreview} type="video/x-flv" />
                Your browser does not support the video tag.
              </video>
              {adminPermission && (adminPermission.includes('Add / Update VIdeo') || adminPermission.includes('All')) &&
                <div className='imgRemove' onClick={() => removeFile('video')}>
                  <CloseIcon />
                </div>
              }
            </div>
          </div>
        </Grid>
      }
      {adminPermission && (adminPermission.includes('Add / Update VIdeo') || adminPermission.includes('All')) &&
        <div className='my-2' style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" color="primary" onClick={save}>
            Confirm & Save
          </Button>
        </div>
      }
    </div>
  );
};

export default HowItWorkVideo
