import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { adminLoginAction } from "../../store/admin/asyncActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { isValidEmail } from "../../helpers";
import Joi from "joi";

const useStyles = makeStyles(theme => ({
  card: {
    overflow: "visible"
  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  background: {
    backgroundColor: theme.palette.primary.main
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center"
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto"
  },
  fullWidth: {
    width: "100%"
  },
  logo: {
    display: "flex",
    flexDirection: "column"
  }
}));

const Signin = () => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const adminLoginData = useSelector((state) => state?.admin?.adminLogin);

  useEffect(() => {
    document.title = 'Sign in - Referral App';
  }, []);

  useEffect(() => {
    if (adminLoginData?.data?.success) {
      localStorage.setItem('id', adminLoginData?.data?.success?._id)
      localStorage.setItem('AccessToken', adminLoginData?.data?.success?.token)
      adminLoginData.data = null;
      history.push('/');
    }
  }, [adminLoginData]);

  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: ''
  });

  const loginDataHandleChange = (e) => {
    setLoginFormData({
      ...loginFormData,
      [e.target.name]: e.target.value
    });
  }

  const [errors, setErrors] = useState({});
  const schema = Joi.object({
    email: Joi.string().email({ tlds: { allow: false } }).required().label('Email'),
    password: Joi.string().min(8).required().label('Password'),
  });

  const login = (e) => {
    e.preventDefault();

    const validationResult = schema.validate(loginFormData, { abortEarly: false });

    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
    } else {
      setErrors({});
      dispatch(adminLoginAction(loginFormData));
    }
  }

  return (
    <div className={classNames(classes.session, classes.background)}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <Card>
            <CardContent>
              <form onSubmit={(e) => { login(e) }}>
                <div
                  className={classNames(classes.logo, `text-xs-center pb-xs`)}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/static/images/logo-dark.png`}
                    alt=""
                    className="block"
                  />
                  <Typography variant="caption">
                    Sign in with your app id to continue.
                  </Typography>
                </div>
                <TextField
                  name="email"
                  label="E-Mail"
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  value={loginFormData?.email}
                  error={errors?.email}
                  helperText={errors?.email}
                  onChange={(e) => loginDataHandleChange(e)}
                />
                <TextField
                  name="password"
                  label="Password"
                  className={classes.textField}
                  type="password"
                  fullWidth
                  margin="normal"
                  value={loginFormData?.password}
                  error={errors?.password}
                  helperText={errors?.password}
                  onChange={(e) => loginDataHandleChange(e)}
                />
                {/* <FormControlLabel
                  control={<Checkbox value="checkedA" />}
                  label="Stayed logged in"
                  className={classes.fullWidth}
                /> */}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={adminLoginData?.loading ? true : false}
                >
                  Login
                </Button>
                <div className="pt-1 text-md-center">
                  <Link to="/forgot">
                    <Button>Forgot password?</Button>
                  </Link>
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/signup">
                    <Button>Create new account.</Button>
                  </Link> */}
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Signin;
