import { creditDebitAmountError, creditDebitAmountLoading, creditDebitAmountSuccess, getUserWalletLedgerError, getUserWalletLedgerLoading, getUserWalletLedgerSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getUserWalletLedgerAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getUserWalletLedgerLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('user-wallet/ledger', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getUserWalletLedgerSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getUserWalletLedgerError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const creditDebitAmountAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(creditDebitAmountLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('user-wallet/credit-debit', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(creditDebitAmountSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(creditDebitAmountError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getUserWalletLedgerAction,
  creditDebitAmountAction
};
