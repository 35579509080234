import { ADMIN_CREATE_USER_ERROR, ADMIN_CREATE_USER_LOADING, ADMIN_CREATE_USER_SUCCESS, ADMIN_DELETE_USER_ERROR, ADMIN_DELETE_USER_LOADING, ADMIN_DELETE_USER_SUCCESS, ADMIN_GET_ALL_USERS_ERROR, ADMIN_GET_ALL_USERS_LOADING, ADMIN_GET_ALL_USERS_SUCCESS, ADMIN_LOGIN_ERROR, ADMIN_LOGIN_LOADING, ADMIN_LOGIN_SUCCESS, ADMIN_UPDATE_USER_ERROR, ADMIN_UPDATE_USER_LOADING, ADMIN_UPDATE_USER_PROFILE_ERROR, ADMIN_UPDATE_USER_PROFILE_LOADING, ADMIN_UPDATE_USER_PROFILE_SUCCESS, ADMIN_UPDATE_USER_STATUS_ERROR, ADMIN_UPDATE_USER_STATUS_LOADING, ADMIN_UPDATE_USER_STATUS_SUCCESS, ADMIN_UPDATE_USER_SUCCESS, GET_ADMIN_BY_TOKEN_ERROR, GET_ADMIN_BY_TOKEN_LOADING, GET_ADMIN_BY_TOKEN_SUCCESS } from "./actionTypes";

const initialState = {
    adminLogin: {
        loading: false,
        data: null,
        error: null
    },
    adminUser: {
        loading: false,
        data: null,
        error: null
    },
    adminGetAllUsers: {
        loading: false,
        data: null,
        error: null
    },
    adminCreateUser: {
        loading: false,
        data: null,
        error: null
    },
    adminUpdateUser: {
        loading: false,
        data: null,
        error: null
    },
    adminUpdateUserProfile: {
        loading: false,
        data: null,
        error: null
    },
    adminUpdateUserStatus: {
        loading: false,
        data: null,
        error: null
    },
    adminDeleteUser: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_LOGIN_LOADING:
            return { ...state, adminLogin: { ...state.adminLogin, loading: true, data: null, error: null } };
        case ADMIN_LOGIN_SUCCESS:
            return { ...state, adminLogin: { ...state.adminLogin, loading: false, data: action.payload, error: null } };
        case ADMIN_LOGIN_ERROR:
            return { ...state, adminLogin: { ...state.adminLogin, loading: false, data: null, error: action.payload } };

        case GET_ADMIN_BY_TOKEN_LOADING:
            return { ...state, adminUser: { ...state.adminUser, loading: true, data: null, error: null } };
        case GET_ADMIN_BY_TOKEN_SUCCESS:
            return { ...state, adminUser: { ...state.adminUser, loading: false, data: action.payload, error: null } };
        case GET_ADMIN_BY_TOKEN_ERROR:
            return { ...state, adminUser: { ...state.adminUser, loading: false, data: null, error: action.payload } };

        case ADMIN_GET_ALL_USERS_LOADING:
            return { ...state, adminGetAllUsers: { ...state.adminGetAllUsers, loading: true, data: null, error: null } };
        case ADMIN_GET_ALL_USERS_SUCCESS:
            return { ...state, adminGetAllUsers: { ...state.adminGetAllUsers, loading: false, data: action.payload, error: null } };
        case ADMIN_GET_ALL_USERS_ERROR:
            return { ...state, adminGetAllUsers: { ...state.adminGetAllUsers, loading: false, data: null, error: action.payload } };

        case ADMIN_CREATE_USER_LOADING:
            return { ...state, adminCreateUser: { ...state.adminCreateUser, loading: true, data: null, error: null } };
        case ADMIN_CREATE_USER_SUCCESS:
            return { ...state, adminCreateUser: { ...state.adminCreateUser, loading: false, data: action.payload, error: null } };
        case ADMIN_CREATE_USER_ERROR:
            return { ...state, adminCreateUser: { ...state.adminCreateUser, loading: false, data: null, error: action.payload } };

        case ADMIN_UPDATE_USER_LOADING:
            return { ...state, adminUpdateUser: { ...state.adminUpdateUser, loading: true, data: null, error: null } };
        case ADMIN_UPDATE_USER_SUCCESS:
            return { ...state, adminUpdateUser: { ...state.adminUpdateUser, loading: false, data: action.payload, error: null } };
        case ADMIN_UPDATE_USER_ERROR:
            return { ...state, adminUpdateUser: { ...state.adminUpdateUser, loading: false, data: null, error: action.payload } };

        case ADMIN_UPDATE_USER_PROFILE_LOADING:
            return { ...state, adminUpdateUserProfile: { ...state.adminUpdateUserProfile, loading: true, data: null, error: null } };
        case ADMIN_UPDATE_USER_PROFILE_SUCCESS:
            return { ...state, adminUpdateUserProfile: { ...state.adminUpdateUserProfile, loading: false, data: action.payload, error: null } };
        case ADMIN_UPDATE_USER_PROFILE_ERROR:
            return { ...state, adminUpdateUserProfile: { ...state.adminUpdateUserProfile, loading: false, data: null, error: action.payload } };

        case ADMIN_UPDATE_USER_STATUS_LOADING:
            return { ...state, adminUpdateUserStatus: { ...state.adminUpdateUserStatus, loading: true, data: null, error: null } };
        case ADMIN_UPDATE_USER_STATUS_SUCCESS:
            return { ...state, adminUpdateUserStatus: { ...state.adminUpdateUserStatus, loading: false, data: action.payload, error: null } };
        case ADMIN_UPDATE_USER_STATUS_ERROR:
            return { ...state, adminUpdateUserStatus: { ...state.adminUpdateUserStatus, loading: false, data: null, error: action.payload } };

        case ADMIN_DELETE_USER_LOADING:
            return { ...state, adminDeleteUser: { ...state.adminDeleteUser, loading: true, data: null, error: null } };
        case ADMIN_DELETE_USER_SUCCESS:
            return { ...state, adminDeleteUser: { ...state.adminDeleteUser, loading: false, data: action.payload, error: null } };
        case ADMIN_DELETE_USER_ERROR:
            return { ...state, adminDeleteUser: { ...state.adminDeleteUser, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
