// rootReducer.js
import { combineReducers } from 'redux';
import dashboardReducer from './dashboard/reducer';
import adminReducer from './admin/reducer';
import roleReducer from './role/reducer';
import categoryReducer from './category/reducer';
import itmeReducer from './item/reducer';
import userReducer from './user/reducer';
import referralReducer from './referral/reducer';
import notificationReducer from './notification/reducer';
import popupReducer from './popup/reducer';
import helpReducer from './help/reducer';
import withdrawReqReducer from './withdraw-request/reducer';
import activityLogReducer from './activity-log/reducer';
import configDataReducer from './configData/reducer';
import walletReducer from './wallet/reducer';
import refundReqReducer from './refund-request/reducer';

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  admin: adminReducer,
  role: roleReducer,
  category: categoryReducer,
  item: itmeReducer,
  user: userReducer,
  referral: referralReducer,
  notification: notificationReducer,
  popup: popupReducer,
  help: helpReducer,
  withdrawReq: withdrawReqReducer,
  activityLog: activityLogReducer,
  configData: configDataReducer,
  wallet: walletReducer,
  refundReq: refundReqReducer
});

export default rootReducer;
