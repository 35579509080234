import { COMPLETE_WITHDRAWREQ_ERROR, COMPLETE_WITHDRAWREQ_LOADING, COMPLETE_WITHDRAWREQ_SUCCESS, CREATE_WITHDRAWREQ_ERROR, CREATE_WITHDRAWREQ_LOADING, CREATE_WITHDRAWREQ_SUCCESS, DELETE_WITHDRAWREQ_ERROR, DELETE_WITHDRAWREQ_LOADING, DELETE_WITHDRAWREQ_SUCCESS, GET_ALL_WITHDRAWREQ_ERROR, GET_ALL_WITHDRAWREQ_LOADING, GET_ALL_WITHDRAWREQ_SUCCESS, GET_USER_WALLET_ERROR, GET_USER_WALLET_LOADING, GET_USER_WALLET_SUCCESS, UPDATE_WITHDRAWREQ_STATUS_ERROR, UPDATE_WITHDRAWREQ_STATUS_LOADING, UPDATE_WITHDRAWREQ_STATUS_SUCCESS } from "./actionTypes";

const initialState = {
    allWithdrawReq: {
        loading: false,
        data: null,
        error: null
    },
    createWithdrawReq: {
        loading: false,
        data: null,
        error: null
    },
    updateWithdrawReqStatus: {
        loading: false,
        data: null,
        error: null
    },
    deleteWithdrawReq: {
        loading: false,
        data: null,
        error: null
    },
    userWallet: {
        loading: false,
        data: null,
        error: null
    },
    completedWithdrawReq: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_WITHDRAWREQ_LOADING:
            return { ...state, allWithdrawReq: { ...state.allWithdrawReq, loading: true, data: null, error: null } };
        case GET_ALL_WITHDRAWREQ_SUCCESS:
            return { ...state, allWithdrawReq: { ...state.allWithdrawReq, loading: false, data: action.payload, error: null } };
        case GET_ALL_WITHDRAWREQ_ERROR:
            return { ...state, allWithdrawReq: { ...state.allWithdrawReq, loading: false, data: null, error: action.payload } };

        case CREATE_WITHDRAWREQ_LOADING:
            return { ...state, createWithdrawReq: { ...state.createWithdrawReq, loading: true, data: null, error: null } };
        case CREATE_WITHDRAWREQ_SUCCESS:
            return { ...state, createWithdrawReq: { ...state.createWithdrawReq, loading: false, data: action.payload, error: null } };
        case CREATE_WITHDRAWREQ_ERROR:
            return { ...state, createWithdrawReq: { ...state.createWithdrawReq, loading: false, data: null, error: action.payload } };

        case UPDATE_WITHDRAWREQ_STATUS_LOADING:
            return { ...state, updateWithdrawReqStatus: { ...state.updateWithdrawReqStatus, loading: true, data: null, error: null } };
        case UPDATE_WITHDRAWREQ_STATUS_SUCCESS:
            return { ...state, updateWithdrawReqStatus: { ...state.updateWithdrawReqStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_WITHDRAWREQ_STATUS_ERROR:
            return { ...state, updateWithdrawReqStatus: { ...state.updateWithdrawReqStatus, loading: false, data: null, error: action.payload } };

        case DELETE_WITHDRAWREQ_LOADING:
            return { ...state, deleteWithdrawReq: { ...state.deleteWithdrawReq, loading: true, data: null, error: null } };
        case DELETE_WITHDRAWREQ_SUCCESS:
            return { ...state, deleteWithdrawReq: { ...state.deleteWithdrawReq, loading: false, data: action.payload, error: null } };
        case DELETE_WITHDRAWREQ_ERROR:
            return { ...state, deleteWithdrawReq: { ...state.deleteWithdrawReq, loading: false, data: null, error: action.payload } };

        case GET_USER_WALLET_LOADING:
            return { ...state, userWallet: { ...state.userWallet, loading: true, data: null, error: null } };
        case GET_USER_WALLET_SUCCESS:
            return { ...state, userWallet: { ...state.userWallet, loading: false, data: action.payload, error: null } };
        case GET_USER_WALLET_ERROR:
            return { ...state, userWallet: { ...state.userWallet, loading: false, data: null, error: action.payload } };

        case COMPLETE_WITHDRAWREQ_LOADING:
            return { ...state, completedWithdrawReq: { ...state.completedWithdrawReq, loading: true, data: null, error: null } };
        case COMPLETE_WITHDRAWREQ_SUCCESS:
            return { ...state, completedWithdrawReq: { ...state.completedWithdrawReq, loading: false, data: action.payload, error: null } };
        case COMPLETE_WITHDRAWREQ_ERROR:
            return { ...state, completedWithdrawReq: { ...state.completedWithdrawReq, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
