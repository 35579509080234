import { GET_ALL_ROLES_LOADING, GET_ALL_ROLES_SUCCESS, GET_ALL_ROLES_ERROR, CREATE_ROLE_LOADING, CREATE_ROLE_SUCCESS, CREATE_ROLE_ERROR, UPDATE_ROLE_LOADING, UPDATE_ROLE_SUCCESS, UPDATE_ROLE_ERROR, DELETE_ROLE_LOADING, DELETE_ROLE_SUCCESS, DELETE_ROLE_ERROR, UPDATE_ROLE_STATUS_LOADING, UPDATE_ROLE_STATUS_SUCCESS, UPDATE_ROLE_STATUS_ERROR, SEARCH_ROLE_LOADING, SEARCH_ROLE_SUCCESS, SEARCH_ROLE_ERROR, GET_ALL_PERMISSIONS_LOADING, GET_ALL_PERMISSIONS_SUCCESS, GET_ALL_PERMISSIONS_ERROR } from './actionTypes';

// get all Roles
const getAllRolesLoading = () => {
  return {
    type: GET_ALL_ROLES_LOADING,
  };
};

const getAllRolesSuccess = (data) => {
  return {
    type: GET_ALL_ROLES_SUCCESS,
    payload: data,
  };
};

const getAllRolesError = (error) => {
  return {
    type: GET_ALL_ROLES_ERROR,
    payload: error,
  };
};

// get all Permissions
const getAllPermissionsLoading = () => {
  return {
    type: GET_ALL_PERMISSIONS_LOADING,
  };
};

const getAllPermissionsSuccess = (data) => {
  return {
    type: GET_ALL_PERMISSIONS_SUCCESS,
    payload: data,
  };
};

const getAllPermissionsError = (error) => {
  return {
    type: GET_ALL_PERMISSIONS_ERROR,
    payload: error,
  };
};

// create role
const createRoleLoading = () => {
  return {
    type: CREATE_ROLE_LOADING,
  };
};

const createRoleSuccess = (data) => {
  return {
    type: CREATE_ROLE_SUCCESS,
    payload: data,
  };
};

const createRoleError = (error) => {
  return {
    type: CREATE_ROLE_ERROR,
    payload: error,
  };
};

// update role
const updateRoleLoading = () => {
  return {
    type: UPDATE_ROLE_LOADING,
  };
};

const updateRoleSuccess = (data) => {
  return {
    type: UPDATE_ROLE_SUCCESS,
    payload: data,
  };
};

const updateRoleError = (error) => {
  return {
    type: UPDATE_ROLE_ERROR,
    payload: error,
  };
};

// update role status
const updateRoleStatusLoading = () => {
  return {
    type: UPDATE_ROLE_STATUS_LOADING,
  };
};

const updateRoleStatusSuccess = (data) => {
  return {
    type: UPDATE_ROLE_STATUS_SUCCESS,
    payload: data,
  };
};

const updateRoleStatusError = (error) => {
  return {
    type: UPDATE_ROLE_STATUS_ERROR,
    payload: error,
  };
};

// delete role
const deleteRoleLoading = () => {
  return {
    type: DELETE_ROLE_LOADING,
  };
};

const deleteRoleSuccess = (data) => {
  return {
    type: DELETE_ROLE_SUCCESS,
    payload: data,
  };
};

const deleteRoleError = (error) => {
  return {
    type: DELETE_ROLE_ERROR,
    payload: error,
  };
};

// search role
const searchRoleLoading = () => {
  return {
    type: SEARCH_ROLE_LOADING,
  };
};

const searchRoleSuccess = (data) => {
  return {
    type: SEARCH_ROLE_SUCCESS,
    payload: data,
  };
};

const searchRoleError = (error) => {
  return {
    type: SEARCH_ROLE_ERROR,
    payload: error,
  };
};

export { 
    getAllRolesLoading, 
    getAllRolesSuccess, 
    getAllRolesError,

    getAllPermissionsLoading, 
    getAllPermissionsSuccess, 
    getAllPermissionsError,

    createRoleLoading, 
    createRoleSuccess, 
    createRoleError,

    updateRoleLoading, 
    updateRoleSuccess, 
    updateRoleError,

    updateRoleStatusLoading, 
    updateRoleStatusSuccess, 
    updateRoleStatusError,

    deleteRoleLoading, 
    deleteRoleSuccess, 
    deleteRoleError,

    searchRoleLoading, 
    searchRoleSuccess, 
    searchRoleError
};
