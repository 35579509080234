import { CREATE_ITEM_ERROR, CREATE_ITEM_LOADING, CREATE_ITEM_SUCCESS, DELETE_ITEM_ERROR, DELETE_ITEM_LOADING, DELETE_ITEM_SUCCESS, EXPORT_ALL_CSV_ITEMS_ERROR, EXPORT_ALL_CSV_ITEMS_LOADING, EXPORT_ALL_CSV_ITEMS_SUCCESS, GET_ALL_ITEMS_ERROR, GET_ALL_ITEMS_LOADING, GET_ALL_ITEMS_SUCCESS, IMPORT_ALL_CSV_ITEMS_ERROR, IMPORT_ALL_CSV_ITEMS_LOADING, IMPORT_ALL_CSV_ITEMS_SUCCESS, SEARCH_ITEM_ERROR, SEARCH_ITEM_LOADING, SEARCH_ITEM_SUCCESS, UPDATE_ITEM_ERROR, UPDATE_ITEM_LOADING, UPDATE_ITEM_STATUS_ERROR, UPDATE_ITEM_STATUS_LOADING, UPDATE_ITEM_STATUS_SUCCESS, UPDATE_ITEM_SUCCESS } from "./actionTypes";

const initialState = {
    getAllItems: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvItems: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvImportItems: {
        loading: false,
        data: null,
        error: null
    },
    createItem: {
        loading: false,
        data: null,
        error: null
    },
    updateItem: {
        loading: false,
        data: null,
        error: null
    },
    updateItemStatus: {
        loading: false,
        data: null,
        error: null
    },
    deleteItem: {
        loading: false,
        data: null,
        error: null
    },
    searchItem: {
        loading: false,
        data: null,
        error: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_ITEMS_LOADING:
            return { ...state, getAllItems: { ...state.getAllItems, loading: true, data: null, error: null } };
        case GET_ALL_ITEMS_SUCCESS:
            return { ...state, getAllItems: { ...state.getAllItems, loading: false, data: action.payload, error: null } };
        case GET_ALL_ITEMS_ERROR:
            return { ...state, getAllItems: { ...state.getAllItems, loading: false, data: null, error: action.payload } };

        case EXPORT_ALL_CSV_ITEMS_LOADING:
            return { ...state, getAllCsvItems: { ...state.getAllCsvItems, loading: true, data: null, error: null } };
        case EXPORT_ALL_CSV_ITEMS_SUCCESS:
            return { ...state, getAllCsvItems: { ...state.getAllCsvItems, loading: false, data: action.payload, error: null } };
        case EXPORT_ALL_CSV_ITEMS_ERROR:
            return { ...state, getAllCsvItems: { ...state.getAllCsvItems, loading: false, data: null, error: action.payload } };

        case IMPORT_ALL_CSV_ITEMS_LOADING:
            return { ...state, getAllCsvImportItems: { ...state.getAllCsvImportItems, loading: true, data: null, error: null } };
        case IMPORT_ALL_CSV_ITEMS_SUCCESS:
            return { ...state, getAllCsvImportItems: { ...state.getAllCsvImportItems, loading: false, data: action.payload, error: null } };
        case IMPORT_ALL_CSV_ITEMS_ERROR:
            return { ...state, getAllCsvImportItems: { ...state.getAllCsvImportItems, loading: false, data: null, error: action.payload } };

        case CREATE_ITEM_LOADING:
            return { ...state, createItem: { ...state.createItem, loading: true, data: null, error: null } };
        case CREATE_ITEM_SUCCESS:
            return { ...state, createItem: { ...state.createItem, loading: false, data: action.payload, error: null } };
        case CREATE_ITEM_ERROR:
            return { ...state, createItem: { ...state.createItem, loading: false, data: null, error: action.payload } };

        case UPDATE_ITEM_LOADING:
            return { ...state, updateItem: { ...state.updateItem, loading: true, data: null, error: null } };
        case UPDATE_ITEM_SUCCESS:
            return { ...state, updateItem: { ...state.updateItem, loading: false, data: action.payload, error: null } };
        case UPDATE_ITEM_ERROR:
            return { ...state, updateItem: { ...state.updateItem, loading: false, data: null, error: action.payload } };

        case UPDATE_ITEM_STATUS_LOADING:
            return { ...state, updateItemStatus: { ...state.updateItemStatus, loading: true, data: null, error: null } };
        case UPDATE_ITEM_STATUS_SUCCESS:
            return { ...state, updateItemStatus: { ...state.updateItemStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_ITEM_STATUS_ERROR:
            return { ...state, updateItemStatus: { ...state.updateItemStatus, loading: false, data: null, error: action.payload } };

        case DELETE_ITEM_LOADING:
            return { ...state, deleteItem: { ...state.deleteItem, loading: true, data: null, error: null } };
        case DELETE_ITEM_SUCCESS:
            return { ...state, deleteItem: { ...state.deleteItem, loading: false, data: action.payload, error: null } };
        case DELETE_ITEM_ERROR:
            return { ...state, deleteItem: { ...state.deleteItem, loading: false, data: null, error: action.payload } };

        case SEARCH_ITEM_LOADING:
            return { ...state, searchItem: { ...state.searchItem, loading: true, data: null, error: null } };
        case SEARCH_ITEM_SUCCESS:
            return { ...state, searchItem: { ...state.searchItem, loading: false, data: action.payload, error: null } };
        case SEARCH_ITEM_ERROR:
            return { ...state, searchItem: { ...state.searchItem, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
