import { getAllRolesLoading, getAllRolesSuccess, getAllRolesError, createRoleLoading, createRoleSuccess, createRoleError, updateRoleLoading, updateRoleSuccess, updateRoleError, deleteRoleLoading, deleteRoleSuccess, deleteRoleError, updateRoleStatusLoading, updateRoleStatusSuccess, updateRoleStatusError, searchRoleLoading, searchRoleSuccess, searchRoleError, getAllPermissionsLoading, getAllPermissionsSuccess, getAllPermissionsError } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllRolesAction = (page, size) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllRolesLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.get(`role/get/all?page=${page}&size=${size}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllRolesSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllRolesError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const getAllPermissionAction = () => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllPermissionsLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.get(`role/get/all/permissions`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllPermissionsSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllPermissionsError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const createRoleAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(createRoleLoading());
      const response = await api.post('role/create', postData);
      const data = response.data;
      dispatch(createRoleSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(createRoleError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateRoleAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateRoleLoading());
      const response = await api.post('role/update', postData);
      const data = response.data;
      dispatch(updateRoleSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateRoleError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateRoleStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateRoleStatusLoading());
      const response = await api.post('role/update/status', postData);
      const data = response.data;
      dispatch(updateRoleStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateRoleStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteRoleAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(deleteRoleLoading());
      const response = await api.post('role/delete', postData);
      const data = response.data;
      dispatch(deleteRoleSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(deleteRoleError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchRoleAction = (qry) => {
  return async (dispatch) => {
    try {
      dispatch(searchRoleLoading());
      const response = await api.get(`role/search?search_query=${qry}`);
      const data = response.data;
      dispatch(searchRoleSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchRoleError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllRolesAction,
  getAllPermissionAction,
  createRoleAction,
  updateRoleAction,
  updateRoleStatusAction,
  deleteRoleAction,
  searchRoleAction
};
