import { createNotificationError, createNotificationLoading, createNotificationSuccess, deleteNotificationError, deleteNotificationLoading, deleteNotificationSuccess, getAllNotificationError, getAllNotificationLoading, getAllNotificationSuccess, updateNotificationError, updateNotificationLoading, updateNotificationSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllNotificationsAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllNotificationLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&from=${filter?.from}&to=${filter?.to}&title=${filter?.title}`;
      const response = await api.get(`notification/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllNotificationSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllNotificationError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createNotificationsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(createNotificationLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('notification/create', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(createNotificationSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(createNotificationError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateNotificationsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(updateNotificationLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('notification/update', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(updateNotificationSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(updateNotificationError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteNotificationsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(deleteNotificationLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('notification/delete', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(deleteNotificationSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(deleteNotificationError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllNotificationsAction,
  createNotificationsAction,
  updateNotificationsAction,
  deleteNotificationsAction
};
