export const GET_ALL_WITHDRAWREQ_LOADING = 'GET_ALL_WITHDRAWREQ_LOADING';
export const GET_ALL_WITHDRAWREQ_SUCCESS = 'GET_ALL_WITHDRAWREQ_SUCCESS';
export const GET_ALL_WITHDRAWREQ_ERROR = 'GET_ALL_WITHDRAWREQ_ERROR';

export const CREATE_WITHDRAWREQ_LOADING = 'CREATE_WITHDRAWREQ_LOADING';
export const CREATE_WITHDRAWREQ_SUCCESS = 'CREATE_WITHDRAWREQ_SUCCESS';
export const CREATE_WITHDRAWREQ_ERROR = 'CREATE_WITHDRAWREQ_ERROR';

export const UPDATE_WITHDRAWREQ_STATUS_LOADING = 'UPDATE_WITHDRAWREQ_STATUS_LOADING';
export const UPDATE_WITHDRAWREQ_STATUS_SUCCESS = 'UPDATE_WITHDRAWREQ_STATUS_SUCCESS';
export const UPDATE_WITHDRAWREQ_STATUS_ERROR = 'UPDATE_WITHDRAWREQ_STATUS_ERROR';

export const DELETE_WITHDRAWREQ_LOADING = 'DELETE_WITHDRAWREQ_LOADING';
export const DELETE_WITHDRAWREQ_SUCCESS = 'DELETE_WITHDRAWREQ_SUCCESS';
export const DELETE_WITHDRAWREQ_ERROR = 'DELETE_WITHDRAWREQ_ERROR';

export const COMPLETE_WITHDRAWREQ_LOADING = 'COMPLETE_WITHDRAWREQ_LOADING';
export const COMPLETE_WITHDRAWREQ_SUCCESS = 'COMPLETE_WITHDRAWREQ_SUCCESS';
export const COMPLETE_WITHDRAWREQ_ERROR = 'COMPLETE_WITHDRAWREQ_ERROR';

export const GET_USER_WALLET_LOADING = 'GET_USER_WALLET_LOADING';
export const GET_USER_WALLET_SUCCESS = 'GET_USER_WALLET_SUCCESS';
export const GET_USER_WALLET_ERROR = 'GET_USER_WALLET_ERROR';
