import { completeWithdrawReqError, completeWithdrawReqLoading, completeWithdrawReqSuccess, createWithdrawReqError, createWithdrawReqLoading, createWithdrawReqSuccess, deleteWithdrawReqError, deleteWithdrawReqLoading, deleteWithdrawReqSuccess, getAllWithdrawReqError, getAllWithdrawReqLoading, getAllWithdrawReqSuccess, getUserWalletError, getUserWalletLoading, getUserWalletSuccess, updateWithdrawReqStatusError, updateWithdrawReqStatusLoading, updateWithdrawReqStatusSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllWithdrawReqsAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllWithdrawReqLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&from=${filter?.from}&to=${filter?.to}&mobile=${filter?.mobile}&status=${filter?.status}`;
      const response = await api.get(`withdraw-request/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllWithdrawReqSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllWithdrawReqError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createWithdrawReqsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(createWithdrawReqLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('withdraw-request/create', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(createWithdrawReqSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(createWithdrawReqError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateWithdrawReqsStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(updateWithdrawReqStatusLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('withdraw-request/update/status', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(updateWithdrawReqStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(updateWithdrawReqStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteWithdrawReqsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(deleteWithdrawReqLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('withdraw-request/delete', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(deleteWithdrawReqSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(deleteWithdrawReqError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const completeWithdrawReqsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(completeWithdrawReqLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('withdraw-request/complete', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(completeWithdrawReqSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(completeWithdrawReqError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getUserWalletAction = (userID) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getUserWalletLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('withdraw-request/get/wallet/by/id', { id: userID });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getUserWalletSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getUserWalletError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllWithdrawReqsAction,
  createWithdrawReqsAction,
  updateWithdrawReqsStatusAction,
  deleteWithdrawReqsAction,
  getUserWalletAction,
  completeWithdrawReqsAction
};
