import { GET_CONFIG_DATA_ERROR, GET_CONFIG_DATA_LOADING, GET_CONFIG_DATA_SUCCESS, SET_HOW_IT_WORK_VIDEO_ERROR, SET_HOW_IT_WORK_VIDEO_LOADING, SET_HOW_IT_WORK_VIDEO_SUCCESS } from "./actionTypes";

const initialState = {
    getConfigData: {
        loading: false,
        data: null,
        error: null
    },
    setHowItWorkVideo: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONFIG_DATA_LOADING:
            return { ...state, getConfigData: { ...state.getConfigData, loading: true, data: null, error: null } };
        case GET_CONFIG_DATA_SUCCESS:
            return { ...state, getConfigData: { ...state.getConfigData, loading: false, data: action.payload, error: null } };
        case GET_CONFIG_DATA_ERROR:
            return { ...state, getConfigData: { ...state.getConfigData, loading: false, data: null, error: action.payload } };

        case SET_HOW_IT_WORK_VIDEO_LOADING:
            return { ...state, setHowItWorkVideo: { ...state.setHowItWorkVideo, loading: true, data: null, error: null } };
        case SET_HOW_IT_WORK_VIDEO_SUCCESS:
            return { ...state, setHowItWorkVideo: { ...state.setHowItWorkVideo, loading: false, data: action.payload, error: null } };
        case SET_HOW_IT_WORK_VIDEO_ERROR:
            return { ...state, setHowItWorkVideo: { ...state.setHowItWorkVideo, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
