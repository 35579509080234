import { APPROVE_REFUNDREQ_ERROR, APPROVE_REFUNDREQ_LOADING, APPROVE_REFUNDREQ_SUCCESS, GET_ALL_REFUNDREQ_ERROR, GET_ALL_REFUNDREQ_LOADING, GET_ALL_REFUNDREQ_SUCCESS, UPDATE_REFUNDREQ_STATUS_ERROR, UPDATE_REFUNDREQ_STATUS_LOADING, UPDATE_REFUNDREQ_STATUS_SUCCESS } from './actionTypes';

// Refund req list
const getAllRefundReqLoading = () => {
  return {
    type: GET_ALL_REFUNDREQ_LOADING,
  };
};

const getAllRefundReqSuccess = (data) => {
  return {
    type: GET_ALL_REFUNDREQ_SUCCESS,
    payload: data,
  };
};

const getAllRefundReqError = (error) => {
  return {
    type: GET_ALL_REFUNDREQ_ERROR,
    payload: error,
  };
};

// update refund req status
const updateRefundReqStatusLoading = () => {
  return {
    type: UPDATE_REFUNDREQ_STATUS_LOADING,
  };
};

const updateRefundReqStatusSuccess = (data) => {
  return {
    type: UPDATE_REFUNDREQ_STATUS_SUCCESS,
    payload: data,
  };
};

const updateRefundReqStatusError = (error) => {
  return {
    type: UPDATE_REFUNDREQ_STATUS_ERROR,
    payload: error,
  };
};

// Approve refund req
const approveRefundReqLoading = () => {
  return {
    type: APPROVE_REFUNDREQ_LOADING,
  };
};

const approveRefundReqSuccess = (data) => {
  return {
    type: APPROVE_REFUNDREQ_SUCCESS,
    payload: data,
  };
};

const approveRefundReqError = (error) => {
  return {
    type: APPROVE_REFUNDREQ_ERROR,
    payload: error,
  };
};


export { 
    getAllRefundReqLoading, 
    getAllRefundReqSuccess, 
    getAllRefundReqError,

    updateRefundReqStatusLoading, 
    updateRefundReqStatusSuccess, 
    updateRefundReqStatusError,

    approveRefundReqLoading, 
    approveRefundReqSuccess, 
    approveRefundReqError,
};
