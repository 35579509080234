import { APPROVE_REFUNDREQ_ERROR, APPROVE_REFUNDREQ_LOADING, APPROVE_REFUNDREQ_SUCCESS, GET_ALL_REFUNDREQ_ERROR, GET_ALL_REFUNDREQ_LOADING, GET_ALL_REFUNDREQ_SUCCESS, UPDATE_REFUNDREQ_STATUS_ERROR, UPDATE_REFUNDREQ_STATUS_LOADING, UPDATE_REFUNDREQ_STATUS_SUCCESS } from "./actionTypes";

const initialState = {
    allRefundReqs: {
        loading: false,
        data: null,
        error: null
    },
    updateRefundReqStatus: {
        loading: false,
        data: null,
        error: null
    },
    approveRefundReq: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_REFUNDREQ_LOADING:
            return { ...state, allRefundReqs: { ...state.allRefundReqs, loading: true, data: null, error: null } };
        case GET_ALL_REFUNDREQ_SUCCESS:
            return { ...state, allRefundReqs: { ...state.allRefundReqs, loading: false, data: action.payload, error: null } };
        case GET_ALL_REFUNDREQ_ERROR:
            return { ...state, allRefundReqs: { ...state.allRefundReqs, loading: false, data: null, error: action.payload } };

        case UPDATE_REFUNDREQ_STATUS_LOADING:
            return { ...state, updateRefundReqStatus: { ...state.updateRefundReqStatus, loading: true, data: null, error: null } };
        case UPDATE_REFUNDREQ_STATUS_SUCCESS:
            return { ...state, updateRefundReqStatus: { ...state.updateRefundReqStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_REFUNDREQ_STATUS_ERROR:
            return { ...state, updateRefundReqStatus: { ...state.updateRefundReqStatus, loading: false, data: null, error: action.payload } };

        case APPROVE_REFUNDREQ_LOADING:
            return { ...state, approveRefundReq: { ...state.approveRefundReq, loading: true, data: null, error: null } };
        case APPROVE_REFUNDREQ_SUCCESS:
            return { ...state, approveRefundReq: { ...state.approveRefundReq, loading: false, data: action.payload, error: null } };
        case APPROVE_REFUNDREQ_ERROR:
            return { ...state, approveRefundReq: { ...state.approveRefundReq, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
