import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, MenuItem, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import { createNotificationsAction, deleteNotificationsAction, getAllNotificationsAction, updateNotificationsAction } from '../../store/notification/asyncActions';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { getSearchUsersAction } from '../../store/user/asyncActions';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { getAllPermissionAction } from '../../store/role/asyncActions';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function Notification_list() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);
  const [rowData, setRowData] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();

  const notificationListData = useSelector((state) => state?.notification?.allNotification);
  const newNotification = useSelector((state) => state?.notification?.createNotification);
  const updatedNotification = useSelector((state) => state?.notification?.updateNotification);
  const deletedNotification = useSelector((state) => state?.notification?.deleteNotification);

  const searchUserList = useSelector((state) => state?.user?.allSearchUser);
  const [notificationList, setNotificationList] = useState(null);
  const [users, setUsers] = useState(null);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    dispatch(getAllNotificationsAction(page + 1, pageSize, filter));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Notifications - Referral App';
    // dispatch(getAllNotificationsAction(page + 1, 10));
    dispatch(getSearchUsersAction())
  }, []);

  useEffect(() => {
    if (notificationListData?.data?.status && Array.isArray(notificationListData?.data?.data?.records)) {
      setNotificationList(notificationListData?.data?.data?.records);
      setTotalCount(notificationListData?.data?.data?.totalCount);
    }
  }, [notificationListData]);

  useEffect(() => {
    if (searchUserList?.data?.status && Array.isArray(searchUserList?.data?.data)) {
      setUsers(searchUserList?.data?.data);
    }
  }, [searchUserList]);

  useEffect(() => {
    if (newNotification?.data?.status) {
      setOpenModal(false);
      dispatch(getAllNotificationsAction(page + 1, pageSize, filter));
      resetForm();
      newNotification.data = null;
    }
  }, [newNotification]);

  useEffect(() => {
    if (updatedNotification?.data?.status) {
      setOpenModal(false);
      dispatch(getAllNotificationsAction(page + 1, pageSize, filter));
      resetForm();
      updatedNotification.data = null;
    }
  }, [updatedNotification]);

  useEffect(() => {
    if (deletedNotification?.data?.status) {
      dispatch(getAllNotificationsAction(page + 1, pageSize, filter));
      deletedNotification.data = null;
      setDeleteModal(false);
      setID(null);
    }
  }, [deletedNotification]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    title: ''
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getAllNotificationsAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      from: '',
      to: '',
      title: ''
    }
    setFilter(reset);
    dispatch(getAllNotificationsAction(page + 1, pageSize, reset));
  }

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    notification_for: 'all',
    image: null,
    users: null
  });

  const resetForm = () => {
    setImagePreview(null);
    setErrors({});
    setFormData({
      title: '',
      description: '',
      notification_for: 'all',
      image: null,
      users: null
    });
  }

  const dataHandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const [imagePreview, setImagePreview] = useState(null);
  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      image: event?.target?.files[0]
    });
  };

  useEffect(() => {
    if (formData?.image !== '' && formData?.image !== 'remove' && formData?.image !== null) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      if (typeof formData?.image === 'string') {
        setImagePreview(process.env.REACT_APP_API_URL + formData?.image);
      } else {
        reader.readAsDataURL(formData?.image);
      }
    }
  }, [formData?.image]);

  const removeImg = () => {
    setFormData({
      ...formData,
      image: 'remove'
    });
    setImagePreview(null);
  }

  const setFormDataFunc = () => {
    const Data = new FormData();
    if (formData?.id && formData?.id !== null && formData?.id !== '') {
      Data.append('id', formData?.id);
    }
    Data.append('title', formData?.title);
    Data.append('description', formData?.description);
    Data.append('notification_for', formData?.notification_for);
    Data.append('image', formData?.image);
    Data.append('users', JSON.stringify(formData?.users));
    return Data;
  }

  const createNotification = () => {
    const data = setFormDataFunc();
    delete data.id
    dispatch(createNotificationsAction(data))
  }

  const editNotification = (row) => {
    if (!row?.image) {
      setImagePreview(null);
    }
    setErrors({});
    setFormData({
      id: row?._id,
      title: row?.title,
      description: row?.description,
      notification_for: row?.notification_for,
      image: row?.image || null,
      users: row?.users || null
    });
    setOpenModal(true);
  }

  const updateNotification = () => {
    const data = setFormDataFunc();
    dispatch(updateNotificationsAction(data))
  }

  const [errors, setErrors] = useState({});
  const validationSchema = Joi.object({
    id: Joi.string().allow(null, ''),
    title: Joi.string().required(),
    description: Joi.string().required(),
    notification_for: Joi.string().valid('all', 'specific').required(),
    image: Joi.allow(null, ''),
    users: Joi.array().allow(null)
  });

  const save = () => {
    const validationResult = validationSchema.validate(formData, { abortEarly: false });
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
      console.log(validationErrors);
    } else {
      setErrors({});
      if (formData?.id && formData?.id !== '' && formData?.id !== null) {
        updateNotification()
      } else {
        createNotification()
      }
    }
  }

  const deleteNotification = (id) => {
    dispatch(deleteNotificationsAction({ id: id }));
  }

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Notifications
            </Typography>
            {adminPermission && (adminPermission.includes('Add Notification') || adminPermission.includes('All')) &&
              <Tooltip title={'Add New Notification'} placement="left">
                <Button color='default' variant='outlined' onClick={() => { resetForm(); setOpenModal(true); }}>+ Add New</Button>
              </Tooltip>
            }
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                variant="outlined"
                label="From Date"
                type="date"
                name='from'
                className='w-100'
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={filter?.from}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                variant="outlined"
                label="To Date"
                type="date"
                name='to'
                className='w-100'
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: filter?.from, // This is the minimum date value you want to set
                  },
                }}
                value={filter?.to}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                name='title'
                label="Title"
                variant="outlined"
                size="small"
                value={filter?.title}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Title</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Description</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Notification for</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Date</TableCell>
                  <TableCell style={{ minWidth: '150px' }} align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notificationList && notificationList.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>{row?.title}</TableCell>
                      <TableCell>{row?.description}</TableCell>
                      <TableCell>{row?.notification_for}</TableCell>
                      <TableCell>
                        {moment(row?.createdAt).format('YYYY-MM-DD hh:mmA')}
                      </TableCell>
                      <TableCell align='right'>
                        {/* <Tooltip title={'View Details'} placement="left">
                          <IconButton onClick={() => { setRowData(row); setOpenDetailsModal(true) }}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip> */}
                        {adminPermission && (adminPermission.includes('Edit Notification') || adminPermission.includes('All')) &&
                          <Tooltip title={'Edit Notification'} placement="left">
                            <IconButton onClick={() => { editNotification(row) }}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {adminPermission && (adminPermission.includes('Delete Notification') || adminPermission.includes('All')) &&
                          <Tooltip title={'Delete Notification'} placement="left">
                            <IconButton onClick={() => { setID(row?._id); setDeleteModal(true) }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* form dialog */}
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setOpenModal(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              {formData?.id ? 'Edit Notification' : 'Create Notification'}
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }} onClick={() => save()}>
              {formData?.id ? 'Update' : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <Box>
            <Grid container style={{ maxWidth: '450px', paddingTop: '25px' }}>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='title'
                  label="Title"
                  variant="outlined"
                  size="small"
                  value={formData?.title}
                  error={errors?.title}
                  helperText={errors?.title}
                  onChange={(e) => dataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='description'
                  label="Description"
                  variant="outlined"
                  size="small"
                  rows={4}
                  multiline
                  value={formData?.description}
                  error={errors?.description}
                  helperText={errors?.description}
                  onChange={(e) => dataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <FormControl variant="outlined" size="small" error={errors?.notification_for} required style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">Notification for</InputLabel>
                  <Select
                    name='notification_for'
                    label="Notification for"
                    value={formData?.notification_for}
                    onChange={(e) => dataHandleChange(e)}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="specific">Specific</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.notification_for}</FormHelperText>
                </FormControl>
              </Grid>
              {formData?.notification_for && formData?.notification_for === 'specific' &&
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={users}
                    getOptionLabel={(option) => option.name}
                    // defaultValue={[top100Films[1], top100Films[2]]}
                    value={formData?.users || []}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Users"
                        placeholder="Search name"
                      />
                    )}
                    onInputChange={(event, newInputValue) => {
                      dispatch(getSearchUsersAction({ search_query: newInputValue }))
                    }}
                    onChange={(event, selectedOptions) => {
                      setFormData({
                        ...formData,
                        users: selectedOptions
                      });
                    }}
                  />
                </Grid>
              }
              {!imagePreview ?
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <div className='fileDragArea'>
                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e)} />
                    <div className='iconDiv'><PermMediaIcon /></div>
                    <div className='fileupTxt'>Drag & Drop to upload image</div>
                    <div className='fileupTxt'>or</div>
                    <div style={{ paddingTop: '10px' }}>
                      <Button color="primary" variant='contained'>
                        Browse file
                      </Button>
                    </div>
                  </div>
                </Grid>
                :
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <div className='fileDragArea'>
                    <div className='imgPrevOuter'>
                      <img src={imagePreview} alt="Image Preview" />
                      <div className='imgRemove' onClick={() => removeImg()}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                </Grid>
              }
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      {/* details view dialog */}
      <Dialog
        fullScreen
        open={openDetailsModal}
        onClose={() => { setOpenDetailsModal(false); setRowData(null) }}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => { setOpenDetailsModal(false); setRowData(null) }} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              Notification Details
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }}>
              {/* {categoryFormData?.id ? 'Update' : 'Save'} */}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <div>
            <table>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Title:</b></Typography></td>
                <td><Typography>{rowData?.title}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Description:</b></Typography></td>
                <td><Typography>{rowData?.description}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Notification for:</b></Typography></td>
                <td><Typography>{rowData?.notification_for}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Created date:</b></Typography></td>
                <td><Typography>{moment(rowData?.createdAt).format('YYYY-MM-DD hh:mmA')}</Typography></td>
              </tr>
            </table>
          </div>
        </DialogContent>
      </Dialog>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deleteNotification(ID)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Notification_list
