import { CREDIT_DEBIT_AMOUNT_ERROR, CREDIT_DEBIT_AMOUNT_LOADING, CREDIT_DEBIT_AMOUNT_SUCCESS, USER_WALLET_LEDGER_ERROR, USER_WALLET_LEDGER_LOADING, USER_WALLET_LEDGER_SUCCESS } from './actionTypes';

// user & referral count
const getUserWalletLedgerLoading = () => {
  return {
    type: USER_WALLET_LEDGER_LOADING,
  };
};

const getUserWalletLedgerSuccess = (data) => {
  return {
    type: USER_WALLET_LEDGER_SUCCESS,
    payload: data,
  };
};

const getUserWalletLedgerError = (error) => {
  return {
    type: USER_WALLET_LEDGER_ERROR,
    payload: error,
  };
};

// Credit debit amount to wllet
const creditDebitAmountLoading = () => {
  return {
    type: CREDIT_DEBIT_AMOUNT_LOADING,
  };
};

const creditDebitAmountSuccess = (data) => {
  return {
    type: CREDIT_DEBIT_AMOUNT_SUCCESS,
    payload: data,
  };
};

const creditDebitAmountError = (error) => {
  return {
    type: CREDIT_DEBIT_AMOUNT_ERROR,
    payload: error,
  };
};

export { 
    getUserWalletLedgerLoading, 
    getUserWalletLedgerSuccess, 
    getUserWalletLedgerError,

    creditDebitAmountLoading, 
    creditDebitAmountSuccess, 
    creditDebitAmountError,

};
