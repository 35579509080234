import { GET_CONFIG_DATA_ERROR, GET_CONFIG_DATA_LOADING, GET_CONFIG_DATA_SUCCESS, SET_HOW_IT_WORK_VIDEO_ERROR, SET_HOW_IT_WORK_VIDEO_LOADING, SET_HOW_IT_WORK_VIDEO_SUCCESS } from './actionTypes';

// Get How it work video
const getConfigDataLoading = () => {
  return {
    type: GET_CONFIG_DATA_LOADING,
  };
};

const getConfigDataSuccess = (data) => {
  return {
    type: GET_CONFIG_DATA_SUCCESS,
    payload: data,
  };
};

const getConfigDataError = (error) => {
  return {
    type: GET_CONFIG_DATA_ERROR,
    payload: error,
  };
};

// Set How it work video
const setHowItWorkVideoLoading = () => {
  return {
    type: SET_HOW_IT_WORK_VIDEO_LOADING,
  };
};

const setHowItWorkVideoSuccess = (data) => {
  return {
    type: SET_HOW_IT_WORK_VIDEO_SUCCESS,
    payload: data,
  };
};

const setHowItWorkVideoError = (error) => {
  return {
    type: SET_HOW_IT_WORK_VIDEO_ERROR,
    payload: error,
  };
};

export { 
    getConfigDataLoading, 
    getConfigDataSuccess, 
    getConfigDataError,

    setHowItWorkVideoLoading, 
    setHowItWorkVideoSuccess, 
    setHowItWorkVideoError
};
