import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, MenuItem, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { getSearchUsersAction } from '../../store/user/asyncActions';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import { createHelpsAction, deleteHelpsAction, getAllHelpsAction, updateHelpsAction } from '../../store/help/asyncActions';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { getAllPermissionAction } from '../../store/role/asyncActions';
import { statusColor } from '../../helpers';


const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function Help_list() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);
  const [rowData, setRowData] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();

  const helpListData = useSelector((state) => state?.help?.allHelp);
  const newHelp = useSelector((state) => state?.help?.createHelp);
  const updatedHelp = useSelector((state) => state?.help?.updateHelp);
  const deletedHelp = useSelector((state) => state?.help?.deleteHelp);

  const [helpList, setHelpList] = useState(null);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    dispatch(getAllHelpsAction(page + 1, pageSize, filter));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Helps - Referral App';
  }, []);

  useEffect(() => {
    if (helpListData?.data?.status && Array.isArray(helpListData?.data?.data?.records)) {
      setHelpList(helpListData?.data?.data?.records);
      setTotalCount(helpListData?.data?.data?.totalCount);
    }
  }, [helpListData]);

  useEffect(() => {
    if (newHelp?.data?.status) {
      setOpenModal(false);
      dispatch(getAllHelpsAction(page + 1, pageSize, filter));
      resetForm();
      newHelp.data = null;
    }
  }, [newHelp]);

  useEffect(() => {
    if (updatedHelp?.data?.status) {
      setOpenModal(false);
      dispatch(getAllHelpsAction(page + 1, pageSize, filter));
      resetForm();
      updatedHelp.data = null;
    }
  }, [updatedHelp]);

  useEffect(() => {
    if (deletedHelp?.data?.status) {
      dispatch(getAllHelpsAction(page + 1, pageSize, filter));
      deletedHelp.data = null;
      setDeleteModal(false);
      setID(null);
    }
  }, [deletedHelp]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    title: '',
    status: ''
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getAllHelpsAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      title: '',
      status: ''
    }
    setFilter(reset);
    dispatch(getAllHelpsAction(page + 1, pageSize, reset));
  }

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    image: null,
    youtube_link: ''
  });

  const resetForm = () => {
    setImagePreview(null);
    setErrors({});
    setFormData({
      title: '',
      description: '',
      image: null,
      youtube_link: ''
    });
  }

  const dataHandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const [imagePreview, setImagePreview] = useState(null);
  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      image: event?.target?.files[0]
    });
  };

  useEffect(() => {
    if (formData?.image !== '' && formData?.image !== 'remove' && formData?.image !== null) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      if (typeof formData?.image === 'string') {
        setImagePreview(process.env.REACT_APP_API_URL + formData?.image);
      } else {
        reader.readAsDataURL(formData?.image);
      }
    }
  }, [formData?.image]);

  const removeImg = () => {
    setFormData({
      ...formData,
      image: 'remove'
    });
    setImagePreview(null);
  }

  const setFormDataFunc = () => {
    const Data = new FormData();
    if (formData?.id && formData?.id !== null && formData?.id !== '') {
      Data.append('id', formData?.id);
    }
    Data.append('title', formData?.title);
    Data.append('description', formData?.description);
    Data.append('image', formData?.image);
    Data.append('youtube_link', formData?.youtube_link);
    return Data;
  }

  const createHelp = () => {
    const data = setFormDataFunc();
    delete data.id
    dispatch(createHelpsAction(data))
  }

  const editHelp = (row) => {
    if (!row?.image) {
      setImagePreview(null);
    }
    setErrors({});
    setFormData({
      id: row?._id,
      title: row?.title,
      description: row?.description,
      image: row?.image || null,
      youtube_link: row?.youtube_link,
    });
    setOpenModal(true);
  }

  const updateHelp = () => {
    const data = setFormDataFunc();
    dispatch(updateHelpsAction(data))
  }

  const [errors, setErrors] = useState({});
  const validationSchema = Joi.object({
    id: Joi.string().allow(null, ''),
    title: Joi.string().required(),
    description: Joi.string().required(),
    image: Joi.allow(null, ''),
    youtube_link: Joi.string().allow(null, '')
  });

  const save = () => {
    const validationResult = validationSchema.validate(formData, { abortEarly: false });
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
      console.log(validationErrors);
    } else {
      setErrors({});
      if (formData?.id && formData?.id !== '' && formData?.id !== null) {
        updateHelp()
      } else {
        createHelp()
      }
    }
  }

  const deleteHelp = (id) => {
    dispatch(deleteHelpsAction({ id: id }));
  }

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Helps
            </Typography>
            {adminPermission && (adminPermission.includes('Add Help') || adminPermission.includes('All')) &&
              <Tooltip title={'Add New Help'} placement="left">
                <Button color='default' variant='outlined' onClick={() => { resetForm(); setOpenModal(true); }}>+ Add New</Button>
              </Tooltip>
            }
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                name='title'
                label="Title"
                variant="outlined"
                size="small"
                value={filter?.title}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  name='status'
                  label="Status"
                  value={filter?.status}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Title</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Status</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Date</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Created By</TableCell>
                  <TableCell style={{ minWidth: '150px' }} align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {helpList && helpList.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>{row?.title}</TableCell>
                      <TableCell>
                        <div className='StatusDiv'>
                          <div className={`StatusDot ${statusColor(row?.status ? 'Active' : 'Inactive')}`}></div>
                          {row?.status ? 'Active' : 'Inactive'}
                        </div>
                      </TableCell>
                      {/* <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row?.status}
                              onChange={(e) => updateUserStatus({ _id: row?._id, status: e.target.checked })}
                              color="primary"
                            />
                          }
                          label={row?.status ? 'Active' : 'Inactive'}
                        />
                      </TableCell> */}
                      <TableCell>
                        {moment(row?.createdAt).format('YYYY-MM-DD hh:mmA')}
                      </TableCell>
                      <TableCell>
                        {row?.createdByData?.[0]?.first_name || ''} {row?.createdByData?.[0]?.last_name || ''}
                      </TableCell>
                      <TableCell align='right'>
                        {/* <Tooltip title={'View Details'} placement="left">
                          <IconButton onClick={() => { setRowData(row); setOpenDetailsModal(true) }}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip> */}
                        {adminPermission && (adminPermission.includes('Edit Help') || adminPermission.includes('All')) &&
                          <Tooltip title={'Edit Help'} placement="left">
                            <IconButton onClick={() => { editHelp(row) }}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {adminPermission && (adminPermission.includes('Delete Help') || adminPermission.includes('All')) &&
                          <Tooltip title={'Delete Help'} placement="left">
                            <IconButton onClick={() => { setID(row?._id); setDeleteModal(true) }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* form dialog */}
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setOpenModal(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              {formData?.id ? 'Edit Help' : 'Create Help'}
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }} onClick={() => save()}>
              {formData?.id ? 'Update' : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <Box>
            <Grid container style={{ maxWidth: '450px', paddingTop: '25px' }}>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='title'
                  label="Title"
                  variant="outlined"
                  size="small"
                  value={formData?.title}
                  error={errors?.title}
                  helperText={errors?.title}
                  onChange={(e) => dataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='description'
                  label="Description"
                  variant="outlined"
                  size="small"
                  rows={4}
                  multiline
                  value={formData?.description}
                  error={errors?.description}
                  helperText={errors?.description}
                  onChange={(e) => dataHandleChange(e)}
                />
              </Grid>
              {!imagePreview ?
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <div className='fileDragArea'>
                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e)} />
                    <div className='iconDiv'><PermMediaIcon /></div>
                    <div className='fileupTxt'>Drag & Drop to upload image</div>
                    <div className='fileupTxt'>or</div>
                    <div style={{ paddingTop: '10px' }}>
                      <Button color="primary" variant='contained'>
                        Browse file
                      </Button>
                    </div>
                  </div>
                </Grid>
                :
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <div className='fileDragArea'>
                    <div className='imgPrevOuter'>
                      <img src={imagePreview} alt="Image Preview" />
                      <div className='imgRemove' onClick={() => removeImg()}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                </Grid>
              }
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='youtube_link'
                  label="Youtube Link"
                  variant="outlined"
                  size="small"
                  value={formData?.youtube_link}
                  error={errors?.youtube_link}
                  helperText={errors?.youtube_link}
                  onChange={(e) => dataHandleChange(e)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      {/* details view dialog */}
      <Dialog
        fullScreen
        open={openDetailsModal}
        onClose={() => { setOpenDetailsModal(false); setRowData(null) }}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => { setOpenDetailsModal(false); setRowData(null) }} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              Help Details
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }}>
              {/* {categoryFormData?.id ? 'Update' : 'Save'} */}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <div>
            <table>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Title:</b></Typography></td>
                <td><Typography>{rowData?.title}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Description:</b></Typography></td>
                <td><Typography>{rowData?.description}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Image:</b></Typography></td>
                <td><Typography>{rowData?.image}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Youtube Link:</b></Typography></td>
                <td><Typography>{rowData?.youtube_link}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Created date:</b></Typography></td>
                <td><Typography>{moment(rowData?.createdAt).format('YYYY-MM-DD hh:mmA')}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Created By:</b></Typography></td>
                <td>
                  <Typography>
                    {rowData?.createdByData?.[0]?.first_name || ''} {rowData?.createdByData?.[0]?.last_name || ''}
                  </Typography>
                </td>
              </tr>
            </table>
          </div>
        </DialogContent>
      </Dialog>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deleteHelp(ID)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Help_list
