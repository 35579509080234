import { CREATE_NOTIFICATION_ERROR, CREATE_NOTIFICATION_LOADING, CREATE_NOTIFICATION_SUCCESS, DELETE_NOTIFICATION_ERROR, DELETE_NOTIFICATION_LOADING, DELETE_NOTIFICATION_SUCCESS, GET_ALL_NOTIFICATION_ERROR, GET_ALL_NOTIFICATION_LOADING, GET_ALL_NOTIFICATION_SUCCESS, UPDATE_NOTIFICATION_ERROR, UPDATE_NOTIFICATION_LOADING, UPDATE_NOTIFICATION_SUCCESS } from "./actionTypes";

const initialState = {
    allNotification: {
        loading: false,
        data: null,
        error: null
    },
    createNotification: {
        loading: false,
        data: null,
        error: null
    },
    updateNotification: {
        loading: false,
        data: null,
        error: null
    },
    deleteNotification: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_NOTIFICATION_LOADING:
            return { ...state, allNotification: { ...state.allNotification, loading: true, data: null, error: null } };
        case GET_ALL_NOTIFICATION_SUCCESS:
            return { ...state, allNotification: { ...state.allNotification, loading: false, data: action.payload, error: null } };
        case GET_ALL_NOTIFICATION_ERROR:
            return { ...state, allNotification: { ...state.allNotification, loading: false, data: null, error: action.payload } };

        case CREATE_NOTIFICATION_LOADING:
            return { ...state, createNotification: { ...state.createNotification, loading: true, data: null, error: null } };
        case CREATE_NOTIFICATION_SUCCESS:
            return { ...state, createNotification: { ...state.createNotification, loading: false, data: action.payload, error: null } };
        case CREATE_NOTIFICATION_ERROR:
            return { ...state, createNotification: { ...state.createNotification, loading: false, data: null, error: action.payload } };

        case UPDATE_NOTIFICATION_LOADING:
            return { ...state, updateNotification: { ...state.updateNotification, loading: true, data: null, error: null } };
        case UPDATE_NOTIFICATION_SUCCESS:
            return { ...state, updateNotification: { ...state.updateNotification, loading: false, data: action.payload, error: null } };
        case UPDATE_NOTIFICATION_ERROR:
            return { ...state, updateNotification: { ...state.updateNotification, loading: false, data: null, error: action.payload } };

        case DELETE_NOTIFICATION_LOADING:
            return { ...state, deleteNotification: { ...state.deleteNotification, loading: true, data: null, error: null } };
        case DELETE_NOTIFICATION_SUCCESS:
            return { ...state, deleteNotification: { ...state.deleteNotification, loading: false, data: action.payload, error: null } };
        case DELETE_NOTIFICATION_ERROR:
            return { ...state, deleteNotification: { ...state.deleteNotification, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
