export const GET_ALL_CATEGORIES_LOADING = 'GET_ALL_CATEGORIES_LOADING';
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
export const GET_ALL_CATEGORIES_ERROR = 'GET_ALL_CATEGORIES_ERROR';

export const CREATE_CATEGORY_LOADING = 'CREATE_CATEGORY_LOADING';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';

export const UPDATE_CATEGORY_LOADING = 'UPDATE_CATEGORY_LOADING';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

export const UPDATE_CATEGORY_STATUS_LOADING = 'UPDATE_CATEGORY_STATUS_LOADING';
export const UPDATE_CATEGORY_STATUS_SUCCESS = 'UPDATE_CATEGORY_STATUS_SUCCESS';
export const UPDATE_CATEGORY_STATUS_ERROR = 'UPDATE_CATEGORY_STATUS_ERROR';

export const DELETE_CATEGORY_LOADING = 'DELETE_CATEGORY_LOADING';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

export const SEARCH_CATEGORY_LOADING = 'SEARCH_CATEGORY_LOADING';
export const SEARCH_CATEGORY_SUCCESS = 'SEARCH_CATEGORY_SUCCESS';
export const SEARCH_CATEGORY_ERROR = 'SEARCH_CATEGORY_ERROR';

export const EXPORT_ALL_CSV_CATEGORY_LOADING = 'EXPORT_ALL_CSV_CATEGORY_LOADING';
export const EXPORT_ALL_CSV_CATEGORY_SUCCESS = 'EXPORT_ALL_CSV_CATEGORY_SUCCESS';
export const EXPORT_ALL_CSV_CATEGORY_ERROR = 'EXPORT_ALL_CSV_CATEGORY_ERROR';

export const IMPORT_ALL_CSV_CATEGORY_LOADING = 'IMPORT_ALL_CSV_CATEGORY_LOADING';
export const IMPORT_ALL_CSV_CATEGORY_SUCCESS = 'IMPORT_ALL_CSV_CATEGORY_SUCCESS';
export const IMPORT_ALL_CSV_CATEGORY_ERROR = 'IMPORT_ALL_CSV_CATEGORY_ERROR';
