import { CREATE_NOTIFICATION_ERROR, CREATE_NOTIFICATION_LOADING, CREATE_NOTIFICATION_SUCCESS, DELETE_NOTIFICATION_ERROR, DELETE_NOTIFICATION_LOADING, DELETE_NOTIFICATION_SUCCESS, GET_ALL_NOTIFICATION_ERROR, GET_ALL_NOTIFICATION_LOADING, GET_ALL_NOTIFICATION_SUCCESS, UPDATE_NOTIFICATION_ERROR, UPDATE_NOTIFICATION_LOADING, UPDATE_NOTIFICATION_SUCCESS } from './actionTypes';

// Notification list
const getAllNotificationLoading = () => {
  return {
    type: GET_ALL_NOTIFICATION_LOADING,
  };
};

const getAllNotificationSuccess = (data) => {
  return {
    type: GET_ALL_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

const getAllNotificationError = (error) => {
  return {
    type: GET_ALL_NOTIFICATION_ERROR,
    payload: error,
  };
};

// create Notification
const createNotificationLoading = () => {
  return {
    type: CREATE_NOTIFICATION_LOADING,
  };
};

const createNotificationSuccess = (data) => {
  return {
    type: CREATE_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

const createNotificationError = (error) => {
  return {
    type: CREATE_NOTIFICATION_ERROR,
    payload: error,
  };
};

// update Notification
const updateNotificationLoading = () => {
  return {
    type: UPDATE_NOTIFICATION_LOADING,
  };
};

const updateNotificationSuccess = (data) => {
  return {
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

const updateNotificationError = (error) => {
  return {
    type: UPDATE_NOTIFICATION_ERROR,
    payload: error,
  };
};

// delete Notification
const deleteNotificationLoading = () => {
  return {
    type: DELETE_NOTIFICATION_LOADING,
  };
};

const deleteNotificationSuccess = (data) => {
  return {
    type: DELETE_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

const deleteNotificationError = (error) => {
  return {
    type: DELETE_NOTIFICATION_ERROR,
    payload: error,
  };
};

export { 
    getAllNotificationLoading, 
    getAllNotificationSuccess, 
    getAllNotificationError,

    createNotificationLoading, 
    createNotificationSuccess, 
    createNotificationError,

    updateNotificationLoading, 
    updateNotificationSuccess, 
    updateNotificationError,

    deleteNotificationLoading, 
    deleteNotificationSuccess, 
    deleteNotificationError,
};
