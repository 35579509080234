import { getAllItemsLoading, getAllItemsSuccess, getAllItemsError, createItemLoading, createItemSuccess, createItemError, updateItemLoading, updateItemSuccess, updateItemError, deleteItemLoading, deleteItemSuccess, deleteItemError, updateItemStatusLoading, updateItemStatusSuccess, updateItemStatusError, searchItemLoading, searchItemSuccess, searchItemError, exportAllCsvItemsLoading, exportAllCsvItemsSuccess, exportAllCsvItemsError, importAllCsvItemsLoading, importAllCsvItemsSuccess, importAllCsvItemsError } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllItemsAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllItemsLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&name=${filter?.name}&category=${filter?.category}&status=${filter?.status}`;
      const response = await api.get(`item/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllItemsSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllItemsError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const getAllCsvItemsAction = (filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(exportAllCsvItemsLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `name=${filter?.name}&category=${filter?.category}&status=${filter?.status}`;
      const response = await api.get(`item/export/csv?${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(exportAllCsvItemsSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(exportAllCsvItemsError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const importCsvItemAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(importAllCsvItemsLoading());
      const response = await api.post('item/import/csv', postData);
      const data = response.data;
      dispatch(importAllCsvItemsSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(importAllCsvItemsError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createItemAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(createItemLoading());
      const response = await api.post('item/create', postData);
      const data = response.data;
      dispatch(createItemSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(createItemError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateItemAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateItemLoading());
      const response = await api.post('item/update', postData);
      const data = response.data;
      dispatch(updateItemSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateItemError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateItemStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(updateItemStatusLoading());
      const response = await api.post('item/update/status', postData);
      const data = response.data;
      dispatch(updateItemStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(updateItemStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteItemAction = (postData) => {
  return async (dispatch) => {
    try {
      dispatch(deleteItemLoading());
      const response = await api.post('item/delete', postData);
      const data = response.data;
      dispatch(deleteItemSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      dispatch(deleteItemError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const searchItemAction = (qry) => {
  return async (dispatch) => {
    try {
      dispatch(searchItemLoading());
      const response = await api.get(`item/search?search_query=${qry}`);
      const data = response.data;
      dispatch(searchItemSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      dispatch(searchItemError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllItemsAction,
  getAllCsvItemsAction,
  importCsvItemAction,
  createItemAction,
  updateItemAction,
  updateItemStatusAction,
  deleteItemAction,
  searchItemAction
};
