import { GET_ALL_BUYERS_ERROR, GET_ALL_BUYERS_LOADING, GET_ALL_BUYERS_SUCCESS, GET_ALL_REFERRAL_DRAFT_ERROR, GET_ALL_REFERRAL_DRAFT_LOADING, GET_ALL_REFERRAL_DRAFT_SUCCESS, GET_ALL_REFERRAL_ERROR, GET_ALL_REFERRAL_LOADING, GET_ALL_REFERRAL_SUCCESS, GET_ALL_SELLER_ERROR, GET_ALL_SELLER_LOADING, GET_ALL_SELLER_SUCCESS, GET_GENERATED_REF_BY_USER_ERROR, GET_GENERATED_REF_BY_USER_LOADING, GET_GENERATED_REF_BY_USER_SUCCESS, GET_RECEIVED_REF_BY_USER_ERROR, GET_RECEIVED_REF_BY_USER_LOADING, GET_RECEIVED_REF_BY_USER_SUCCESS } from './actionTypes';

// referrals list
const getAllReferralLoading = () => {
  return {
    type: GET_ALL_REFERRAL_LOADING,
  };
};

const getAllReferralSuccess = (data) => {
  return {
    type: GET_ALL_REFERRAL_SUCCESS,
    payload: data,
  };
};

const getAllReferralError = (error) => {
  return {
    type: GET_ALL_REFERRAL_ERROR,
    payload: error,
  };
};

// referrals draft list
const getAllReferralDraftLoading = () => {
  return {
    type: GET_ALL_REFERRAL_DRAFT_LOADING,
  };
};

const getAllReferralDraftSuccess = (data) => {
  return {
    type: GET_ALL_REFERRAL_DRAFT_SUCCESS,
    payload: data,
  };
};

const getAllReferralDraftError = (error) => {
  return {
    type: GET_ALL_REFERRAL_DRAFT_ERROR,
    payload: error,
  };
};

// buyers list
const getAllBuyerLoading = () => {
  return {
    type: GET_ALL_BUYERS_LOADING,
  };
};

const getAllBuyerSuccess = (data) => {
  return {
    type: GET_ALL_BUYERS_SUCCESS,
    payload: data,
  };
};

const getAllBuyerError = (error) => {
  return {
    type: GET_ALL_BUYERS_ERROR,
    payload: error,
  };
};

// seller list
const getAllSellerLoading = () => {
  return {
    type: GET_ALL_SELLER_LOADING,
  };
};

const getAllSellerSuccess = (data) => {
  return {
    type: GET_ALL_SELLER_SUCCESS,
    payload: data,
  };
};

const getAllSellerError = (error) => {
  return {
    type: GET_ALL_SELLER_ERROR,
    payload: error,
  };
};

// Get generated referral by user id
const getGeneratedRefByUserLoading = () => {
  return {
    type: GET_GENERATED_REF_BY_USER_LOADING,
  };
};

const getGeneratedRefByUserSuccess = (data) => {
  return {
    type: GET_GENERATED_REF_BY_USER_SUCCESS,
    payload: data,
  };
};

const getGeneratedRefByUserError = (error) => {
  return {
    type: GET_GENERATED_REF_BY_USER_ERROR,
    payload: error,
  };
};

// Get received referral by user id
const getReceivedRefByUserLoading = () => {
  return {
    type: GET_RECEIVED_REF_BY_USER_LOADING,
  };
};

const getReceivedRefByUserSuccess = (data) => {
  return {
    type: GET_RECEIVED_REF_BY_USER_SUCCESS,
    payload: data,
  };
};

const getReceivedRefByUserError = (error) => {
  return {
    type: GET_RECEIVED_REF_BY_USER_ERROR,
    payload: error,
  };
};

export { 
    getAllReferralLoading, 
    getAllReferralSuccess, 
    getAllReferralError,

    getAllReferralDraftLoading, 
    getAllReferralDraftSuccess, 
    getAllReferralDraftError,

    getAllBuyerLoading, 
    getAllBuyerSuccess, 
    getAllBuyerError,

    getAllSellerLoading, 
    getAllSellerSuccess, 
    getAllSellerError,

    getGeneratedRefByUserLoading, 
    getGeneratedRefByUserSuccess, 
    getGeneratedRefByUserError,

    getReceivedRefByUserLoading, 
    getReceivedRefByUserSuccess, 
    getReceivedRefByUserError,

};
