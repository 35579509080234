import { GET_ALL_BUYERS_ERROR, GET_ALL_BUYERS_LOADING, GET_ALL_BUYERS_SUCCESS, GET_ALL_REFERRAL_DRAFT_ERROR, GET_ALL_REFERRAL_DRAFT_LOADING, GET_ALL_REFERRAL_DRAFT_SUCCESS, GET_ALL_REFERRAL_ERROR, GET_ALL_REFERRAL_LOADING, GET_ALL_REFERRAL_SUCCESS, GET_ALL_SELLER_ERROR, GET_ALL_SELLER_LOADING, GET_ALL_SELLER_SUCCESS, GET_GENERATED_REF_BY_USER_ERROR, GET_GENERATED_REF_BY_USER_LOADING, GET_GENERATED_REF_BY_USER_SUCCESS, GET_RECEIVED_REF_BY_USER_ERROR, GET_RECEIVED_REF_BY_USER_LOADING, GET_RECEIVED_REF_BY_USER_SUCCESS } from "./actionTypes";

const initialState = {
    allReferrals: {
        loading: false,
        data: null,
        error: null
    },
    allReferralDrafts: {
        loading: false,
        data: null,
        error: null
    },
    allBuyers: {
        loading: false,
        data: null,
        error: null
    },
    allSellers: {
        loading: false,
        data: null,
        error: null
    },
    generatedRefByUser: {
        loading: false,
        data: null,
        error: null
    },
    receivedRefByUser: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_REFERRAL_LOADING:
            return { ...state, allReferrals: { ...state.allReferrals, loading: true, data: null, error: null } };
        case GET_ALL_REFERRAL_SUCCESS:
            return { ...state, allReferrals: { ...state.allReferrals, loading: false, data: action.payload, error: null } };
        case GET_ALL_REFERRAL_ERROR:
            return { ...state, allReferrals: { ...state.allReferrals, loading: false, data: null, error: action.payload } };

        case GET_ALL_REFERRAL_DRAFT_LOADING:
            return { ...state, allReferralDrafts: { ...state.allReferralDrafts, loading: true, data: null, error: null } };
        case GET_ALL_REFERRAL_DRAFT_SUCCESS:
            return { ...state, allReferralDrafts: { ...state.allReferralDrafts, loading: false, data: action.payload, error: null } };
        case GET_ALL_REFERRAL_DRAFT_ERROR:
            return { ...state, allReferralDrafts: { ...state.allReferralDrafts, loading: false, data: null, error: action.payload } };

        case GET_ALL_BUYERS_LOADING:
            return { ...state, allBuyers: { ...state.allBuyers, loading: true, data: null, error: null } };
        case GET_ALL_BUYERS_SUCCESS:
            return { ...state, allBuyers: { ...state.allBuyers, loading: false, data: action.payload, error: null } };
        case GET_ALL_BUYERS_ERROR:
            return { ...state, allBuyers: { ...state.allBuyers, loading: false, data: null, error: action.payload } };

        case GET_ALL_SELLER_LOADING:
            return { ...state, allSellers: { ...state.allSellers, loading: true, data: null, error: null } };
        case GET_ALL_SELLER_SUCCESS:
            return { ...state, allSellers: { ...state.allSellers, loading: false, data: action.payload, error: null } };
        case GET_ALL_SELLER_ERROR:
            return { ...state, allSellers: { ...state.allSellers, loading: false, data: null, error: action.payload } };

        case GET_GENERATED_REF_BY_USER_LOADING:
            return { ...state, generatedRefByUser: { ...state.generatedRefByUser, loading: true, data: null, error: null } };
        case GET_GENERATED_REF_BY_USER_SUCCESS:
            return { ...state, generatedRefByUser: { ...state.generatedRefByUser, loading: false, data: action.payload, error: null } };
        case GET_GENERATED_REF_BY_USER_ERROR:
            return { ...state, generatedRefByUser: { ...state.generatedRefByUser, loading: false, data: null, error: action.payload } };

        case GET_RECEIVED_REF_BY_USER_LOADING:
            return { ...state, receivedRefByUser: { ...state.receivedRefByUser, loading: true, data: null, error: null } };
        case GET_RECEIVED_REF_BY_USER_SUCCESS:
            return { ...state, receivedRefByUser: { ...state.receivedRefByUser, loading: false, data: action.payload, error: null } };
        case GET_RECEIVED_REF_BY_USER_ERROR:
            return { ...state, receivedRefByUser: { ...state.receivedRefByUser, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
