import { CREDIT_DEBIT_AMOUNT_ERROR, CREDIT_DEBIT_AMOUNT_LOADING, CREDIT_DEBIT_AMOUNT_SUCCESS, USER_WALLET_LEDGER_ERROR, USER_WALLET_LEDGER_LOADING, USER_WALLET_LEDGER_SUCCESS } from "./actionTypes";

const initialState = {
    userWalletLedger: {
        loading: false,
        data: null,
        error: null
    },
    creditDebitAmount: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_WALLET_LEDGER_LOADING:
            return { ...state, userWalletLedger: { ...state.userWalletLedger, loading: true, data: null, error: null } };
        case USER_WALLET_LEDGER_SUCCESS:
            return { ...state, userWalletLedger: { ...state.userWalletLedger, loading: false, data: action.payload, error: null } };
        case USER_WALLET_LEDGER_ERROR:
            return { ...state, userWalletLedger: { ...state.userWalletLedger, loading: false, data: null, error: action.payload } };

        case CREDIT_DEBIT_AMOUNT_LOADING:
            return { ...state, creditDebitAmount: { ...state.creditDebitAmount, loading: true, data: null, error: null } };
        case CREDIT_DEBIT_AMOUNT_SUCCESS:
            return { ...state, creditDebitAmount: { ...state.creditDebitAmount, loading: false, data: action.payload, error: null } };
        case CREDIT_DEBIT_AMOUNT_ERROR:
            return { ...state, creditDebitAmount: { ...state.creditDebitAmount, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
