export const GET_ALL_ITEMS_LOADING = 'GET_ALL_ITEMS_LOADING';
export const GET_ALL_ITEMS_SUCCESS = 'GET_ALL_ITEMS_SUCCESS';
export const GET_ALL_ITEMS_ERROR = 'GET_ALL_ITEMS_ERROR';

export const EXPORT_ALL_CSV_ITEMS_LOADING = 'EXPORT_ALL_CSV_ITEMS_LOADING';
export const EXPORT_ALL_CSV_ITEMS_SUCCESS = 'EXPORT_ALL_CSV_ITEMS_SUCCESS';
export const EXPORT_ALL_CSV_ITEMS_ERROR = 'EXPORT_ALL_CSV_ITEMS_ERROR';

export const IMPORT_ALL_CSV_ITEMS_LOADING = 'IMPORT_ALL_CSV_ITEMS_LOADING';
export const IMPORT_ALL_CSV_ITEMS_SUCCESS = 'IMPORT_ALL_CSV_ITEMS_SUCCESS';
export const IMPORT_ALL_CSV_ITEMS_ERROR = 'IMPORT_ALL_CSV_ITEMS_ERROR';

export const CREATE_ITEM_LOADING = 'CREATE_ITEM_LOADING';
export const CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS';
export const CREATE_ITEM_ERROR = 'CREATE_ITEM_ERROR';

export const UPDATE_ITEM_LOADING = 'UPDATE_ITEM_LOADING';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_ERROR = 'UPDATE_ITEM_ERROR';

export const UPDATE_ITEM_STATUS_LOADING = 'UPDATE_ITEM_STATUS_LOADING';
export const UPDATE_ITEM_STATUS_SUCCESS = 'UPDATE_ITEM_STATUS_SUCCESS';
export const UPDATE_ITEM_STATUS_ERROR = 'UPDATE_ITEM_STATUS_ERROR';

export const DELETE_ITEM_LOADING = 'DELETE_ITEM_LOADING';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_ERROR = 'DELETE_ITEM_ERROR';

export const SEARCH_ITEM_LOADING = 'SEARCH_ITEM_LOADING';
export const SEARCH_ITEM_SUCCESS = 'SEARCH_ITEM_SUCCESS';
export const SEARCH_ITEM_ERROR = 'SEARCH_ITEM_ERROR';
