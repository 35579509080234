export const GET_ALL_NOTIFICATION_LOADING = 'GET_ALL_NOTIFICATION_LOADING';
export const GET_ALL_NOTIFICATION_SUCCESS = 'GET_ALL_NOTIFICATION_SUCCESS';
export const GET_ALL_NOTIFICATION_ERROR = 'GET_ALL_NOTIFICATION_ERROR';

export const CREATE_NOTIFICATION_LOADING = 'CREATE_NOTIFICATION_LOADING';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_ERROR = 'CREATE_NOTIFICATION_ERROR';

export const UPDATE_NOTIFICATION_LOADING = 'UPDATE_NOTIFICATION_LOADING';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_ERROR = 'UPDATE_NOTIFICATION_ERROR';

export const DELETE_NOTIFICATION_LOADING = 'DELETE_NOTIFICATION_LOADING';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_ERROR = 'DELETE_NOTIFICATION_ERROR';
