export const GET_ALL_ROLES_LOADING = 'GET_ALL_ROLES_LOADING';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const GET_ALL_ROLES_ERROR = 'GET_ALL_ROLES_ERROR';

export const GET_ALL_PERMISSIONS_LOADING = 'GET_ALL_PERMISSIONS_LOADING';
export const GET_ALL_PERMISSIONS_SUCCESS = 'GET_ALL_PERMISSIONS_SUCCESS';
export const GET_ALL_PERMISSIONS_ERROR = 'GET_ALL_PERMISSIONS_ERROR';

export const CREATE_ROLE_LOADING = 'CREATE_ROLE_LOADING';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR';

export const UPDATE_ROLE_LOADING = 'UPDATE_ROLE_LOADING';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';

export const UPDATE_ROLE_STATUS_LOADING = 'UPDATE_ROLE_STATUS_LOADING';
export const UPDATE_ROLE_STATUS_SUCCESS = 'UPDATE_ROLE_STATUS_SUCCESS';
export const UPDATE_ROLE_STATUS_ERROR = 'UPDATE_ROLE_STATUS_ERROR';

export const DELETE_ROLE_LOADING = 'DELETE_ROLE_LOADING';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';

export const SEARCH_ROLE_LOADING = 'SEARCH_ROLE_LOADING';
export const SEARCH_ROLE_SUCCESS = 'SEARCH_ROLE_SUCCESS';
export const SEARCH_ROLE_ERROR = 'SEARCH_ROLE_ERROR';
