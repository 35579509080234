import { getAllActivityLogError, getAllActivityLogLoading, getAllActivityLogSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllActivityLogsAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllActivityLogLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&from=${filter?.from}&to=${filter?.to}&action=${filter?.action}&collection=${filter?.collection}&search=${filter?.search}`;
      const response = await api.get(`activity-log/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllActivityLogSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllActivityLogError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllActivityLogsAction
};
