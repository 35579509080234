import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ImageIcon from '@material-ui/icons/Image';
import MovieIcon from '@material-ui/icons/Movie';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, MenuItem, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { getSearchUsersAction } from '../../store/user/asyncActions';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import { createPopupsAction, deletePopupsAction, getAllPopupsAction, updatePopupsAction } from '../../store/popup/asyncActions';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { getAllPermissionAction } from '../../store/role/asyncActions';
import { statusColor } from '../../helpers';


const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function Popup_list() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);
  const [rowData, setRowData] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();

  const popupListData = useSelector((state) => state?.popup?.allPopup);
  const newPopup = useSelector((state) => state?.popup?.createPopup);
  const updatedPopup = useSelector((state) => state?.popup?.updatePopup);
  const deletedPopup = useSelector((state) => state?.popup?.deletePopup);

  const searchUserList = useSelector((state) => state?.user?.allSearchUser);
  const [popupList, setPopupList] = useState(null);
  const [users, setUsers] = useState(null);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    dispatch(getAllPopupsAction(page + 1, pageSize, filter));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Popups - Referral App';
    // dispatch(getAllPopupsAction(page + 1, 10));
    dispatch(getSearchUsersAction())
  }, []);

  useEffect(() => {
    if (popupListData?.data?.status && Array.isArray(popupListData?.data?.data?.records)) {
      setPopupList(popupListData?.data?.data?.records);
      setTotalCount(popupListData?.data?.data?.totalCount);
    }
  }, [popupListData]);

  useEffect(() => {
    if (searchUserList?.data?.status && Array.isArray(searchUserList?.data?.data)) {
      setUsers(searchUserList?.data?.data);
    }
  }, [searchUserList]);

  useEffect(() => {
    if (newPopup?.data?.status) {
      setOpenModal(false);
      dispatch(getAllPopupsAction(page + 1, pageSize, filter));
      resetForm();
      newPopup.data = null;
    }
  }, [newPopup]);

  useEffect(() => {
    if (updatedPopup?.data?.status) {
      setOpenModal(false);
      dispatch(getAllPopupsAction(page + 1, pageSize, filter));
      resetForm();
      updatedPopup.data = null;
    }
  }, [updatedPopup]);

  useEffect(() => {
    if (deletedPopup?.data?.status) {
      dispatch(getAllPopupsAction(page + 1, pageSize, filter));
      deletedPopup.data = null;
      setDeleteModal(false);
      setID(null);
    }
  }, [deletedPopup]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    title: '',
    status: ''
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getAllPopupsAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      title: '',
      status: ''
    }
    setFilter(reset);
    dispatch(getAllPopupsAction(page + 1, pageSize, reset));
  }

  const [formData, setFormData] = useState({
    title: '',
    page: 'All',
    type: 'image',
    start_date: '',
    end_date: '',
    image: null,
    video: null,
    status: true
  });

  const resetForm = () => {
    setImagePreview(null);
    setVideoPreview(null);
    setErrors({});
    setFormData({
      title: '',
      page: 'All',
      type: 'image',
      start_date: '',
      end_date: '',
      image: null,
      video: null,
      status: true
    });
  }

  const dataHandleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event?.target?.files[0]
    });
  };

  useEffect(() => {
    if (formData?.image !== '' && formData?.image !== 'remove' && formData?.image !== null) {
      if (typeof formData?.image === 'string') {
        setImagePreview(process.env.REACT_APP_API_URL + formData?.image);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(formData?.image);
      }
    }
  }, [formData?.image]);

  useEffect(() => {
    if (formData?.video !== '' && formData?.video !== 'remove' && formData?.video !== null) {
      if (typeof formData?.video === 'string') {
        setVideoPreview(process.env.REACT_APP_API_URL + formData?.video);
      } else {
        const videoURL = URL.createObjectURL(formData?.video);
        setVideoPreview(videoURL);
      }
    }
  }, [formData?.video]);

  const removeFile = (key) => {
    setFormData({
      ...formData,
      [key]: 'remove'
    });
    if (key === 'image') {
      setImagePreview(null);
    }
    if (key === 'video') {
      setVideoPreview(null);
    }
  }

  const setFormDataFunc = () => {
    const Data = new FormData();
    if (formData?.id && formData?.id !== null && formData?.id !== '') {
      Data.append('id', formData?.id);
    }
    Data.append('title', formData?.title);
    Data.append('page', formData?.page);
    Data.append('type', formData?.type);
    Data.append('image', formData?.image);
    Data.append('video', formData?.video);
    Data.append('start_date', formData?.start_date);
    Data.append('end_date', formData?.end_date);
    Data.append('status', formData?.status);
    return Data;
  }

  const createPopup = () => {
    const data = setFormDataFunc();
    delete data.id
    dispatch(createPopupsAction(data))
  }

  const editPopup = (row) => {
    if (!row?.image) {
      setImagePreview(null);
    }
    setErrors({});
    setFormData({
      id: row?._id,
      title: row?.title,
      page: row?.page,
      type: row?.type,
      start_date: moment(row?.start_date).format('YYYY-MM-DD') || '',
      end_date: moment(row?.end_date).format('YYYY-MM-DD') || '',
      image: row?.image || null,
      video: row?.video || null,
      status: row?.status
    });
    setOpenModal(true);
  }

  const updatePopup = () => {
    const data = setFormDataFunc();
    dispatch(updatePopupsAction(data))
  }

  const [errors, setErrors] = useState({});
  const validationSchema = Joi.object({
    id: Joi.string().allow(null, ''),
    title: Joi.string().required(),
    page: Joi.string().required(),
    type: Joi.string().required(),
    start_date: Joi.string().required(),
    end_date: Joi.string().required(),
    image: Joi.allow(null, ''),
    video: Joi.allow(null, ''),
    status: Joi.boolean().required()
  });

  const save = () => {
    const validationResult = validationSchema.validate(formData, { abortEarly: false });
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
    } else {
      setErrors({});
      if (formData?.id && formData?.id !== '' && formData?.id !== null) {
        updatePopup()
      } else {
        createPopup()
      }
    }
  }

  const deletePopup = (id) => {
    dispatch(deletePopupsAction({ id: id }));
  }

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Popups
            </Typography>
            {adminPermission && (adminPermission.includes('Add Popup') || adminPermission.includes('All')) &&
              <Tooltip title={'Add New Notification'} placement="left">
                <Button color='default' variant='outlined' onClick={() => { resetForm(); setOpenModal(true); }}>+ Add New</Button>
              </Tooltip>
            }
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                name='title'
                label="Title"
                variant="outlined"
                size="small"
                value={filter?.title}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  name='status'
                  label="Status"
                  value={filter?.status}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Title</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Type</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Start Date</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>End Date</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Status</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Date</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Created By</TableCell>
                  <TableCell style={{ minWidth: '150px' }} align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {popupList && popupList.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>{row?.title}</TableCell>
                      <TableCell>{row?.type}</TableCell>
                      <TableCell>
                        {moment(row?.start_date).format('YYYY-MM-DD hh:mmA')}
                      </TableCell>
                      <TableCell>
                        {moment(row?.end_date).format('YYYY-MM-DD hh:mmA')}
                      </TableCell>
                      <TableCell>
                        <div className='StatusDiv'>
                          <div className={`StatusDot ${statusColor(row?.status ? 'Active' : 'Inactive')}`}></div>
                          {row?.status ? 'Active' : 'Inactive'}
                        </div>
                      </TableCell>
                      <TableCell>
                        {moment(row?.createdAt).format('YYYY-MM-DD hh:mmA')}
                      </TableCell>
                      <TableCell>
                        {row?.createdByData?.[0]?.first_name || ''} {row?.createdByData?.[0]?.last_name || ''}
                      </TableCell>
                      <TableCell align='right'>
                        {/* <Tooltip title={'View Details'} placement="left">
                          <IconButton onClick={() => { setRowData(row); setOpenDetailsModal(true) }}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip> */}
                        {adminPermission && (adminPermission.includes('Edit Popup') || adminPermission.includes('All')) &&
                          <Tooltip title={'Edit Notification'} placement="left">
                            <IconButton onClick={() => { editPopup(row) }} >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {adminPermission && (adminPermission.includes('Delete Popup') || adminPermission.includes('All')) &&
                          <Tooltip title={'Delete Notification'} placement="left">
                            <IconButton onClick={() => { setID(row?._id); setDeleteModal(true) }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* form dialog */}
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setOpenModal(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              {formData?.id ? 'Edit Popup' : 'Create Popup'}
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }} onClick={() => save()}>
              {formData?.id ? 'Update' : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <Box>
            <Grid container style={{ maxWidth: '450px', paddingTop: '25px' }}>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='title'
                  label="Title"
                  variant="outlined"
                  size="small"
                  value={formData?.title}
                  error={errors?.title}
                  helperText={errors?.title}
                  onChange={(e) => dataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <FormControl variant="outlined" size="small" error={errors?.page} required style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">Page</InputLabel>
                  <Select
                    name='page'
                    label="Page"
                    value={formData?.page}
                    onChange={(e) => dataHandleChange(e)}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Referral Create">Referral Create</MenuItem>
                    <MenuItem value="Referral Success">Referral Success</MenuItem>
                    <MenuItem value="View Referral">View Referral</MenuItem>
                    <MenuItem value="Wallet">Wallet</MenuItem>
                    <MenuItem value="Notification">Notification</MenuItem>
                    <MenuItem value="Profile">Profile</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.page}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <FormControl variant="outlined" size="small" error={errors?.type} required style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                  <Select
                    name='type'
                    label="Type"
                    value={formData?.type}
                    onChange={(e) => dataHandleChange(e)}
                  >
                    <MenuItem value="image">Image</MenuItem>
                    <MenuItem value="video">Video</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.type}</FormHelperText>
                </FormControl>
              </Grid>
              {!imagePreview ?
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <div className='fileDragArea'>
                    <input type="file" name='image' accept="image/*" onChange={(e) => handleFileChange(e)} />
                    <div className='iconDiv'><ImageIcon /></div>
                    <div className='fileupTxt'>Drag & Drop to upload image</div>
                    <div className='fileupTxt'>or</div>
                    <div style={{ paddingTop: '10px' }}>
                      <Button color="primary" variant='contained'>
                        Browse file
                      </Button>
                    </div>
                  </div>
                </Grid>
                :
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                  <div className='fileDragArea'>
                    <div className='imgPrevOuter'>
                      <img src={imagePreview} alt="Image Preview" />
                      <div className='imgRemove' onClick={() => removeFile('image')}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                </Grid>
              }
              {formData?.type === 'video' &&
                (
                  !videoPreview ?
                    <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                      <div className='fileDragArea'>
                        <input type="file" name='video' accept="video/*" onChange={(e) => handleFileChange(e)} />
                        <div className='iconDiv'><MovieIcon /></div>
                        <div className='fileupTxt'>Drag & Drop to upload video</div>
                        <div className='fileupTxt'>or</div>
                        <div style={{ paddingTop: '10px' }}>
                          <Button color="primary" variant='contained'>
                            Browse file
                          </Button>
                        </div>
                      </div>
                    </Grid>
                    :
                    <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                      <div className='fileDragArea'>
                        <div className='imgPrevOuter'>
                          <video controls style={{ height: '100%' }}>
                            <source src={videoPreview} type="video/mp4" />
                            <source src={videoPreview} type="video/webm" />
                            <source src={videoPreview} type="video/wmv" />
                            <source src={videoPreview} type="video/ogg" />
                            <source src={videoPreview} type="video/avi" />
                            <source src={videoPreview} type="video/m4v" />
                            <source src={videoPreview} type="video/mov" />
                            <source src={videoPreview} type="video/mpeg" />
                            <source src={videoPreview} type="video/3gpp" />
                            <source src={videoPreview} type="video/x-matroska" />
                            <source src={videoPreview} type="video/quicktime" />
                            <source src={videoPreview} type="video/x-flv" />
                            Your browser does not support the video tag.
                          </video>
                          <div className='imgRemove' onClick={() => removeFile('video')}>
                            <CloseIcon />
                          </div>
                        </div>
                      </div>
                    </Grid>
                )
              }
              <Grid item xs={12} sm={12} md={6} xl={6} className='mb-2 px-1'>
                <TextField
                  variant="outlined"
                  label="Start Date"
                  type="date"
                  name='start_date'
                  className='w-100'
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData?.start_date}
                  error={errors?.start_date}
                  helperText={errors?.start_date}
                  onChange={(e) => dataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={6} className='mb-2 px-1'>
                <TextField
                  variant="outlined"
                  label="End Date"
                  type="date"
                  name='end_date'
                  className='w-100'
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData?.end_date}
                  error={errors?.end_date}
                  helperText={errors?.end_date}
                  onChange={(e) => dataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Box className='statusBox'>
                  <Typography color="inherit" className="flexSpacer">
                    <div>Status</div>
                  </Typography>
                  <FormControlLabel
                    style={{ marginLeft: 'auto', marginRight: 0 }}
                    control={
                      <Switch
                        checked={formData?.status}
                        onChange={(e) => setFormData({ ...formData, status: e?.target?.checked })}
                        color="primary"
                      />
                    }
                    label={formData?.status ? 'Active' : 'Inactive'}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      {/* details view dialog */}
      <Dialog
        fullScreen
        open={openDetailsModal}
        onClose={() => { setOpenDetailsModal(false); setRowData(null) }}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => { setOpenDetailsModal(false); setRowData(null) }} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              Popup Details
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }}>
              {/* {categoryFormData?.id ? 'Update' : 'Save'} */}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <div>
            <table>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Title:</b></Typography></td>
                <td><Typography>{rowData?.title}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Page:</b></Typography></td>
                <td><Typography>{rowData?.page}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Type:</b></Typography></td>
                <td><Typography>{rowData?.type}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Image:</b></Typography></td>
                <td><Typography>{rowData?.image}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Video:</b></Typography></td>
                <td><Typography>{rowData?.video}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Start Date:</b></Typography></td>
                <td><Typography>{moment(rowData?.start_date).format('YYYY-MM-DD hh:mmA')}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>End Date:</b></Typography></td>
                <td><Typography>{moment(rowData?.end_date).format('YYYY-MM-DD hh:mmA')}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Status:</b></Typography></td>
                <td><Typography>{rowData?.status ? 'Active' : 'Inactive'}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Created date:</b></Typography></td>
                <td><Typography>{moment(rowData?.createdAt).format('YYYY-MM-DD hh:mmA')}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Created By:</b></Typography></td>
                <td>
                  <Typography>
                    {rowData?.createdByData?.[0]?.first_name || ''} {rowData?.createdByData?.[0]?.last_name || ''}
                  </Typography>
                </td>
              </tr>
            </table>
          </div>
        </DialogContent>
      </Dialog>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deletePopup(ID)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Popup_list
