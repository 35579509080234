export const GET_ALL_POPUP_LOADING = 'GET_ALL_POPUP_LOADING';
export const GET_ALL_POPUP_SUCCESS = 'GET_ALL_POPUP_SUCCESS';
export const GET_ALL_POPUP_ERROR = 'GET_ALL_POPUP_ERROR';

export const CREATE_POPUP_LOADING = 'CREATE_POPUP_LOADING';
export const CREATE_POPUP_SUCCESS = 'CREATE_POPUP_SUCCESS';
export const CREATE_POPUP_ERROR = 'CREATE_POPUP_ERROR';

export const UPDATE_POPUP_LOADING = 'UPDATE_POPUP_LOADING';
export const UPDATE_POPUP_SUCCESS = 'UPDATE_POPUP_SUCCESS';
export const UPDATE_POPUP_ERROR = 'UPDATE_POPUP_ERROR';

export const DELETE_POPUP_LOADING = 'DELETE_POPUP_LOADING';
export const DELETE_POPUP_SUCCESS = 'DELETE_POPUP_SUCCESS';
export const DELETE_POPUP_ERROR = 'DELETE_POPUP_ERROR';
