import { GET_ALL_SEARCH_USER_ERROR, GET_ALL_SEARCH_USER_LOADING, GET_ALL_SEARCH_USER_SUCCESS, GET_ALL_USERS_REF_COUNT_ERROR, GET_ALL_USERS_REF_COUNT_LOADING, GET_ALL_USERS_REF_COUNT_SUCCESS, UPDATE_USER_STATUS_ERROR, UPDATE_USER_STATUS_LOADING, UPDATE_USER_STATUS_SUCCESS } from "./actionTypes";

const initialState = {
    allUserRefCount: {
        loading: false,
        data: null,
        error: null
    },
    allSearchUser: {
        loading: false,
        data: null,
        error: null
    },
    updateUserStatus: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_USERS_REF_COUNT_LOADING:
            return { ...state, allUserRefCount: { ...state.allUserRefCount, loading: true, data: null, error: null } };
        case GET_ALL_USERS_REF_COUNT_SUCCESS:
            return { ...state, allUserRefCount: { ...state.allUserRefCount, loading: false, data: action.payload, error: null } };
        case GET_ALL_USERS_REF_COUNT_ERROR:
            return { ...state, allUserRefCount: { ...state.allUserRefCount, loading: false, data: null, error: action.payload } };

        case GET_ALL_SEARCH_USER_LOADING:
            return { ...state, allSearchUser: { ...state.allSearchUser, loading: true, data: null, error: null } };
        case GET_ALL_SEARCH_USER_SUCCESS:
            return { ...state, allSearchUser: { ...state.allSearchUser, loading: false, data: action.payload, error: null } };
        case GET_ALL_SEARCH_USER_ERROR:
            return { ...state, allSearchUser: { ...state.allSearchUser, loading: false, data: null, error: action.payload } };

        case UPDATE_USER_STATUS_LOADING:
            return { ...state, updateUserStatus: { ...state.updateUserStatus, loading: true, data: null, error: null } };
        case UPDATE_USER_STATUS_SUCCESS:
            return { ...state, updateUserStatus: { ...state.updateUserStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_USER_STATUS_ERROR:
            return { ...state, updateUserStatus: { ...state.updateUserStatus, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
