import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Avatar, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Grow, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Slide, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import { getUserRefCountListAction, updateUserStatusAction } from '../../store/user/asyncActions';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { getUserWalletAction } from '../../store/withdraw-request/asyncActions';
import { creditDebitAmountAction, getUserWalletLedgerAction } from '../../store/wallet/asyncActions';
import classNames from 'classnames';
import { getGeneratedRefByUserAction, getReceivedRefByUserAction } from '../../store/referral/asyncActions';
import { getAllPermissionAction } from '../../store/role/asyncActions';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function Users() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openWalletModal, setOpenWalletModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openWalletForm, setOpenWalletForm] = useState(false);
  const [ID, setID] = useState(null);
  const [rowData, setRowData] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();

  const userRefCountListData = useSelector((state) => state?.user?.allUserRefCount);
  const updateUserStatusData = useSelector((state) => state?.user?.updateUserStatus);
  const userWalletData = useSelector((state) => state?.withdrawReq?.userWallet);
  const userWalletLedgerData = useSelector((state) => state?.wallet?.userWalletLedger);
  const creditDebitAmountData = useSelector((state) => state?.wallet?.creditDebitAmount);

  const [userRefCountList, setUserRefCountList] = useState(null);
  const [walletBalance, setWalletBalance] = useState(null);
  const [walletLedger, setWalletLedger] = useState(null);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const userID = params.get('userID');
    const userName = params.get('userName');
    if (userID && userName) {
      walletView({ _id: userID, name: userName });
    }
  }, []);

  useEffect(() => {
    dispatch(getUserRefCountListAction(page + 1, pageSize, filter));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Users - Referral App';
  }, []);

  useEffect(() => {
    if (userRefCountListData?.data?.status && Array.isArray(userRefCountListData?.data?.data?.records)) {
      setUserRefCountList(userRefCountListData?.data?.data?.records);
      setTotalCount(userRefCountListData?.data?.data?.totalCount);
    }
  }, [userRefCountListData]);

  useEffect(() => {
    if (updateUserStatusData?.data?.status) {
      dispatch(getUserRefCountListAction(page + 1, pageSize, filter));
      updateUserStatusData.data = null;
    }
  }, [updateUserStatusData]);

  useEffect(() => {
    if (userWalletData?.data?.status) {
      setWalletBalance(userWalletData?.data?.data?.[0]?.balance);
    }
  }, [userWalletData]);

  useEffect(() => {
    if (userWalletLedgerData?.data?.status) {
      setWalletLedger(userWalletLedgerData?.data?.data);
    }
  }, [userWalletLedgerData]);

  useEffect(() => {
    if (creditDebitAmountData?.data?.status) {
      // setOpenWalletModal(false);
      setOpenWalletForm(false);
      setWalletFormData({
        ...walletFormData,
        description: '',
        amount: 0,
        type: 'credited'
      });
      dispatch(getUserWalletAction(creditDebitAmountData?.data?.data?.[0]?.user_id));
      dispatch(getUserWalletLedgerAction({
        id: creditDebitAmountData?.data?.data?.[0]?.user_id
      }));
      creditDebitAmountData.data = null;
    }
  }, [creditDebitAmountData]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    name: '',
    mobile: '',
    status: ''
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getUserRefCountListAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      from: '',
      to: '',
      name: '',
      mobile: '',
      status: ''
    }
    setFilter(reset);
    dispatch(getUserRefCountListAction(page + 1, pageSize, reset));
  }

  const updateUserStatus = (data) => {
    {
      adminPermission && (adminPermission.includes('Edit User') || adminPermission.includes('All')) &&
        dispatch(updateUserStatusAction(data));
    }
  }

  const walletView = (row) => {
    setWalletBalance(null);
    setWalletLedger(null);
    setOpenWalletForm(false);

    dispatch(getUserWalletAction(row?._id));
    dispatch(getUserWalletLedgerAction({ id: row?._id }));
    setWalletFormData({
      ...walletFormData,
      user_id: row?._id,
      description: '',
      amount: 0,
      type: 'credited'
    });
    setRowData(row);
    setOpenWalletModal(true);
  }

  const [walletFormData, setWalletFormData] = useState({
    user_id: '',
    description: '',
    amount: 0,
    type: 'credited'
  });

  const resetWalletForm = () => {
    setWalletFormData({
      user_id: '',
      description: '',
      amount: 0,
      type: 'credited'
    });
  }

  const DataHandleChange = (e) => {
    setWalletFormData({
      ...walletFormData,
      [e.target.name]: e.target.value
    });
  }

  const [errors, setErrors] = useState({});
  const validationSchema = Joi.object({
    user_id: Joi.string().required(),
    // description: Joi.string().allow(null, ''),
    description: Joi.string().required(),
    amount: Joi.number().min(1).required(),
    type: Joi.string().valid('credited', 'debited').required()
  });

  const save = () => {
    const validationResult = validationSchema.validate(walletFormData, { abortEarly: false });
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
    } else {
      setErrors({});
      dispatch(creditDebitAmountAction(walletFormData));
    }
  }

  const [tab, setTab] = useState(0);

  const [modelPage, setModelPage] = useState(0);
  const [modelPageSize, setModelPageSize] = useState(10);
  const [modelTotalCount, setModelTotalCount] = useState(0);
  const handleModelPageChange = (newPage) => {
    setModelPage(newPage);
  };
  const [modelRefList, setModelRefList] = useState(null);

  useEffect(() => {
    if (tab === 1) {
      setModelRefList(null);
      dispatch(getGeneratedRefByUserAction({
        user_id: rowData?._id,
        page: modelPage + 1,
        size: modelPageSize,
      }));
    }
    if (tab === 2) {
      setModelRefList(null);
      dispatch(getReceivedRefByUserAction({
        user_id: rowData?._id,
        page: modelPage + 1,
        size: modelPageSize,
      }));
    }
  }, [tab, modelPage, modelPageSize]);

  const generatedRefByUsr = useSelector((state) => state?.referral?.generatedRefByUser);
  const receivedRefByUsr = useSelector((state) => state?.referral?.receivedRefByUser);

  useEffect(() => {
    if (generatedRefByUsr?.data?.status && Array.isArray(generatedRefByUsr?.data?.data?.records)) {
      setModelRefList(generatedRefByUsr?.data?.data?.records);
      setModelTotalCount(generatedRefByUsr?.data?.data?.totalCount);
    }
  }, [generatedRefByUsr]);

  useEffect(() => {
    if (receivedRefByUsr?.data?.status && Array.isArray(receivedRefByUsr?.data?.data?.records)) {
      setModelRefList(receivedRefByUsr?.data?.data?.records);
      setModelTotalCount(receivedRefByUsr?.data?.data?.totalCount);
    }
  }, [receivedRefByUsr]);

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Users
            </Typography>
            {/* <Tooltip title={'Add New Category'} placement="left">
              <Button color='default' variant='outlined' onClick={() => { resetForm(); setOpenModal(true); }}>+ Add New</Button>
            </Tooltip> */}
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        {/* {openFilter && */}
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                variant="outlined"
                label="From Date"
                type="date"
                name='from'
                className='w-100'
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={filter?.from}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                variant="outlined"
                label="To Date"
                type="date"
                name='to'
                className='w-100'
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: filter?.from, // This is the minimum date value you want to set
                  },
                }}
                value={filter?.to}
                onChange={(e) => filterHandleChange(e.target.value.toISOString())}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                required
                name='name'
                label="Name"
                variant="outlined"
                size="small"
                value={filter?.name}
                onChange={(e) => filterHandleChange(e.target.value.toISOString())}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                required
                type='number'
                name='mobile'
                label="Mobile"
                variant="outlined"
                size="small"
                value={filter?.mobile}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  name='status'
                  label="Status"
                  value={filter?.status}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        {/* } */}
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>Total Referral Created</TableCell>
                  {/* <TableCell>Genuine Referral</TableCell>
                  <TableCell>Fake Referral</TableCell> */}
                  <TableCell>Deal Done</TableCell>
                  <TableCell>Purchased By Seller</TableCell>
                  <TableCell>Yet to be Purchase</TableCell>
                  <TableCell>Referral Received</TableCell>
                  <TableCell>Purchased</TableCell>
                  <TableCell>Yet to be Purchase</TableCell>
                  {/* <TableCell>Genuine Referral</TableCell>
                  <TableCell>Fake Referral</TableCell> */}
                  <TableCell>Deal Done</TableCell>
                  <TableCell>Create Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userRefCountList && userRefCountList.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>{row?.name}</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>{row?.mobile}</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>{row?.total_created}</TableCell>
                      {/* <TableCell style={{ minWidth: '125px' }}>{row?.genuine_created}</TableCell>
                      <TableCell style={{ minWidth: '115px' }}>{row?.fake_created}</TableCell> */}
                      <TableCell style={{ minWidth: '100px' }}>{row?.deal_done_created}</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>{row?.purchased_by_seller_created}</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>{row?.yet_to_be_purchase_created}</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>{row?.total_received}</TableCell>
                      <TableCell style={{ minWidth: '100px' }}>{row?.purchased_received}</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>{row?.yet_to_be_purchase_received}</TableCell>
                      {/* <TableCell style={{ minWidth: '150px' }}>{row?.genuine_received}</TableCell>
                      <TableCell style={{ minWidth: '115px' }}>{row?.fake_received}</TableCell> */}
                      <TableCell style={{ minWidth: '100px' }}>{row?.deal_done_received}</TableCell>
                      <TableCell style={{ minWidth: '150px' }}>{row?.createdAt}</TableCell>
                      <TableCell style={{ minWidth: '100px' }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row?.user_status}
                              onChange={(e) => updateUserStatus({ id: row?._id, status: e.target.checked })}
                              color="primary"
                              disabled={!adminPermission || (!adminPermission.includes('Edit User') && !adminPermission.includes('All'))}
                            />
                          }
                          label={row?.user_status ? 'Active' : 'Inactive'}
                        />
                      </TableCell>
                      <TableCell style={{ minWidth: '175px' }} align='right'>
                        {adminPermission && (adminPermission.includes('User Details') || adminPermission.includes('All')) &&
                          <Tooltip title={'View'} placement="left">
                            <IconButton onClick={() => { setRowData(row); setTab(0); setOpenDetailsModal(true) }}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {adminPermission && (adminPermission.includes('View User Wallet') || adminPermission.includes('All')) &&
                          <Tooltip title={'Wallet'} placement="left">
                            <IconButton onClick={() => walletView(row)}>
                              <AccountBalanceWalletIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {/* <Tooltip title={'Edit'} placement="left">
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Tooltip> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* User details view dialog */}
      <Dialog
        fullScreen
        open={openDetailsModal}
        onClose={() => { setOpenDetailsModal(false); setRowData(null) }}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => { setOpenDetailsModal(false); setRowData(null) }} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <div style={{ marginLeft: 'auto' }}>
              <Typography variant="h6" color="inherit" align='center'>
                {rowData?.name} | {rowData?.mobile}
              </Typography>
              <Typography color="inherit" align='center'>
                Created At: &nbsp; {rowData?.createdAt} &nbsp; | &nbsp; Status: &nbsp; {rowData?.user_status ? 'Active' : 'Inactive'}
              </Typography>
            </div>
            <Button color="inherit" style={{ marginLeft: 'auto' }}>
              {/* {categoryFormData?.id ? 'Update' : 'Save'} */}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <div className='w-100'>
            <AppBar position="static" color='default' className={`${classes.root} w-100`}>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                centered
                value={tab}
                onChange={(e, value) => { setModelPage(0); setTab(value); }}>
                <Tab label="Profile Details" />
                <Tab label="Generated Referral" />
                <Tab label="Received Referral" />
              </Tabs>
            </AppBar>
            {tab === 0 &&
              <div className='pt-2'>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={4}>
                    <div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Avatar
                          alt="User Profile Image"
                          src={rowData?.user_profile ? rowData?.user_profile : '/static/images/avatar.png'}
                          className={classNames(classes.avatar)}
                          style={{ height: '150px', width: '150px' }}
                        />
                      </div>
                      <div className='pt-2' style={{ display: 'flex', justifyContent: 'center' }}>
                        <table style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>
                          <tr>
                            <td style={{ minWidth: '100px' }}><b>Name: </b></td>
                            <td align='right'>{rowData?.name}</td>
                          </tr>
                          <tr>
                            <td><b>Mobile: </b></td>
                            <td align='right'>{`+${rowData?.user_country_code} ${rowData?.mobile}`}</td>
                          </tr>
                          <tr>
                            <td><b>State: </b></td>
                            <td align='right'>{rowData?.user_state}</td>
                          </tr>
                          <tr>
                            <td><b>City: </b></td>
                            <td align='right'>{rowData?.user_city}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <Grid container spacing={2}>

                      <Grid item xs={12} md={6} lg={4}>
                        <Card className='w-100 py-2'>
                          <Typography variant="h6" color="primary" align='center'>
                            Total Referral Created
                          </Typography>
                          <Typography color="inherit" align='center' className='pt-2' style={{ fontSize: '20px' }}>
                            {rowData?.total_created}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Card className='w-100 py-2'>
                          <Typography variant="h6" color="primary" align='center'>
                            Deal Done
                          </Typography>
                          <Typography color="inherit" align='center' className='pt-2' style={{ fontSize: '20px' }}>
                            {rowData?.deal_done_created}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Card className='w-100 py-2'>
                          <Typography variant="h6" color="primary" align='center'>
                            Purchased By Seller
                          </Typography>
                          <Typography color="inherit" align='center' className='pt-2' style={{ fontSize: '20px' }}>
                            {rowData?.purchased_by_seller_created}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Card className='w-100 py-2'>
                          <Typography variant="h6" color="primary" align='center'>
                            Yet to be Purchase
                          </Typography>
                          <Typography color="inherit" align='center' className='pt-2' style={{ fontSize: '20px' }}>
                            {rowData?.yet_to_be_purchase_created}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Card className='w-100 py-2'>
                          <Typography variant="h6" color="primary" align='center'>
                            Referral Received
                          </Typography>
                          <Typography color="inherit" align='center' className='pt-2' style={{ fontSize: '20px' }}>
                            {rowData?.total_received}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Card className='w-100 py-2'>
                          <Typography variant="h6" color="primary" align='center'>
                            Purchased
                          </Typography>
                          <Typography color="inherit" align='center' className='pt-2' style={{ fontSize: '20px' }}>
                            {rowData?.purchased_received}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Card className='w-100 py-2'>
                          <Typography variant="h6" color="primary" align='center'>
                            Yet to be Purchase
                          </Typography>
                          <Typography color="inherit" align='center' className='pt-2' style={{ fontSize: '20px' }}>
                            {rowData?.yet_to_be_purchase_received}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Card className='w-100 py-2'>
                          <Typography variant="h6" color="primary" align='center'>
                            Deal Done
                          </Typography>
                          <Typography color="inherit" align='center' className='pt-2' style={{ fontSize: '20px' }}>
                            {rowData?.deal_done_received}
                          </Typography>
                        </Card>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </div>
            }
            {tab === 1 &&
              <div className='pt-2'>
                <TableContainer className='Custom_Datatable'>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Referral Number</TableCell>
                        <TableCell>Buyer Name</TableCell>
                        <TableCell>Buyer Mobile</TableCell>
                        <TableCell>Seller Name</TableCell>
                        <TableCell>Seller Mobile</TableCell>
                        <TableCell>Requirments</TableCell>
                        <TableCell>Item Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modelRefList && modelRefList.map((row, index) => {
                        return (
                          <TableRow key={row?._id}>
                            <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.referral_number}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.buyer_name}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.buyer_mobile}</TableCell>
                            <TableCell style={{ minWidth: '100px' }}>{row?.seller_name}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.seller_mobile}</TableCell>
                            <TableCell style={{ minWidth: '250px' }}>{row?.requirments}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.item_name}</TableCell>
                            <TableCell style={{ minWidth: '100px' }}>{row?.status}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.created_at}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={modelTotalCount}
                  rowsPerPage={modelPageSize}
                  page={modelPage}
                  rowsPerPageOptions={[5, 10, 20]}
                  onChangePage={(e, page) => handleModelPageChange(page)}
                  onChangeRowsPerPage={(e) => setModelPageSize(e.target.value)}
                />
              </div>
            }
            {tab === 2 &&
              <div className='pt-2'>
                <TableContainer className='Custom_Datatable'>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Referral Number</TableCell>
                        <TableCell>Buyer Name</TableCell>
                        <TableCell>Buyer Mobile</TableCell>
                        <TableCell>Referrar Name</TableCell>
                        <TableCell>Referrar Mobile</TableCell>
                        <TableCell>Requirments</TableCell>
                        <TableCell>Item Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modelRefList && modelRefList.map((row, index) => {
                        return (
                          <TableRow key={row?._id}>
                            <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.referral_number}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.buyer_name}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.buyer_mobile}</TableCell>
                            <TableCell style={{ minWidth: '100px' }}>{row?.referrar_name}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.referrar_mobile}</TableCell>
                            <TableCell style={{ minWidth: '250px' }}>{row?.requirments}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.item_name}</TableCell>
                            <TableCell style={{ minWidth: '100px' }}>{row?.status}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.created_at}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={modelTotalCount}
                  rowsPerPage={modelPageSize}
                  page={modelPage}
                  rowsPerPageOptions={[5, 10, 20]}
                  onChangePage={(e, page) => handleModelPageChange(page)}
                  onChangeRowsPerPage={(e) => setModelPageSize(e.target.value)}
                />
              </div>
            }
          </div>
          {/* <div>
            <table>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Name:</b></Typography></td>
                <td><Typography>{rowData?.name}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Mobile Number:</b></Typography></td>
                <td><Typography>{rowData?.mobile}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Total Referral Created:</b></Typography></td>
                <td><Typography>{rowData?.total_created}</Typography></td>
              </tr>

              <tr>
                <td><Typography><b>Genuine Referral:</b></Typography></td>
                <td><Typography>{rowData?.genuine_created}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Fake Referral:</b></Typography></td>
                <td><Typography>{rowData?.fake_created}</Typography></td>
              </tr>

              <tr>
                <td><Typography><b>Deal Done:</b></Typography></td>
                <td><Typography>{rowData?.deal_done_created}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Purchased By Seller:</b></Typography></td>
                <td><Typography>{rowData?.purchased_by_seller_created}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Yet to be Purchase:</b></Typography></td>
                <td><Typography>{rowData?.yet_to_be_purchase_created}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Referral Received:</b></Typography></td>
                <td><Typography>{rowData?.total_received}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Purchased:</b></Typography></td>
                <td><Typography>{rowData?.purchased_received}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Yet to be Purchase:</b></Typography></td>
                <td><Typography>{rowData?.yet_to_be_purchase_received}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Genuine Referral:</b></Typography></td>
                <td><Typography>{rowData?.genuine_received}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Fake Referral:</b></Typography></td>
                <td><Typography>{rowData?.fake_received}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Deal Done:</b></Typography></td>
                <td><Typography>{rowData?.deal_done_received}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Create Date:</b></Typography></td>
                <td><Typography>{rowData?.createdAt}</Typography></td>
              </tr>
            </table>
          </div> */}
        </DialogContent>
      </Dialog>

      {/* User Wallet view dialog */}
      <Dialog
        fullScreen
        open={openWalletModal}
        onClose={() => { setOpenWalletModal(false) }}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => { setOpenWalletModal(false); setRowData(null) }} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              Wallet | {rowData?.name}
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }}>
              {/* {categoryFormData?.id ? 'Update' : 'Save'} */}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: '450px', width: '100%' }}>
              <div style={{ display: 'flex' }}>
                <Typography variant="h6" color="inherit">
                  Balance:
                </Typography>
                <Typography variant="h6" color="inherit" style={{ marginLeft: 'auto' }}>
                  {walletBalance}
                </Typography>
              </div>
              <hr className='my-1' />
              {!openWalletForm && adminPermission && (adminPermission.includes('Credit Debit Amount') || adminPermission.includes('All')) &&
                <Button color="primary" variant='contained' className='w-100 mb-2' onClick={() => setOpenWalletForm(true)}>
                  Credit / Debit Amount
                </Button>
              }
              <Grid className={`filterOuter ${openWalletForm && 'activeOpen'}`} container style={{ maxWidth: '450px' }}>
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 pt-1'>
                  <TextField
                    className='w-100'
                    required
                    name='description'
                    label="Description"
                    variant="outlined"
                    size="small"
                    rows={4}
                    multiline
                    value={walletFormData?.description}
                    error={errors?.description}
                    helperText={errors?.description}
                    onChange={(e) => DataHandleChange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2'>
                  <TextField
                    className='w-100'
                    required
                    name='amount'
                    label="Amount"
                    variant="outlined"
                    size="small"
                    type='number'
                    value={walletFormData?.amount}
                    error={errors?.amount}
                    helperText={errors?.amount}
                    onChange={(e) => DataHandleChange(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2'>
                  <FormControl component="fieldset">
                    <RadioGroup
                      style={{ flexDirection: 'row' }}
                      name="type"
                      value={walletFormData?.type}
                      onChange={(e) => DataHandleChange(e)}
                    >
                      <FormControlLabel value="credited" control={<Radio color="primary" />} label="Credited" />
                      <FormControlLabel value="debited" control={<Radio color="primary" />} label="Debited" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2'>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button color="primary" variant='contained' className='mb-2' style={{ width: '150px' }} onClick={() => save()}>
                      Confirm
                    </Button>
                    <Button color="inherit" variant='contained' className='mb-2' style={{ width: '150px' }} onClick={() => setOpenWalletForm(false)}>
                      Cancel
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          {/* <hr className='my-1' /> */}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: '800px', overflowX: 'auto' }}>
              <table className='w-100 ActivityLogTBL'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {walletLedger && walletLedger.map((item) => {
                    return (
                      <tr>
                        <td style={{ minWidth: '125px' }}>{item?.created_at}</td>
                        <td style={{ maxWidth: '375px' }}>{item?.description}</td>
                        <td style={{ minWidth: '65px', textAlign: 'right' }}>{item?.dr}</td>
                        <td style={{ minWidth: '65px', textAlign: 'right' }}>{item?.cr}</td>
                        <td style={{ minWidth: '65px', textAlign: 'right' }}>{item?.totalBalance}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </DialogContent>
      </Dialog>


      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Users
