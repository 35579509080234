import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, MenuItem, Paper, Select, Slide, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import { getAllReferralsAction } from '../../store/referral/asyncActions';
import { Autocomplete } from '@material-ui/lab';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { searchItemAction } from '../../store/item/asyncActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getAllPermissionAction } from '../../store/role/asyncActions';
import { statusColor } from '../../helpers';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function Referral_list() {
  const classes = useStyles();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);
  const [rowData, setRowData] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();

  const referralListData = useSelector((state) => state?.referral?.allReferrals);
  const itemSearch = useSelector((state) => state?.item?.searchItem);

  const [referralList, setReferralList] = useState(null);
  const [searchItem, setSearchItem] = useState(null);
  const [item, setItem] = useState(null);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    dispatch(getAllReferralsAction(page + 1, pageSize, filter));
    dispatch(searchItemAction(''));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Referral List - Referral App';
  }, []);

  useEffect(() => {
    if (referralListData?.data?.status && Array.isArray(referralListData?.data?.data?.records)) {
      setReferralList(referralListData?.data?.data?.records);
      setTotalCount(referralListData?.data?.data?.totalCount);
    }
  }, [referralListData]);

  useEffect(() => {
    if (itemSearch?.data?.status && Array.isArray(itemSearch?.data?.data)) {
      setSearchItem(itemSearch?.data?.data)
    }
  }, [itemSearch]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    name: '',
    mobile: '',
    status: '',
    type: '',
    item: ''
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getAllReferralsAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      from: '',
      to: '',
      name: '',
      mobile: '',
      status: '',
      type: '',
      item: ''
    }
    setFilter(reset);
    setItem(null);
    dispatch(getAllReferralsAction(page + 1, pageSize, reset));
  }

  const checkWallet = (userID, userName) => {
    if (userID && userName && userID !== '' && userName !== '') {
      history.push(`/users?userID=${userID}&userName=${userName}`);
    }
  }

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Referral List
            </Typography>
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        {/* {openFilter && */}
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid item xs={6} sm={6} md={3} xl={3} className='mb-2 px-1'>
              <TextField
                variant="outlined"
                label="From Date"
                type="date"
                name='from'
                className='w-100'
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={filter?.from}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} xl={3} className='mb-2 px-1'>
              <TextField
                variant="outlined"
                label="To Date"
                type="date"
                name='to'
                className='w-100'
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: filter?.from, // This is the minimum date value you want to set
                  },
                }}
                value={filter?.to}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} xl={3} className='mb-2 px-1'>
              <TextField
                className='w-100'
                required
                name='name'
                label="Name"
                variant="outlined"
                size="small"
                value={filter?.name}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} xl={3} className='mb-2 px-1'>
              <TextField
                className='w-100'
                required
                type='number'
                name='mobile'
                label="Mobile"
                variant="outlined"
                size="small"
                value={filter?.mobile}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} xl={3} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  name='status'
                  label="Status"
                  value={filter?.status}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='Pending'>Pending</MenuItem>
                  <MenuItem value='Purchased'>Purchased</MenuItem>
                  <MenuItem value='Refund-Requested'>Refund-Requested</MenuItem>
                  <MenuItem value='Refund-Approved'>Refund-Approved</MenuItem>
                  <MenuItem value='Refund-Rejected'>Refund-Rejected</MenuItem>
                  <MenuItem value='Deal-Done'>Deal-Done</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={3} xl={3} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                <Select
                  name='type'
                  label="Type"
                  value={filter?.type}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="service">Service</MenuItem>
                  <MenuItem value="product">Product</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={3} xl={3} className='mb-2 px-1'>
              <Autocomplete
                options={searchItem || []}
                getOptionLabel={(option) => option.item_name}
                value={item}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Item"
                    placeholder="Search name"
                    size='small'
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  dispatch(searchItemAction(newInputValue))
                }}
                onChange={(event, selectedOptions) => {
                  setItem(selectedOptions);
                  setFilter({
                    ...filter,
                    item: selectedOptions?._id ? selectedOptions?._id : ''
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} xl={3} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        {/* } */}
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Referral No.</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Reffered By</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Reffered To</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Buyer</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Type</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Item</TableCell>
                  <TableCell style={{ minWidth: '175px' }}>Status</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Created Date</TableCell>
                  <TableCell style={{ minWidth: '125px' }} align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referralList && referralList.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>{row?.referral_number}</TableCell>
                      <TableCell>
                        {row?.user_name} <br /> M - {row?.user_mobile}
                      </TableCell>
                      <TableCell>
                        {row?.seller_name} <br /> M - {row?.seller_mobile}
                      </TableCell>
                      <TableCell>
                        {row?.buyer_name} <br /> M - {row?.buyer_mobile}
                      </TableCell>
                      <TableCell>{row?.type}</TableCell>
                      <TableCell>{row?.item_name}</TableCell>
                      <TableCell>
                        <div className='StatusDiv'>
                          <div className={`StatusDot ${statusColor(row?.status)}`}></div>
                          {row?.status}
                        </div>
                      </TableCell>
                      <TableCell>{row?.createdAt}</TableCell>
                      <TableCell align='right'>
                        {adminPermission && (adminPermission.includes('Referral Details') || adminPermission.includes('All')) &&
                          <Tooltip title={'View'} placement="left">
                            <IconButton onClick={() => { setRowData(row); setOpenDetailsModal(true) }}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {/* <Tooltip title={'Edit'} placement="left">
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Tooltip> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* User details view dialog */}
      <Dialog
        fullScreen
        open={openDetailsModal}
        onClose={() => { setOpenDetailsModal(false); setRowData(null) }}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => { setOpenDetailsModal(false); setRowData(null) }} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              Referral Details
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }}>
              {/* {categoryFormData?.id ? 'Update' : 'Save'} */}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <table style={{ width: '100%', maxWidth: '425px' }}>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Referral ID:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{rowData?.referral_number}</Typography></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Created At:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{rowData?.createdAt}</Typography></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Status:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{rowData?.status}</Typography></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Price:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{rowData?.price}</Typography></td>
                  </tr>
                  <tr>
                    <td colSpan={2} className='pt-2'>
                      <Typography variant="h6">Requirement:</Typography>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Type:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{rowData?.type}</Typography></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Category:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{rowData?.category_name}</Typography></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Requirments:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{rowData?.requirments}</Typography></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Commision:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{rowData?.commision}</Typography></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography variant="h6" className='pt-2'>Referrer:</Typography></td>
                    <td>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', height: '50px' }}>
                        <Button color="primary" variant='contained' size='small' style={{ marginTop: 'auto' }} onClick={() => checkWallet(rowData?.user_id, rowData?.user_name)}>
                          Check Wallet
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}><hr /></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Name:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{rowData?.user_name}</Typography></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Mobile:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{'+' + rowData?.user_mobile_code + rowData?.user_mobile}</Typography></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography variant="h6" className='pt-2'>Seller:</Typography></td>
                    <td>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', height: '50px' }}>
                        <Button color="primary" variant='contained' size='small'
                          style={{ marginTop: 'auto' }}
                          disabled={!rowData?.seller_id}
                          onClick={() => checkWallet(rowData?.seller_id, rowData?.seller_name)}
                        >
                          Check Wallet
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}><hr /></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Name:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{rowData?.seller_name}</Typography></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Mobile:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{'+' + rowData?.seller_mobile_code + rowData?.seller_mobile}</Typography></td>
                  </tr>
                  <tr>
                    <td colSpan={2} className='pt-2'>
                      <Typography variant="h6">Buyer:</Typography>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Name:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{rowData?.buyer_name}</Typography></td>
                  </tr>
                  <tr>
                    <td style={{ width: '1.75in' }}><Typography><b>Mobile:</b></Typography></td>
                    <td style={{ textAlign: 'right' }}><Typography>{'+' + rowData?.buyer_mobile_code + rowData?.buyer_mobile}</Typography></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          {/* <div>
            <table>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Reffered By:</b></Typography></td>
                <td><Typography>{rowData?.user_name}, <span style={{ marginLeft: '20px' }}>M - {rowData?.user_mobile}</span></Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Reffered To:</b></Typography></td>
                <td><Typography>{rowData?.seller_name}, <span style={{ marginLeft: '20px' }}>M - {rowData?.seller_mobile}</span></Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Buyer:</b></Typography></td>
                <td><Typography>{rowData?.buyer_name}, <span style={{ marginLeft: '20px' }}>M - {rowData?.buyer_mobile}</span></Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Type:</b></Typography></td>
                <td><Typography>{rowData?.type}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Item:</b></Typography></td>
                <td><Typography>{rowData?.item_name}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Status:</b></Typography></td>
                <td><Typography>{rowData?.status}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Create Date:</b></Typography></td>
                <td><Typography>{rowData?.createdAt}</Typography></td>
              </tr>
            </table>
          </div> */}
        </DialogContent>
      </Dialog>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Referral_list
