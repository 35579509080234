import { createPopupError, createPopupLoading, createPopupSuccess, deletePopupError, deletePopupLoading, deletePopupSuccess, getAllPopupError, getAllPopupLoading, getAllPopupSuccess, updatePopupError, updatePopupLoading, updatePopupSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllPopupsAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllPopupLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&title=${filter?.title}&status=${filter?.status}`;
      const response = await api.get(`popup/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllPopupSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllPopupError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createPopupsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(createPopupLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('popup/create', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(createPopupSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(createPopupError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updatePopupsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(updatePopupLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('popup/update', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(updatePopupSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(updatePopupError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deletePopupsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(deletePopupLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('popup/delete', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(deletePopupSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(deletePopupError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllPopupsAction,
  createPopupsAction,
  updatePopupsAction,
  deletePopupsAction
};
