import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, MenuItem, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import { createRoleAction, deleteRoleAction, getAllPermissionAction, getAllRolesAction, updateRoleAction } from '../../store/role/asyncActions';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function AdminRoles() {
  const classes = useStyles();
  const [roles, setRoles] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [roleID, setRoleID] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();
  const adminRoles = useSelector((state) => state?.role?.getAllRoles);

  const newRole = useSelector((state) => state?.role?.createRole);
  const updatedRole = useSelector((state) => state?.role?.updateRole);
  // const updatedRoleStatus = useSelector((state) => state?.role?.updateRoleStatus);
  const deletedRole = useSelector((state) => state?.role?.deleteRole);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermissionList, setAdminPermissionList] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermissionList(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    dispatch(getAllRolesAction(page + 1, pageSize));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Roles - Referral App';
  }, []);

  useEffect(() => {
    if (adminRoles?.data?.status && Array.isArray(adminRoles?.data?.data?.records)) {
      setRoles(adminRoles?.data?.data?.records)
    }
  }, [adminRoles]);

  const [roleFormData, setRoleFormData] = useState({
    role_name: '',
    permissions: null
  });

  const resetForm = () => {
    setErrors({});
    setRoleFormData({
      role_name: '',
      permissions: null
    });
    setSelectedPermissions({});
  }

  const roleDataHandleChange = (e) => {
    setRoleFormData({
      ...roleFormData,
      [e.target.name]: e.target.value
    });
  }

  const createRole = () => {
    dispatch(createRoleAction(roleFormData))
  }

  useEffect(() => {
    if (newRole?.data?.status) {
      setOpenModal(false);
      dispatch(getAllRolesAction(page + 1, pageSize));
      resetForm();
      newRole.data = null;
    }
  }, [newRole]);

  useEffect(() => {
    if (updatedRole?.data?.status) {
      setOpenModal(false);
      dispatch(getAllRolesAction(page + 1, pageSize));
      resetForm();
      updatedRole.data = null;
    }
  }, [updatedRole]);

  // useEffect(() => {
  //   if (updatedRoleStatus?.data?.success) {
  //     dispatch(getAllRolesAction(page + 1, pageSize));
  //     updatedRoleStatus.data = null;
  //   }
  // }, [updatedRoleStatus]);

  useEffect(() => {
    if (deletedRole?.data?.status) {
      dispatch(getAllRolesAction(page + 1, pageSize));
      setDeleteModal(false);
      setRoleID(null);
      deletedRole.data = null;
    }
  }, [deletedRole]);

  const editRole = (row) => {
    setErrors({});
    setRoleFormData({
      id: row?._id,
      role_name: row?.role_name,
      permissions: row?.permissions
    });
    setSelectedPermissions(row?.permissions);
    setOpenModal(true);
  }

  const updateRole = () => {
    dispatch(updateRoleAction(roleFormData))
  }

  const updateRoleStatus = (data) => {
    dispatch(adminUpdateRoleStatusAction(data))
  }

  const save = () => {
    const validationResult = validationSchema.validate(roleFormData, { abortEarly: false });
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
    } else {
      setErrors({});
      if (roleFormData?.id && roleFormData?.id !== '' && roleFormData?.id !== null) {
        updateRole()
      } else {
        createRole()
      }
    }
  }

  const deleteRole = (roleID) => {
    dispatch(deleteRoleAction({ id: roleID }));
  }

  const [errors, setErrors] = useState({});
  const validationSchema = Joi.object({
    id: Joi.string().allow(null, ''),
    role_name: Joi.string().required().label('Role Name'),
    permissions: Joi.object().required().min(1).label('Permissions'),
  });

  const adminPermission = {
    Admin: [
      'View Admin',
      'Add Admin',
      'Edit Admin',
      'Delete Admin'
    ],
    Role: [
      'View Role',
      'Add Role',
      'Edit Role',
      'Delete Role'
    ],
    Category: [
      'View Category',
      'Add Category',
      'Edit Category',
      'Delete Category'
    ],
    Item: [
      'View Item',
      'Add Item',
      'Edit Item',
      'Delete Item'
    ],
    User: [
      'View User',
      'User Details',
      'Edit User',
      'Delete User',
      'View User Wallet',
      'Credit Debit Amount'
    ],
    Referrals: [
      'View Referrals',
      'Referral Details'
    ],
    Referrals_Draft: [
      'View Referral Drafts',
      'Referral Draft Details'
    ],
    Buyers: [
      'View Buyers',
      'Buyer Details'
    ],
    Sellers: [
      'View Sellers',
      'Seller Details'
    ],
    Refund_Request: [
      'View Refund Request',
      'Refund Request Details'
    ],
    Notification: [
      'View Notification',
      'Add Notification',
      'Edit Notification',
      'Delete Notification'
    ],
    Popups: [
      'View Popups',
      'Add Popup',
      'Edit Popup',
      'Delete Popup'
    ],
    Helps: [
      'View Helps',
      'Add Help',
      'Edit Help',
      'Delete Help'
    ],
    Withdraw_Request: [
      'View Withdraw Request',
      'Withdraw Request Details'
    ],
    How_it_works_video: [
      'Add / Update VIdeo'
    ],
    Activity_Log: [
      'View Activity Log',
      'Activity Log Details'
    ]
  };

  const [selectedPermissions, setSelectedPermissions] = useState({});

  useEffect(() => {
    setRoleFormData({
      ...roleFormData,
      permissions: selectedPermissions
    });
  }, [selectedPermissions]);

  const handleCheckboxChange = (mainKey, subKey) => {
    setSelectedPermissions(prevPermissions => {
      const updatedPermissions = { ...prevPermissions };
      if (!updatedPermissions[mainKey]) {
        updatedPermissions[mainKey] = [];
      }
      if (updatedPermissions[mainKey].includes(subKey)) {
        // Uncheck the checkbox
        updatedPermissions[mainKey] = updatedPermissions[mainKey].filter(key => key !== subKey);
      } else {
        // Check the checkbox
        updatedPermissions[mainKey].push(subKey);
      }
      return updatedPermissions;
    });
  };

  const handleCheckAllSubKeys = (mainKey) => {
    setSelectedPermissions(prevPermissions => {
      const updatedPermissions = { ...prevPermissions };
      updatedPermissions[mainKey] = adminPermission[mainKey];
      return updatedPermissions;
    });
  };

  const handleUncheckAllSubKeys = (mainKey) => {
    setSelectedPermissions(prevPermissions => {
      const updatedPermissions = { ...prevPermissions };
      updatedPermissions[mainKey] = [];
      return updatedPermissions;
    });
  };

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Roles
            </Typography>
            {adminPermissionList && (adminPermissionList.includes('Add Role') || adminPermissionList.includes('All')) &&
              <Tooltip title={'Add New Role'} placement="left">
                <Button color='default' variant='outlined' onClick={() => { resetForm(); setOpenModal(true); }}>+ Add New</Button>
              </Tooltip>
            }
          </Toolbar>
        </AppBar>
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Role Name</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Created At</TableCell>
                  <TableCell style={{ minWidth: '150px' }} align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roles && roles.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>{row?.role_name}</TableCell>
                      <TableCell>
                        {moment(row?.createdAt).format('YYYY-MM-DD hh:mmA')}
                      </TableCell>
                      <TableCell align='right'>
                        {adminPermissionList && (adminPermissionList.includes('Edit Role') || adminPermissionList.includes('All')) &&
                          <Tooltip title={'Edit Role'} placement="left">
                            <IconButton onClick={() => editRole(row)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {adminPermissionList && (adminPermissionList.includes('Delete Role') || adminPermissionList.includes('All')) &&
                          <Tooltip title={'Delete Role'} placement="left">
                            <IconButton onClick={() => { setRoleID(row?._id); setDeleteModal(true) }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* role form dialog */}
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setOpenModal(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              {roleFormData?._id ? 'Edit Role' : 'Create Role'}
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }} onClick={() => save()}>
              {roleFormData?._id ? 'Update' : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <Box style={{ width: '100%', maxWidth: '450px', paddingTop: '25px' }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='role_name'
                  label="Role Name"
                  variant="outlined"
                  size="small"
                  value={roleFormData?.role_name}
                  error={errors?.role_name}
                  helperText={errors?.role_name}
                  onChange={(e) => roleDataHandleChange(e)}
                />
              </Grid>
            </Grid>
            <Grid container>
              {Object.keys(adminPermission).map((mainKey) => (
                <Grid item xs={12} sm={12} md={6} xl={4} className='px-1' key={mainKey.replace(/\s/g, "")}>
                  <div className='mb-3'>
                    <div className="CustomCheckBox" style={{ fontSize: '1rem' }}>
                      <input
                        id={mainKey.replace(/\s/g, "")}
                        type="checkbox"
                        checked={selectedPermissions[mainKey] && selectedPermissions[mainKey].length === adminPermission[mainKey].length}
                        onChange={() => {
                          if (selectedPermissions[mainKey] && selectedPermissions[mainKey].length === adminPermission[mainKey].length) {
                            handleUncheckAllSubKeys(mainKey);
                          } else {
                            handleCheckAllSubKeys(mainKey);
                          }
                        }}
                      />
                      <label htmlFor={mainKey.replace(/\s/g, "")} style={{ fontWeight: 'bold' }}>{mainKey.replace('_', ' ')}</label>
                    </div>
                    {adminPermission[mainKey].map(subKey => (
                      <div className="CustomCheckBox" key={subKey.replace(/\s/g, "")} style={{ fontSize: '1rem' }}>
                        <input
                          id={subKey.replace(/\s/g, "")}
                          type="checkbox"
                          checked={selectedPermissions[mainKey] && selectedPermissions[mainKey].includes(subKey)}
                          onChange={() => handleCheckboxChange(mainKey, subKey)}
                        />
                        <label htmlFor={subKey.replace(/\s/g, "")}>{subKey}</label>
                      </div>
                    ))}
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      {/* role delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deleteRole(roleID)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default AdminRoles
