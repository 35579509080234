import { getConfigDataError, getConfigDataLoading, getConfigDataSuccess, setHowItWorkVideoError, setHowItWorkVideoLoading, setHowItWorkVideoSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getConfigDataAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getConfigDataLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('config-data/get', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getConfigDataSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getConfigDataError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const setHowItWorkVideoAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(setHowItWorkVideoLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('config-data/set/how_it_work_video', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(setHowItWorkVideoSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(setHowItWorkVideoError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getConfigDataAction,
  setHowItWorkVideoAction
};
