import { creditDebitAmountError, creditDebitAmountLoading, creditDebitAmountSuccess, getDashboardChartError, getDashboardChartLoading, getDashboardChartSuccess, getDashboardFirstError, getDashboardFirstLoading, getDashboardFirstSuccess, getDashboardSecondError, getDashboardSecondLoading, getDashboardSecondSuccess, getDashboardTopTenItemsError, getDashboardTopTenItemsLoading, getDashboardTopTenItemsSuccess, getUserWalletLedgerError, getUserWalletLedgerLoading, getUserWalletLedgerSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getDashboardFirstAction = () => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getDashboardFirstLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.get('dashboard/get/total/first-section');
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getDashboardFirstSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getDashboardFirstError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getDashboardSecondAction = (postData) => {
  return async (dispatch) => {
    try {
      if (!postData) {
        return
      }
      // Start the loading state
      dispatch(getDashboardSecondLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.get(`dashboard/get/total/second-section?from=${postData?.startDate}&to=${postData?.endDate}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getDashboardSecondSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getDashboardSecondError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getDashboardChartAction = (postData) => {
  return async (dispatch) => {
    try {
      if (!postData) {
        return
      }
      // Start the loading state
      dispatch(getDashboardChartLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.get(`dashboard/get/total/chart-section?chart=${postData?.chart}&from=${postData?.startDate}&to=${postData?.endDate}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getDashboardChartSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getDashboardChartError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getDashboardTopTenItemsAction = () => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getDashboardTopTenItemsLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.get(`referral/get/top-product-and-service`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getDashboardTopTenItemsSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getDashboardTopTenItemsError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getDashboardFirstAction,
  getDashboardSecondAction,
  getDashboardChartAction,
  getDashboardTopTenItemsAction
};
