import { Bar, Bubble } from "react-chartjs-2";
import {
  NewsCard,
  PostCard,
  StatCard,
  WeatherCard,
  Wrapper
} from "../../components";
import React, { useEffect, useState } from "react";
import { mockDashboard, mockFeed } from "../../utils/mock";

import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import EmailIcon from "@material-ui/icons/Email";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreIcon from "@material-ui/icons/More";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import Paper from "@material-ui/core/Paper";
import PhoneIcon from "@material-ui/icons/Phone";
import SettingsIcon from "@material-ui/icons/Settings";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import red from "@material-ui/core/colors/red";
import { useDispatch } from "react-redux";
import { getDashboardChartAction, getDashboardFirstAction, getDashboardSecondAction, getDashboardTopTenItemsAction } from "../../store/dashboard/asyncActions";
import { useSelector } from "react-redux";
import DateRangePicker from "../../components/DateRange/DateRangePicker";
import { Button, FormControl, InputLabel, Select } from "@material-ui/core";
import { format, parse } from "date-fns";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HistoryIcon from '@material-ui/icons/History';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import BlockIcon from '@material-ui/icons/Block';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

let id = 0;
function createData(name, date, progress) {
  id += 1;
  return { id, name, date, progress };
}

const data = [
  createData("UI prototyping", "January 23", 67),
  createData("Design", "February 2", 87),
  createData("Development", "March 30", 54),
  createData("Testing and delivery", "April 12", 34),
  createData("Ongoing maintanance", "May 28", 56),
  createData("Extensive review", "December 3", 56),
  createData("Extensive testing", "December 25", 56)
];

const Home = () => {
  const dispatch = useDispatch();
  const firstSection = useSelector((state) => state?.dashboard?.dashboardFirst);
  const secondSection = useSelector((state) => state?.dashboard?.dashboardSecond);
  const chartSection = useSelector((state) => state?.dashboard?.dashboardChart);
  const topTenSection = useSelector((state) => state?.dashboard?.topTenItems);

  const [firstSecData, setFirstSecData] = useState(null);
  const [secondSecData, setSecondSecData] = useState(null);
  const [topTenData, setTopTenData] = useState(null);
  const [dateOptions, setDateOptions] = useState({
    selectedOption: 'Today',
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  });

  const [chartOPT, setChartOPT] = useState({
    chart: 'New User',
    month: format(new Date(new Date().getFullYear(), new Date().getMonth()), 'MMMM-yyyy')
  });

  const [chartData, setChartData] = useState({
    labels: null,
    datasets: [{
      label: chartOPT?.chart,
      backgroundColor: '#7986cb',
      borderColor: '#3f51b5',
      pointBackgroundColor: '#3f51b5',
      pointBorderColor: '#fff',
      borderWidth: 0,
      data: null
    }]
  });

  useEffect(() => {
    dispatch(getDashboardFirstAction());
    dispatch(getDashboardSecondAction());
    dispatch(getDashboardTopTenItemsAction());
  }, []);

  useEffect(() => {
    if (dateOptions && dateOptions?.selectedOption && dateOptions?.startDate && dateOptions?.endDate) {
      dispatch(getDashboardSecondAction(dateOptions));
    }
  }, [dateOptions]);

  useEffect(() => {
    if (firstSection?.data?.status) {
      setFirstSecData(firstSection?.data?.data);
    }
  }, [firstSection]);

  useEffect(() => {
    if (secondSection?.data?.status) {
      setSecondSecData(secondSection?.data?.data);
    }
  }, [secondSection]);

  useEffect(() => {
    if (chartSection?.data?.status) {
      setChartData((prevState) => {
        const updatedDatasets = [...prevState.datasets];
        updatedDatasets[0].data = chartSection?.data?.data?.chartData;
        updatedDatasets[0].label = chartOPT?.chart;
        return {
          ...prevState,
          labels: chartSection?.data?.data?.labels,
          datasets: updatedDatasets
        };
      });
    }
  }, [chartSection]);

  useEffect(() => {
    if (topTenSection?.data?.status) {
      setTopTenData(topTenSection?.data?.data);
    }
  }, [topTenSection]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const chartMenu = (
    <Menu
      id="chart-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <MoreIcon />
        </ListItemIcon>
        <ListItemText primary="View more" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <NotificationsOffIcon />
        </ListItemIcon>
        <ListItemText primary="Disable notifications" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Remove panel" />
      </MenuItem>
    </Menu>
  );

  const [dateRangeModalOpen, setDateRangeModalOpen] = useState(false);

  const handleApplyDateRange = (selectedOption, startDate, endDate) => {
    setDateOptions({ selectedOption, startDate, endDate });
  };

  useEffect(() => {
    if (chartOPT?.chart && chartOPT?.month) {
      const parsedDate = parse(chartOPT?.month, 'MMMM-yyyy', new Date());
      const firstDate = format(new Date(parsedDate.getFullYear(), parsedDate.getMonth(), 1), 'yyyy-MM-dd');
      const lastDate = format(new Date(parsedDate.getFullYear(), parsedDate.getMonth() + 1, 0), 'yyyy-MM-dd');
      dispatch(getDashboardChartAction({
        chart: chartOPT?.chart,
        startDate: firstDate,
        endDate: lastDate
      }));
    }
  }, [chartOPT]);

  const today = new Date();
  const months = [];

  for (let i = 0; i < 13; i++) {
    const currentMonth = new Date(today.getFullYear(), today.getMonth() - i);
    const formattedMonth = format(currentMonth, 'MMMM-yyyy');
    months.push(formattedMonth);
  }

  return (
    <Wrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            topTitle="Total Users"
            title=" "
            value={firstSecData?.totalUserCount}
            icon={<PeopleAltIcon />}
            color="#3f51b5"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            topTitle="Total Created Referral"
            title={'Rs.' + firstSecData?.Referral?.totalPrice}
            value={firstSecData?.Referral?.totalCounts}
            icon={<SupervisedUserCircleIcon />}
            color="#9c27b0"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            topTitle="Total Purchased Referral"
            title={'Rs.' + firstSecData?.Purchased?.totalPrice}
            value={firstSecData?.Purchased?.totalCounts}
            icon={<ShoppingCartIcon />}
            color="#f44336"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            topTitle="Pending for Purchase"
            title={'Rs.' + firstSecData?.Pending?.totalPrice}
            value={firstSecData?.Pending?.totalCounts}
            icon={<HistoryIcon />}
            color="#ffae00"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            topTitle="Total Refund Requested"
            title={'Rs.' + firstSecData?.RefundReq?.totalPrice}
            value={firstSecData?.RefundReq?.totalCounts}
            icon={<AttachMoneyIcon />}
            color="#3f9db5"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            topTitle="Total Refund Approved"
            title={'Rs.' + firstSecData?.RefundAproved?.totalPrice}
            value={firstSecData?.RefundAproved?.totalCounts}
            icon={<DoneAllIcon />}
            color="#27b064"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            topTitle="Total Refund Rjected"
            title={'Rs.' + firstSecData?.RefundRejected?.totalPrice}
            value={firstSecData?.RefundRejected?.totalCounts}
            icon={<BlockIcon />}
            color="#b02727"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            topTitle="Total Deal Done"
            title={'Rs.' + firstSecData?.DealDone?.totalPrice}
            value={firstSecData?.DealDone?.totalCounts}
            icon={<ThumbUpIcon />}
            color="#3f51b5"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <StatCard
            type="fill"
            topTitle="Total Buyer"
            value={firstSecData?.Buyers?.totalCounts}
            icon={<PeopleAltIcon />}
            color="#3f51b5"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <StatCard
            type="fill"
            topTitle="Total Seller"
            value={firstSecData?.Sellers?.totalCounts}
            icon={<PeopleAltIcon />}
            color="#9c27b0"
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} className="mt-2">
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button variant="outlined" color="default" onClick={() => setDateRangeModalOpen(true)}>
              {dateOptions?.selectedOption} <ExpandMoreIcon style={{ marginLeft: '10px' }} />
            </Button>
            <DateRangePicker
              open={dateRangeModalOpen}
              onClose={() => setDateRangeModalOpen(false)}
              onApply={handleApplyDateRange}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            topTitle="New User"
            title=" "
            value={secondSecData?.totalUserCount}
            icon={<PeopleAltIcon />}
            color="#3f51b5"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            topTitle="New Referral"
            title={'Rs.' + secondSecData?.Referral?.totalPrice}
            value={secondSecData?.Referral?.totalCounts}
            icon={<SupervisedUserCircleIcon />}
            color="#9c27b0"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            topTitle="Referral Purchased"
            title={'Rs.' + secondSecData?.Purchased?.totalPrice}
            value={secondSecData?.Purchased?.totalCounts}
            icon={<ShoppingCartIcon />}
            color="#f44336"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            type="fill"
            topTitle="Refund Requested"
            title={'Rs.' + secondSecData?.RefundReq?.totalPrice}
            value={secondSecData?.RefundReq?.totalCounts}
            icon={<AttachMoneyIcon />}
            color="#ffae00"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            type="fill"
            topTitle="Refund Approved"
            title={'Rs.' + secondSecData?.RefundAproved?.totalPrice}
            value={secondSecData?.RefundAproved?.totalCounts}
            icon={<DoneAllIcon />}
            color="#3f9db5"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            type="fill"
            topTitle="Refund Rejected"
            title={'Rs.' + secondSecData?.RefundRejected?.totalPrice}
            value={secondSecData?.RefundRejected?.totalCounts}
            icon={<BlockIcon />}
            color="#b02727"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            type="fill"
            topTitle="Deal Done"
            title={'Rs.' + secondSecData?.DealDone?.totalPrice}
            value={secondSecData?.DealDone?.totalCounts}
            icon={<ThumbUpIcon />}
            color="#27b064"
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} className="mt-3" style={{ display: 'flex', justifyContent: 'right', marginBottom: '5px' }}>
        <Grid item xs={6} sm={6} md={2} xl={2}>
          <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-outlined-label">Chart of</InputLabel>
            <Select
              name='chart'
              label="Chart of"
              value={chartOPT?.chart}
              onChange={(e) => setChartOPT({
                ...chartOPT,
                chart: e?.target?.value
              })}
            >
              <MenuItem value="New User">New User</MenuItem>
              <MenuItem value="New Referral">New Referral</MenuItem>
              <MenuItem value="Referral Purchased">Referral Purchased</MenuItem>
              <MenuItem value="Refund Requested">Refund Requested</MenuItem>
              <MenuItem value="Refund Approved">Refund Approved</MenuItem>
              <MenuItem value="Refund Rejected">Refund Rejected</MenuItem>
              <MenuItem value="Deal Done">Deal Done</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6} md={2} xl={2}>
          <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-outlined-label">Calendar - Month</InputLabel>
            <Select
              name='month'
              label="Calendar - Month"
              value={chartOPT?.month}
              onChange={(e) => setChartOPT({
                ...chartOPT,
                month: e?.target?.value
              })}
            >
              {months.map((month, index) => (
                <MenuItem value={month} key={index}>{month}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <CardHeader
              subheader={chartOPT?.chart}
              action={
                <IconButton id={`menu-button-1`} onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              }
            />
            <CardContent>
              <Bar
                data={chartData}
                height={100}
                options={mockDashboard?.[0].options}
              />
            </CardContent>
          </Card>
        </Grid>

        {topTenData && topTenData.map((list, listIndex) => {
          return (
            <Grid item xs={12} sm={12} md={6} key={listIndex}>
              <Paper className="table-responsive">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Top 10 <span style={{ textTransform: 'capitalize' }}>{list?.type}s</span>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list?.topItems && list?.topItems.map((item, itemIndex) => {
                      return (
                        <TableRow key={itemIndex}>
                          <TableCell component="th" scope="row">
                            {item?.item?.item_name}
                          </TableCell>
                          <TableCell>{item?.count}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          )
        })}

      </Grid>


      {/* <Grid container spacing={1}>
        {chartMenu}
        {mockDashboard.map((chart, index) => (
          <Grid item xs={12} sm={12} md={4} key={index}>
            <Card>
              <CardHeader
                subheader={chart.title}
                action={
                  <IconButton id={`${index}-menu-button`} onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                }
              />
              <CardContent>
                {chart.type === "bar" && (
                  <Bar
                    data={chart.data}
                    height={chart.height}
                    options={chart.options}
                  />
                )}
                {chart.type === "bubble" && (
                  <Bubble
                    data={chart.data}
                    height={chart.height}
                    options={chart.options}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} sm={12} md={12}>
          <Paper className="table-responsive">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Project</TableCell>
                  <TableCell>Due Date</TableCell>
                  <TableCell>Current Progress</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(n => (
                  <TableRow key={n.id}>
                    <TableCell component="th" scope="row">
                      {n.name}
                    </TableCell>
                    <TableCell>{n.date}</TableCell>
                    <TableCell>
                      {
                        <LinearProgress
                          variant="determinate"
                          value={n.progress}
                        />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <NewsCard subtitle="Last updated 24 mins ago" feed={mockFeed} />
        </Grid>
        <Grid item sm={12} md={6}>
          <PostCard
            title="Shrimp and Chorizo Paella"
            subtitle="Yesterday"
            image={`${process.env.PUBLIC_URL}/static/images/unsplash/2.jpg`}
            imageHeight={200}
            text="Phileas Fogg and Aouda went on board, where they found Fix already installed. Below deck was a square cabin, of which the walls bulged out in the form of cots, above a circular divan; in the centre was a table provided with a swinging lamp."
            avatar={
              <Avatar aria-label="Post" style={{ backgroundColor: red[500] }}>
                R
              </Avatar>
            }
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <WeatherCard city="london" country="uk" days={7} />
        </Grid>

      </Grid> */}

    </Wrapper>
  );
};

export default Home;
