import { adminLoginLoading, adminLoginSuccess, adminLoginError, adminGetAllUsersLoading, adminGetAllUsersSuccess, adminGetAllUsersError, adminCreateUserLoading, adminCreateUserSuccess, adminCreateUserError, getAdminByTokenLoading, getAdminByTokenSuccess, getAdminByTokenError, adminDeleteUserLoading, adminDeleteUserSuccess, adminDeleteUserError, adminUpdateUserLoading, adminUpdateUserSuccess, adminUpdateUserError, adminUpdateUserStatusLoading, adminUpdateUserStatusSuccess, adminUpdateUserStatusError, adminUserProfileUpdateLoading, adminUserProfileUpdateSuccess, adminUserProfileUpdateError } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const adminLoginAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(adminLoginLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('admin/user/login', postData, { includeAuthorization: false });
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(adminLoginSuccess(data));
      if (data?.success) {
        toast.success(data?.message);
        // localStorage.setItem('id', data?.success?._id)
        // localStorage.setItem('AccessToken', data?.success?.token)
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(adminLoginError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const adminLogoutAction = () => {
  try {
    localStorage.removeItem('id');
    localStorage.removeItem('AccessToken');
    // window.location.href = '/signin';
    return true;
  } catch (error) {
    // toast.error(error);
    return false;
  }
};

const getAdminUserAction = () => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAdminByTokenLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.get('admin/user/get');
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAdminByTokenSuccess(data));
      if (data?.success) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAdminByTokenError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const adminGetAllUserAction = (page, size) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(adminGetAllUsersLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.get(`admin/user/get/all?page=${page}&size=${size}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(adminGetAllUsersSuccess(data));
      if (data?.success) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(adminGetAllUsersError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const adminCreateUserAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(adminCreateUserLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('admin/user/create', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(adminCreateUserSuccess(data));
      if (data?.success) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(adminCreateUserError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const adminUpdateUserAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(adminUpdateUserLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('admin/user/update', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(adminUpdateUserSuccess(data));
      if (data?.success) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(adminUpdateUserError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const adminUpdateUserProfileAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(adminUserProfileUpdateLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('admin/user/profile/update', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(adminUserProfileUpdateSuccess(data));
      if (data?.success) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(adminUserProfileUpdateError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const adminUpdateUserStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(adminUpdateUserStatusLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('admin/user/update/status', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(adminUpdateUserStatusSuccess(data));
      if (data?.success) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(adminUpdateUserStatusError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

const adminDeleteUserAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(adminDeleteUserLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('admin/user/delete', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(adminDeleteUserSuccess(data));
      if (data?.success) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(adminDeleteUserError(error.message));
      toast.error(error?.response?.data?.error);
    }
  };
};

export {
  adminLoginAction,
  adminLogoutAction,
  getAdminUserAction,
  adminGetAllUserAction,
  adminCreateUserAction,
  adminUpdateUserAction,
  adminUpdateUserProfileAction,
  adminUpdateUserStatusAction,
  adminDeleteUserAction
};
