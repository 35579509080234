import { GET_ALL_ACTIVITY_LOG_ERROR, GET_ALL_ACTIVITY_LOG_LOADING, GET_ALL_ACTIVITY_LOG_SUCCESS } from './actionTypes';

// user & referral count
const getAllActivityLogLoading = () => {
  return {
    type: GET_ALL_ACTIVITY_LOG_LOADING,
  };
};

const getAllActivityLogSuccess = (data) => {
  return {
    type: GET_ALL_ACTIVITY_LOG_SUCCESS,
    payload: data,
  };
};

const getAllActivityLogError = (error) => {
  return {
    type: GET_ALL_ACTIVITY_LOG_ERROR,
    payload: error,
  };
};

export { 
    getAllActivityLogLoading, 
    getAllActivityLogSuccess, 
    getAllActivityLogError
};
