// Pages
import {
  AppBar,
  Autocomplete,
  Avatars,
  BackendError,
  Badges,
  Blank,
  ButtonNavigation,
  Buttons,
  Calendar,
  Cards,
  Charts,
  Chat,
  Chips,
  Detail,
  Dialogs,
  Dividers,
  Drawers,
  Editor,
  ExpansionPanels,
  Google,
  GridList,
  Home,
  Invoice,
  Leaflet,
  Lists,
  Lockscreen,
  Media,
  Menus,
  Messages,
  NotFound,
  Paper,
  PasswordReset,
  Pickers,
  PricingPage,
  Products,
  Progress,
  SelectionControls,
  Selects,
  Signin,
  Signup,
  Snackbars,
  Social,
  Steppers,
  Tables,
  Tabs,
  Taskboard,
  TextFields,
  TimelinePage,
  Tooltips,
  Widgets
} from './pages';

import AppsIcon from '@material-ui/icons/Apps';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import EqualizerIcon from '@material-ui/icons/Equalizer';
// Icons
import ExploreIcon from '@material-ui/icons/Explore';
import FaceIcon from '@material-ui/icons/Face';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import Looks3Icon from '@material-ui/icons/Looks3';
import Looks4Icon from '@material-ui/icons/Looks4';
import NavigationIcon from '@material-ui/icons/Navigation';
import PagesIcon from '@material-ui/icons/Pages';
import PersonIcon from '@material-ui/icons/Person';
import PhotoIcon from '@material-ui/icons/Photo';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import PeopleIcon from '@material-ui/icons/People';
import AdminUsers from './pages/Admin-users/AdminUsers';
import Profile from './pages/Apps/Profile';
import ViewQuiltRoundedIcon from '@material-ui/icons/ViewQuiltRounded';
import Categories from './pages/Categories/Categories';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import Users from './pages/Users/Users';
import Referral_list from './pages/Referral/Referral_list';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Buyer_list from './pages/Buyer/Buyer_list';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Seller_list from './pages/Seller/Seller_list';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import Notification_list from './pages/Notification/Notification_list';
import Popup_list from './pages/Popup/Popup_list';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Help_list from './pages/Help/Help_list';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Withdraw_request from './pages/Withdraw-request/Withdraw_request';
import HistoryIcon from '@material-ui/icons/History';
import Activity_log from './pages/Activity-log/Activity_log';
import ItemsList from './pages/Items/ItemsList';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import HowItWorkVideo from './pages/HowItWorkVideo/HowItWorkVideo';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Refund_request from './pages/Refund-request/Refund_request';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import AdminRoles from './pages/Admin-roles/AdminRoles';
import HomeIcon from '@material-ui/icons/Home';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import Referral_draft from './pages/Referral-draft/Referral_draft';

export default {
  items: [
    {
      path: '/',
      name: 'Home',
      type: 'link',
      access: null,
      icon: HomeIcon,
      component: Home
    },
    {
      path: '/admin-users',
      name: 'Admin Users',
      type: 'link',
      access: 'View Admin',
      icon: SupervisorAccountIcon,
      component: AdminUsers
    },
    {
      path: '/roles',
      name: 'Roles',
      type: 'link',
      access: 'View Role',
      icon: EmojiPeopleIcon,
      component: AdminRoles
    },
    {
      path: '/categories',
      name: 'Categories',
      type: 'link',
      access: 'View Category',
      icon: ViewQuiltRoundedIcon,
      component: Categories
    },
    {
      path: '/items',
      name: 'Items',
      type: 'link',
      access: 'View Item',
      icon: AppsIcon,
      component: ItemsList
    },
    {
      path: '/users',
      name: 'Users',
      type: 'link',
      access: 'View User',
      icon: PeopleAltIcon,
      component: Users
    },
    {
      path: '/referral-list',
      name: 'Referrals',
      type: 'link',
      access: 'View Referrals',
      icon: SupervisedUserCircleIcon,
      component: Referral_list
    },
    {
      path: '/referral-draft-list',
      name: 'Referral in Draft',
      type: 'link',
      access: 'View Referral Drafts',
      icon: RecentActorsIcon,
      component: Referral_draft
    },
    {
      path: '/buyer-list',
      name: 'Buyers',
      type: 'link',
      access: 'View Buyers',
      icon: AssignmentIndIcon,
      component: Buyer_list
    },
    {
      path: '/seller-list',
      name: 'Sellers',
      type: 'link',
      access: 'View Sellers',
      icon: AccountBoxIcon,
      component: Seller_list
    },
    {
      path: '/refund-request',
      name: 'Refund Requests',
      type: 'link',
      access: 'View Refund Request',
      icon: LocalAtmIcon,
      component: Refund_request
    },
    {
      path: '/notification',
      name: 'Notifications',
      type: 'link',
      access: 'View Notification',
      icon: NotificationsActiveIcon,
      component: Notification_list
    },
    {
      path: '/popup',
      name: 'Popups',
      type: 'link',
      access: 'View Popups',
      icon: AnnouncementIcon,
      component: Popup_list
    },
    {
      path: '/help',
      name: 'Helps',
      type: 'link',
      access: 'View Helps',
      icon: LiveHelpIcon,
      component: Help_list
    },
    {
      path: '/withdraw-request',
      name: 'Withdraw Requests',
      type: 'link',
      access: 'View Withdraw Request',
      icon: AccountBalanceIcon,
      component: Withdraw_request
    },
    {
      path: '/how-it-work-video',
      name: 'How it work video',
      type: 'link',
      access: 'Add / Update VIdeo',
      icon: PlayCircleFilledIcon,
      component: HowItWorkVideo
    },
    {
      path: '/activity-log',
      name: 'Activity Log',
      type: 'link',
      access: 'View Activity Log',
      icon: HistoryIcon,
      component: Activity_log
    },
    {
      path: '/profile',
      name: 'Profile',
      type: 'external',
      access: null,
      icon: PeopleIcon,
      component: Profile
    },
    // {
    //   path: '/apps',
    //   name: 'Apps',
    //   type: 'submenu',
    //   icon: AppsIcon,
    //   badge: {
    //     type: 'primary',
    //     value: '5'
    //   },
    //   children: [
    //     {
    //       path: '/calendar',
    //       name: 'Calendar',
    //       component: Calendar
    //     },
    //     {
    //       path: '/media',
    //       name: 'Media',
    //       component: Media
    //     },
    //     {
    //       path: '/messages',
    //       name: 'Messages',
    //       component: Messages
    //     },
    //     {
    //       path: '/social',
    //       name: 'Social',
    //       component: Social
    //     },
    //     {
    //       path: '/chat',
    //       name: 'Chat',
    //       component: Chat
    //     }
    //   ]
    // },
    // {
    //   path: '/widgets',
    //   name: 'Widgets',
    //   type: 'link',
    //   icon: PhotoIcon,
    //   component: Widgets
    // },



    // {
    //   path: '/material',
    //   name: 'Material',
    //   type: 'submenu',
    //   access: null,
    //   icon: EqualizerIcon,
    //   badge: {
    //     type: 'error',
    //     value: '8'
    //   },
    //   children: [
    //     {
    //       path: '/appbar',
    //       name: 'App Bar',
    //       component: AppBar
    //     },
    //     {
    //       path: '/autocomplete',
    //       name: 'Auto Complete',
    //       component: Autocomplete
    //     },
    //     {
    //       path: '/avatars',
    //       name: 'Avatars',
    //       component: Avatars
    //     },
    //     {
    //       path: '/badges',
    //       name: 'Badges',
    //       component: Badges
    //     },
    //     {
    //       path: '/button-navigation',
    //       name: 'Button Navigation',
    //       component: ButtonNavigation
    //     },
    //     {
    //       path: '/buttons',
    //       name: 'Buttons',
    //       component: Buttons
    //     },
    //     {
    //       path: '/cards',
    //       name: 'Cards',
    //       component: Cards
    //     },
    //     {
    //       path: '/chips',
    //       name: 'Chips',
    //       component: Chips
    //     },
    //     {
    //       path: '/dialogs',
    //       name: 'Dialogs',
    //       component: Dialogs
    //     },
    //     {
    //       path: '/dividers',
    //       name: 'Dividers',
    //       component: Dividers
    //     },
    //     {
    //       path: '/drawers',
    //       name: 'Drawers',
    //       component: Drawers
    //     },
    //     {
    //       path: '/expansion-panels',
    //       name: 'Expansion Panels',
    //       component: ExpansionPanels
    //     },
    //     {
    //       path: '/grid-list',
    //       name: 'Grid List',
    //       component: GridList
    //     },
    //     {
    //       path: '/lists',
    //       name: 'Lists',
    //       component: Lists
    //     },
    //     {
    //       path: '/menus',
    //       name: 'Menus',
    //       component: Menus
    //     },
    //     {
    //       path: '/paper',
    //       name: 'Paper',
    //       component: Paper
    //     },
    //     {
    //       path: '/pickers',
    //       name: 'Pickers',
    //       component: Pickers
    //     },
    //     {
    //       path: '/progress',
    //       name: 'Progress',
    //       component: Progress
    //     },
    //     {
    //       path: '/selection-controls',
    //       name: 'Selection Controls',
    //       component: SelectionControls
    //     },
    //     {
    //       path: '/selects',
    //       name: 'Selects',
    //       component: Selects
    //     },
    //     {
    //       path: '/snackbars',
    //       name: 'Snackbars',
    //       component: Snackbars
    //     },
    //     {
    //       path: '/steppers',
    //       name: 'Steppers',
    //       component: Steppers
    //     },
    //     {
    //       path: '/tables',
    //       name: 'Tables',
    //       component: Tables
    //     },
    //     {
    //       path: '/tabs',
    //       name: 'Tabs',
    //       component: Tabs
    //     },
    //     {
    //       path: '/text-fields',
    //       name: 'Text Fields',
    //       component: TextFields
    //     },
    //     {
    //       path: '/tooltips',
    //       name: 'Tooltips',
    //       component: Tooltips
    //     }
    //   ]
    // },



    // {
    //   path: '/editor',
    //   name: 'Editor',
    //   type: 'link',
    //   icon: Looks3Icon,
    //   component: Editor
    // },
    // {
    //   path: '/ecommerce',
    //   name: 'Ecommerce',
    //   type: 'submenu',
    //   icon: Looks4Icon,
    //   badge: {
    //     type: 'secondary',
    //     value: 'N'
    //   },
    //   children: [
    //     {
    //       path: '/products',
    //       name: 'Products',
    //       component: Products
    //     },
    //     {
    //       path: '/detail',
    //       name: 'Detail',
    //       component: Detail
    //     }
    //   ]
    // },
    // {
    //   path: '/taskboard',
    //   name: 'Taskboard',
    //   type: 'link',
    //   icon: ViewColumnIcon,
    //   component: Taskboard
    // },
    // {
    //   path: '/charts',
    //   name: 'Charts',
    //   type: 'link',
    //   icon: ShowChartIcon,
    //   component: Charts
    // },
    // {
    //   path: '/maps',
    //   name: 'Maps',
    //   type: 'submenu',
    //   icon: NavigationIcon,
    //   children: [
    //     {
    //       path: '/google',
    //       name: 'Google',
    //       component: Google
    //     },
    //     {
    //       path: '/leaflet',
    //       name: 'Leaflet',
    //       component: Leaflet
    //     }
    //   ]
    // },
    // {
    //   path: '/pages',
    //   name: 'Pages',
    //   type: 'submenu',
    //   icon: PagesIcon,
    //   children: [
    //     {
    //       path: '/invoice',
    //       name: 'Invoice',
    //       component: Invoice
    //     },
    //     {
    //       path: '/timeline',
    //       name: 'Timeline',
    //       component: TimelinePage
    //     },
    //     {
    //       path: '/blank',
    //       name: 'Blank',
    //       component: Blank
    //     },
    //     {
    //       path: '/pricing',
    //       name: 'Pricing',
    //       component: PricingPage
    //     }
    //   ]
    // },
    // {
    //   name: 'Authentication',
    //   type: 'submenu',
    //   icon: PersonIcon,
    //   children: [
    //     {
    //       path: '/signin',
    //       name: 'Signin',
    //       component: Signin
    //     },
    //     {
    //       path: '/signup',
    //       name: 'Signup',
    //       component: Signup
    //     },
    //     {
    //       path: '/forgot',
    //       name: 'Forgot',
    //       component: PasswordReset
    //     },
    //     {
    //       path: '/lockscreen',
    //       name: 'Lockscreen',
    //       component: Lockscreen
    //     }
    //   ]
    // },
    // {
    //   name: 'Error',
    //   type: 'submenu',
    //   icon: FaceIcon,
    //   children: [
    //     {
    //       path: '/404',
    //       name: '404',
    //       component: NotFound
    //     },
    //     {
    //       path: '/500',
    //       name: 'Error',
    //       component: BackendError
    //     }
    //   ]
    // },
    // {
    //   path: 'https://iamnyasha.github.io/react-primer-docs/',
    //   name: 'Documentation',
    //   type: 'external',
    //   icon: LocalLibraryIcon
    // },
    // {
    //   path: 'https://primer.fusepx.com',
    //   name: 'Get Angular Version',
    //   type: 'external',
    //   icon: BookmarkIcon
    // }
  ]
};
