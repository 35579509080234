export const GET_ALL_HELP_LOADING = 'GET_ALL_HELP_LOADING';
export const GET_ALL_HELP_SUCCESS = 'GET_ALL_HELP_SUCCESS';
export const GET_ALL_HELP_ERROR = 'GET_ALL_HELP_ERROR';

export const CREATE_HELP_LOADING = 'CREATE_HELP_LOADING';
export const CREATE_HELP_SUCCESS = 'CREATE_HELP_SUCCESS';
export const CREATE_HELP_ERROR = 'CREATE_HELP_ERROR';

export const UPDATE_HELP_LOADING = 'UPDATE_HELP_LOADING';
export const UPDATE_HELP_SUCCESS = 'UPDATE_HELP_SUCCESS';
export const UPDATE_HELP_ERROR = 'UPDATE_HELP_ERROR';

export const DELETE_HELP_LOADING = 'DELETE_HELP_LOADING';
export const DELETE_HELP_SUCCESS = 'DELETE_HELP_SUCCESS';
export const DELETE_HELP_ERROR = 'DELETE_HELP_ERROR';
