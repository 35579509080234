export const GET_ALL_REFERRAL_LOADING = 'GET_ALL_REFERRAL_LOADING';
export const GET_ALL_REFERRAL_SUCCESS = 'GET_ALL_REFERRAL_SUCCESS';
export const GET_ALL_REFERRAL_ERROR = 'GET_ALL_REFERRAL_ERROR';

export const GET_ALL_REFERRAL_DRAFT_LOADING = 'GET_ALL_REFERRAL_DRAFT_LOADING';
export const GET_ALL_REFERRAL_DRAFT_SUCCESS = 'GET_ALL_REFERRAL_DRAFT_SUCCESS';
export const GET_ALL_REFERRAL_DRAFT_ERROR = 'GET_ALL_REFERRAL_DRAFT_ERROR';

export const GET_ALL_BUYERS_LOADING = 'GET_ALL_BUYERS_LOADING';
export const GET_ALL_BUYERS_SUCCESS = 'GET_ALL_BUYERS_SUCCESS';
export const GET_ALL_BUYERS_ERROR = 'GET_ALL_BUYERS_ERROR';

export const GET_ALL_SELLER_LOADING = 'GET_ALL_SELLER_LOADING';
export const GET_ALL_SELLER_SUCCESS = 'GET_ALL_SELLER_SUCCESS';
export const GET_ALL_SELLER_ERROR = 'GET_ALL_SELLER_ERROR';

export const GET_GENERATED_REF_BY_USER_LOADING = 'GET_GENERATED_REF_BY_USER_LOADING';
export const GET_GENERATED_REF_BY_USER_SUCCESS = 'GET_GENERATED_REF_BY_USER_SUCCESS';
export const GET_GENERATED_REF_BY_USER_ERROR = 'GET_GENERATED_REF_BY_USER_ERROR';

export const GET_RECEIVED_REF_BY_USER_LOADING = 'GET_RECEIVED_REF_BY_USER_LOADING';
export const GET_RECEIVED_REF_BY_USER_SUCCESS = 'GET_RECEIVED_REF_BY_USER_SUCCESS';
export const GET_RECEIVED_REF_BY_USER_ERROR = 'GET_RECEIVED_REF_BY_USER_ERROR';