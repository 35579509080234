import { CREATE_CATEGORY_ERROR, CREATE_CATEGORY_LOADING, CREATE_CATEGORY_SUCCESS, DELETE_CATEGORY_ERROR, DELETE_CATEGORY_LOADING, DELETE_CATEGORY_SUCCESS, EXPORT_ALL_CSV_CATEGORY_ERROR, EXPORT_ALL_CSV_CATEGORY_LOADING, EXPORT_ALL_CSV_CATEGORY_SUCCESS, GET_ALL_CATEGORIES_ERROR, GET_ALL_CATEGORIES_LOADING, GET_ALL_CATEGORIES_SUCCESS, IMPORT_ALL_CSV_CATEGORY_ERROR, IMPORT_ALL_CSV_CATEGORY_LOADING, IMPORT_ALL_CSV_CATEGORY_SUCCESS, SEARCH_CATEGORY_ERROR, SEARCH_CATEGORY_LOADING, SEARCH_CATEGORY_SUCCESS, UPDATE_CATEGORY_ERROR, UPDATE_CATEGORY_LOADING, UPDATE_CATEGORY_STATUS_ERROR, UPDATE_CATEGORY_STATUS_LOADING, UPDATE_CATEGORY_STATUS_SUCCESS, UPDATE_CATEGORY_SUCCESS } from "./actionTypes";

const initialState = {
    getAllCategories: {
        loading: false,
        data: null,
        error: null
    },
    createCategory: {
        loading: false,
        data: null,
        error: null
    },
    updateCategory: {
        loading: false,
        data: null,
        error: null
    },
    updateCategoryStatus: {
        loading: false,
        data: null,
        error: null
    },
    deleteCategory: {
        loading: false,
        data: null,
        error: null
    },
    searchCategory: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvCategory: {
        loading: false,
        data: null,
        error: null
    },
    getAllCsvImportCategory: {
        loading: false,
        data: null,
        error: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CATEGORIES_LOADING:
            return { ...state, getAllCategories: { ...state.getAllCategories, loading: true, data: null, error: null } };
        case GET_ALL_CATEGORIES_SUCCESS:
            return { ...state, getAllCategories: { ...state.getAllCategories, loading: false, data: action.payload, error: null } };
        case GET_ALL_CATEGORIES_ERROR:
            return { ...state, getAllCategories: { ...state.getAllCategories, loading: false, data: null, error: action.payload } };

        case CREATE_CATEGORY_LOADING:
            return { ...state, createCategory: { ...state.createCategory, loading: true, data: null, error: null } };
        case CREATE_CATEGORY_SUCCESS:
            return { ...state, createCategory: { ...state.createCategory, loading: false, data: action.payload, error: null } };
        case CREATE_CATEGORY_ERROR:
            return { ...state, createCategory: { ...state.createCategory, loading: false, data: null, error: action.payload } };

        case UPDATE_CATEGORY_LOADING:
            return { ...state, updateCategory: { ...state.updateCategory, loading: true, data: null, error: null } };
        case UPDATE_CATEGORY_SUCCESS:
            return { ...state, updateCategory: { ...state.updateCategory, loading: false, data: action.payload, error: null } };
        case UPDATE_CATEGORY_ERROR:
            return { ...state, updateCategory: { ...state.updateCategory, loading: false, data: null, error: action.payload } };

        case UPDATE_CATEGORY_STATUS_LOADING:
            return { ...state, updateCategoryStatus: { ...state.updateCategoryStatus, loading: true, data: null, error: null } };
        case UPDATE_CATEGORY_STATUS_SUCCESS:
            return { ...state, updateCategoryStatus: { ...state.updateCategoryStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_CATEGORY_STATUS_ERROR:
            return { ...state, updateCategoryStatus: { ...state.updateCategoryStatus, loading: false, data: null, error: action.payload } };

        case DELETE_CATEGORY_LOADING:
            return { ...state, deleteCategory: { ...state.deleteCategory, loading: true, data: null, error: null } };
        case DELETE_CATEGORY_SUCCESS:
            return { ...state, deleteCategory: { ...state.deleteCategory, loading: false, data: action.payload, error: null } };
        case DELETE_CATEGORY_ERROR:
            return { ...state, deleteCategory: { ...state.deleteCategory, loading: false, data: null, error: action.payload } };

        case SEARCH_CATEGORY_LOADING:
            return { ...state, searchCategory: { ...state.searchCategory, loading: true, data: null, error: null } };
        case SEARCH_CATEGORY_SUCCESS:
            return { ...state, searchCategory: { ...state.searchCategory, loading: false, data: action.payload, error: null } };
        case SEARCH_CATEGORY_ERROR:
            return { ...state, searchCategory: { ...state.searchCategory, loading: false, data: null, error: action.payload } };

        case EXPORT_ALL_CSV_CATEGORY_LOADING:
            return { ...state, getAllCsvCategory: { ...state.getAllCsvCategory, loading: true, data: null, error: null } };
        case EXPORT_ALL_CSV_CATEGORY_SUCCESS:
            return { ...state, getAllCsvCategory: { ...state.getAllCsvCategory, loading: false, data: action.payload, error: null } };
        case EXPORT_ALL_CSV_CATEGORY_ERROR:
            return { ...state, getAllCsvCategory: { ...state.getAllCsvCategory, loading: false, data: null, error: action.payload } };

        case IMPORT_ALL_CSV_CATEGORY_LOADING:
            return { ...state, getAllCsvImportCategory: { ...state.getAllCsvImportCategory, loading: true, data: null, error: null } };
        case IMPORT_ALL_CSV_CATEGORY_SUCCESS:
            return { ...state, getAllCsvImportCategory: { ...state.getAllCsvImportCategory, loading: false, data: action.payload, error: null } };
        case IMPORT_ALL_CSV_CATEGORY_ERROR:
            return { ...state, getAllCsvImportCategory: { ...state.getAllCsvImportCategory, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
