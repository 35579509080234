import { DASHBOARD_CHART_ERROR, DASHBOARD_CHART_LOADING, DASHBOARD_CHART_SUCCESS, DASHBOARD_FIRST_ERROR, DASHBOARD_FIRST_LOADING, DASHBOARD_FIRST_SUCCESS, DASHBOARD_SECOND_ERROR, DASHBOARD_SECOND_LOADING, DASHBOARD_SECOND_SUCCESS, DASHBOARD_TOP_ITEMS_ERROR, DASHBOARD_TOP_ITEMS_LOADING, DASHBOARD_TOP_ITEMS_SUCCESS } from './actionTypes';

// get dashboard first section
const getDashboardFirstLoading = () => {
  return {
    type: DASHBOARD_FIRST_LOADING,
  };
};

const getDashboardFirstSuccess = (data) => {
  return {
    type: DASHBOARD_FIRST_SUCCESS,
    payload: data,
  };
};

const getDashboardFirstError = (error) => {
  return {
    type: DASHBOARD_FIRST_ERROR,
    payload: error,
  };
};

// get dashboard second section
const getDashboardSecondLoading = () => {
  return {
    type: DASHBOARD_SECOND_LOADING,
  };
};

const getDashboardSecondSuccess = (data) => {
  return {
    type: DASHBOARD_SECOND_SUCCESS,
    payload: data,
  };
};

const getDashboardSecondError = (error) => {
  return {
    type: DASHBOARD_SECOND_ERROR,
    payload: error,
  };
};

// get dashboard chart section
const getDashboardChartLoading = () => {
  return {
    type: DASHBOARD_CHART_LOADING,
  };
};

const getDashboardChartSuccess = (data) => {
  return {
    type: DASHBOARD_CHART_SUCCESS,
    payload: data,
  };
};

const getDashboardChartError = (error) => {
  return {
    type: DASHBOARD_CHART_ERROR,
    payload: error,
  };
};

// get dashboard top 10 section
const getDashboardTopTenItemsLoading = () => {
  return {
    type: DASHBOARD_TOP_ITEMS_LOADING,
  };
};

const getDashboardTopTenItemsSuccess = (data) => {
  return {
    type: DASHBOARD_TOP_ITEMS_SUCCESS,
    payload: data,
  };
};

const getDashboardTopTenItemsError = (error) => {
  return {
    type: DASHBOARD_TOP_ITEMS_ERROR,
    payload: error,
  };
};

export { 
    getDashboardFirstLoading, 
    getDashboardFirstSuccess, 
    getDashboardFirstError,

    getDashboardSecondLoading, 
    getDashboardSecondSuccess, 
    getDashboardSecondError,

    getDashboardChartLoading, 
    getDashboardChartSuccess, 
    getDashboardChartError,

    getDashboardTopTenItemsLoading, 
    getDashboardTopTenItemsSuccess, 
    getDashboardTopTenItemsError,
};
