import { createHelpError, createHelpLoading, createHelpSuccess, deleteHelpError, deleteHelpLoading, deleteHelpSuccess, getAllHelpError, getAllHelpLoading, getAllHelpSuccess, updateHelpError, updateHelpLoading, updateHelpSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllHelpsAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllHelpLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&title=${filter?.title}&status=${filter?.status}`;
      const response = await api.get(`help/get/all?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllHelpSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllHelpError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const createHelpsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(createHelpLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('help/create', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(createHelpSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(createHelpError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateHelpsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(updateHelpLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('help/update', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(updateHelpSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(updateHelpError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const deleteHelpsAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(deleteHelpLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('help/delete', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(deleteHelpSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(deleteHelpError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllHelpsAction,
  createHelpsAction,
  updateHelpsAction,
  deleteHelpsAction
};
