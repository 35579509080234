import { getAllBuyerError, getAllBuyerLoading, getAllBuyerSuccess, getAllReferralDraftError, getAllReferralDraftLoading, getAllReferralDraftSuccess, getAllReferralError, getAllReferralLoading, getAllReferralSuccess, getAllSellerError, getAllSellerLoading, getAllSellerSuccess, getGeneratedRefByUserError, getGeneratedRefByUserLoading, getGeneratedRefByUserSuccess, getReceivedRefByUserError, getReceivedRefByUserLoading, getReceivedRefByUserSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllReferralsAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllReferralLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&from=${filter?.from}&to=${filter?.to}&name=${filter?.name}&mobile=${filter?.mobile}&status=${filter?.status}&type=${filter?.type}&item=${filter?.item}`;
      const response = await api.get(`app/referral/get/list?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllReferralSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllReferralError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getAllReferralDraftsAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllReferralDraftLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&from=${filter?.from}&to=${filter?.to}&name=${filter?.name}&mobile=${filter?.mobile}&type=${filter?.type}&item=${filter?.item}`;
      const response = await api.get(`app/referral/get/draft-list?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllReferralDraftSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllReferralDraftError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getAllBuyersAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllBuyerLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&from=${filter?.from}&to=${filter?.to}&name=${filter?.name}&mobile=${filter?.mobile}`;
      const response = await api.get(`app/referral/get/buyer/list?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllBuyerSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllBuyerError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getAllSellerAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllSellerLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&from=${filter?.from}&to=${filter?.to}&name=${filter?.name}&mobile=${filter?.mobile}`;
      const response = await api.get(`app/referral/get/seller/list?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllSellerSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllSellerError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getGeneratedRefByUserAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getGeneratedRefByUserLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('referral/get/generated/by-user-id', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getGeneratedRefByUserSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getGeneratedRefByUserError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getReceivedRefByUserAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getReceivedRefByUserLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('referral/get/received/by-user-id', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getReceivedRefByUserSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getReceivedRefByUserError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllReferralsAction,
  getAllReferralDraftsAction,
  getAllBuyersAction,
  getAllSellerAction,
  getGeneratedRefByUserAction,
  getReceivedRefByUserAction
};
