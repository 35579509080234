import { CREATE_HELP_ERROR, CREATE_HELP_LOADING, CREATE_HELP_SUCCESS, DELETE_HELP_ERROR, DELETE_HELP_LOADING, DELETE_HELP_SUCCESS, GET_ALL_HELP_ERROR, GET_ALL_HELP_LOADING, GET_ALL_HELP_SUCCESS, UPDATE_HELP_ERROR, UPDATE_HELP_LOADING, UPDATE_HELP_SUCCESS } from "./actionTypes";

const initialState = {
    allHelp: {
        loading: false,
        data: null,
        error: null
    },
    createHelp: {
        loading: false,
        data: null,
        error: null
    },
    updateHelp: {
        loading: false,
        data: null,
        error: null
    },
    deleteHelp: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_HELP_LOADING:
            return { ...state, allHelp: { ...state.allHelp, loading: true, data: null, error: null } };
        case GET_ALL_HELP_SUCCESS:
            return { ...state, allHelp: { ...state.allHelp, loading: false, data: action.payload, error: null } };
        case GET_ALL_HELP_ERROR:
            return { ...state, allHelp: { ...state.allHelp, loading: false, data: null, error: action.payload } };

        case CREATE_HELP_LOADING:
            return { ...state, createHelp: { ...state.createHelp, loading: true, data: null, error: null } };
        case CREATE_HELP_SUCCESS:
            return { ...state, createHelp: { ...state.createHelp, loading: false, data: action.payload, error: null } };
        case CREATE_HELP_ERROR:
            return { ...state, createHelp: { ...state.createHelp, loading: false, data: null, error: action.payload } };

        case UPDATE_HELP_LOADING:
            return { ...state, updateHelp: { ...state.updateHelp, loading: true, data: null, error: null } };
        case UPDATE_HELP_SUCCESS:
            return { ...state, updateHelp: { ...state.updateHelp, loading: false, data: action.payload, error: null } };
        case UPDATE_HELP_ERROR:
            return { ...state, updateHelp: { ...state.updateHelp, loading: false, data: null, error: action.payload } };

        case DELETE_HELP_LOADING:
            return { ...state, deleteHelp: { ...state.deleteHelp, loading: true, data: null, error: null } };
        case DELETE_HELP_SUCCESS:
            return { ...state, deleteHelp: { ...state.deleteHelp, loading: false, data: action.payload, error: null } };
        case DELETE_HELP_ERROR:
            return { ...state, deleteHelp: { ...state.deleteHelp, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
