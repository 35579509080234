import { GET_ALL_ITEMS_LOADING, GET_ALL_ITEMS_SUCCESS, GET_ALL_ITEMS_ERROR, CREATE_ITEM_LOADING, CREATE_ITEM_SUCCESS, CREATE_ITEM_ERROR, UPDATE_ITEM_LOADING, UPDATE_ITEM_SUCCESS, UPDATE_ITEM_ERROR, DELETE_ITEM_LOADING, DELETE_ITEM_SUCCESS, DELETE_ITEM_ERROR, UPDATE_ITEM_STATUS_LOADING, UPDATE_ITEM_STATUS_SUCCESS, UPDATE_ITEM_STATUS_ERROR, SEARCH_ITEM_LOADING, SEARCH_ITEM_SUCCESS, SEARCH_ITEM_ERROR, EXPORT_ALL_CSV_ITEMS_LOADING, EXPORT_ALL_CSV_ITEMS_SUCCESS, EXPORT_ALL_CSV_ITEMS_ERROR, IMPORT_ALL_CSV_ITEMS_LOADING, IMPORT_ALL_CSV_ITEMS_SUCCESS, IMPORT_ALL_CSV_ITEMS_ERROR } from './actionTypes';

// get all Items
const getAllItemsLoading = () => {
  return {
    type: GET_ALL_ITEMS_LOADING,
  };
};

const getAllItemsSuccess = (data) => {
  return {
    type: GET_ALL_ITEMS_SUCCESS,
    payload: data,
  };
};

const getAllItemsError = (error) => {
  return {
    type: GET_ALL_ITEMS_ERROR,
    payload: error,
  };
};

// export all csv Items
const exportAllCsvItemsLoading = () => {
  return {
    type: EXPORT_ALL_CSV_ITEMS_LOADING,
  };
};

const exportAllCsvItemsSuccess = (data) => {
  return {
    type: EXPORT_ALL_CSV_ITEMS_SUCCESS,
    payload: data,
  };
};

const exportAllCsvItemsError = (error) => {
  return {
    type: EXPORT_ALL_CSV_ITEMS_ERROR,
    payload: error,
  };
};

// import all csv Items
const importAllCsvItemsLoading = () => {
  return {
    type: IMPORT_ALL_CSV_ITEMS_LOADING,
  };
};

const importAllCsvItemsSuccess = (data) => {
  return {
    type: IMPORT_ALL_CSV_ITEMS_SUCCESS,
    payload: data,
  };
};

const importAllCsvItemsError = (error) => {
  return {
    type: IMPORT_ALL_CSV_ITEMS_ERROR,
    payload: error,
  };
};

// create item
const createItemLoading = () => {
  return {
    type: CREATE_ITEM_LOADING,
  };
};

const createItemSuccess = (data) => {
  return {
    type: CREATE_ITEM_SUCCESS,
    payload: data,
  };
};

const createItemError = (error) => {
  return {
    type: CREATE_ITEM_ERROR,
    payload: error,
  };
};

// update item
const updateItemLoading = () => {
  return {
    type: UPDATE_ITEM_LOADING,
  };
};

const updateItemSuccess = (data) => {
  return {
    type: UPDATE_ITEM_SUCCESS,
    payload: data,
  };
};

const updateItemError = (error) => {
  return {
    type: UPDATE_ITEM_ERROR,
    payload: error,
  };
};

// update item status
const updateItemStatusLoading = () => {
  return {
    type: UPDATE_ITEM_STATUS_LOADING,
  };
};

const updateItemStatusSuccess = (data) => {
  return {
    type: UPDATE_ITEM_STATUS_SUCCESS,
    payload: data,
  };
};

const updateItemStatusError = (error) => {
  return {
    type: UPDATE_ITEM_STATUS_ERROR,
    payload: error,
  };
};

// delete item
const deleteItemLoading = () => {
  return {
    type: DELETE_ITEM_LOADING,
  };
};

const deleteItemSuccess = (data) => {
  return {
    type: DELETE_ITEM_SUCCESS,
    payload: data,
  };
};

const deleteItemError = (error) => {
  return {
    type: DELETE_ITEM_ERROR,
    payload: error,
  };
};

// search item
const searchItemLoading = () => {
  return {
    type: SEARCH_ITEM_LOADING,
  };
};

const searchItemSuccess = (data) => {
  return {
    type: SEARCH_ITEM_SUCCESS,
    payload: data,
  };
};

const searchItemError = (error) => {
  return {
    type: SEARCH_ITEM_ERROR,
    payload: error,
  };
};

export { 
    getAllItemsLoading, 
    getAllItemsSuccess, 
    getAllItemsError,

    exportAllCsvItemsLoading, 
    exportAllCsvItemsSuccess, 
    exportAllCsvItemsError,

    importAllCsvItemsLoading, 
    importAllCsvItemsSuccess, 
    importAllCsvItemsError,

    createItemLoading, 
    createItemSuccess, 
    createItemError,

    updateItemLoading, 
    updateItemSuccess, 
    updateItemError,

    updateItemStatusLoading, 
    updateItemStatusSuccess, 
    updateItemStatusError,

    deleteItemLoading, 
    deleteItemSuccess, 
    deleteItemError,

    searchItemLoading, 
    searchItemSuccess, 
    searchItemError
};
