import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, MenuItem, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { getAllActivityLogsAction } from '../../store/activity-log/asyncActions';
import moment from 'moment';
import { getAllPermissionAction } from '../../store/role/asyncActions';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function Activity_log() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();

  const activityLogListData = useSelector((state) => state?.activityLog?.allActivityLogs);
  const [activityLogList, setActivityLogList] = useState(null);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    dispatch(getAllActivityLogsAction(page + 1, pageSize, filter));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Activity Logs - Referral App';
  }, []);

  useEffect(() => {
    if (activityLogListData?.data?.status && Array.isArray(activityLogListData?.data?.data?.records)) {
      setActivityLogList(activityLogListData?.data?.data?.records);
      setTotalCount(activityLogListData?.data?.data?.totalCount);
    }
  }, [activityLogListData]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    action: '',
    collection: '',
    search: ''
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getAllActivityLogsAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      from: '',
      to: '',
      action: '',
      collection: '',
      search: ''
    }
    setFilter(reset);
    dispatch(getAllActivityLogsAction(page + 1, pageSize, reset));
  }

  const [allKeys, setAllKeys] = useState(null);
  const viewDetails = (row) => {
    setRowData(row);
    setOpenDetailsModal(true);
    const allKeys = Array.from(new Set([
      ...Object.keys(row?.referenceDocument?.old || []),
      ...Object.keys(row?.referenceDocument?.new || [])
    ]));
    setAllKeys(allKeys);
  }

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Activity Logs
            </Typography>
            {/* <Tooltip title={'Add New Category'} placement="left">
              <Button color='default' variant='outlined' onClick={() => { resetForm(); setOpenModal(true); }}>+ Add New</Button>
            </Tooltip> */}
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        {/* {openFilter && */}
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                variant="outlined"
                label="From Date"
                type="date"
                name='from'
                className='w-100'
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={filter?.from}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                variant="outlined"
                label="To Date"
                type="date"
                name='to'
                className='w-100'
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: filter?.from, // This is the minimum date value you want to set
                  },
                }}
                value={filter?.to}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Action</InputLabel>
                <Select
                  name='action'
                  label="Action"
                  value={filter?.action}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="created">Created</MenuItem>
                  <MenuItem value="updated">Updated</MenuItem>
                  <MenuItem value="deleted">Deleted</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                name='collection'
                label="Collection"
                variant="outlined"
                size="small"
                value={filter?.collection}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                name='search'
                label="Search..."
                variant="outlined"
                size="small"
                value={filter?.search}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        {/* } */}
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Created At</TableCell>
                  <TableCell style={{ minWidth: '125px' }}>Collection</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>User Name</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Action</TableCell>
                  <TableCell style={{ minWidth: '100px' }} align='right'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activityLogList && activityLogList.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>{moment(row?.createdAt).format('YYYY-MM-DD hh:mmA')}</TableCell>
                      <TableCell>{row?.collectionType}</TableCell>
                      <TableCell>
                        {row?.adminData?.[0]?.first_name + ' ' + row?.adminData?.[0]?.last_name}
                      </TableCell>
                      <TableCell>{row?.action}</TableCell>
                      <TableCell align='right'>
                        {adminPermission && (adminPermission.includes('Activity Log Details') || adminPermission.includes('All')) &&
                          <Tooltip title={'View'} placement="left">
                            <IconButton onClick={() => viewDetails(row)}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* User details view dialog */}
      <Dialog
        fullScreen
        open={openDetailsModal}
        onClose={() => { setOpenDetailsModal(false); setRowData(null) }}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => { setOpenDetailsModal(false); setRowData(null) }} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              Activity Log Details
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }}>
              {/* {categoryFormData?.id ? 'Update' : 'Save'} */}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <div>
            <table className='w-100'>
              <tr>
                <td><Typography><b>Create Date:</b></Typography></td>
                <td><Typography style={{ textAlign: 'right' }}>{moment(rowData?.createdAt).format('YYYY-MM-DD hh:mmA')}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Collection:</b></Typography></td>
                <td><Typography style={{ textAlign: 'right' }}>{rowData?.collectionType}</Typography></td>
              </tr>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>User Name:</b></Typography></td>
                <td>
                  <Typography style={{ textAlign: 'right' }}>
                    {rowData?.adminData?.[0]?.first_name + ' ' + rowData?.adminData?.[0]?.last_name}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td><Typography><b>Action:</b></Typography></td>
                <td><Typography style={{ textAlign: 'right' }}>{rowData?.action}</Typography></td>
              </tr>
            </table>
            <hr className='my-1' />
            <table className='w-100 ActivityLogTBL'>
              <tr>
                <th><Typography><b>#</b></Typography></th>
                {rowData?.referenceDocument?.old &&
                  <th><Typography><b>Old</b></Typography></th>
                }
                {rowData?.referenceDocument?.new &&
                  <th><Typography><b>New</b></Typography></th>
                }
              </tr>
              {allKeys && allKeys?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td><Typography><b>{item}:</b></Typography></td>
                    {rowData?.referenceDocument?.old &&
                      <td>
                        <Typography style={{ textAlign: 'right', maxWidth: '200px' }}>
                          {typeof rowData?.referenceDocument?.old?.[item] === "object" ?
                            JSON.stringify(rowData?.referenceDocument?.old?.[item])
                            :
                            rowData?.referenceDocument?.old?.[item]
                          }
                        </Typography>
                      </td>
                    }
                    {rowData?.referenceDocument?.new &&
                      <td>
                        <Typography style={{ textAlign: 'right', maxWidth: '200px' }}>
                          {typeof rowData?.referenceDocument?.new?.[item] === "object" ?
                            JSON.stringify(rowData?.referenceDocument?.new?.[item])
                            :
                            rowData?.referenceDocument?.new?.[item]
                          }
                        </Typography>
                      </td>
                    }
                  </tr>
                )
              })}
            </table>
            <div className='py-2'></div>
          </div>
        </DialogContent>
      </Dialog>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Activity_log
