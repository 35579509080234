import { COMPLETE_WITHDRAWREQ_ERROR, COMPLETE_WITHDRAWREQ_LOADING, COMPLETE_WITHDRAWREQ_SUCCESS, CREATE_WITHDRAWREQ_ERROR, CREATE_WITHDRAWREQ_LOADING, CREATE_WITHDRAWREQ_SUCCESS, DELETE_WITHDRAWREQ_ERROR, DELETE_WITHDRAWREQ_LOADING, DELETE_WITHDRAWREQ_SUCCESS, GET_ALL_WITHDRAWREQ_ERROR, GET_ALL_WITHDRAWREQ_LOADING, GET_ALL_WITHDRAWREQ_SUCCESS, GET_USER_WALLET_ERROR, GET_USER_WALLET_LOADING, GET_USER_WALLET_SUCCESS, UPDATE_WITHDRAWREQ_STATUS_ERROR, UPDATE_WITHDRAWREQ_STATUS_LOADING, UPDATE_WITHDRAWREQ_STATUS_SUCCESS } from './actionTypes';

// WithdrawReq list
const getAllWithdrawReqLoading = () => {
  return {
    type: GET_ALL_WITHDRAWREQ_LOADING,
  };
};

const getAllWithdrawReqSuccess = (data) => {
  return {
    type: GET_ALL_WITHDRAWREQ_SUCCESS,
    payload: data,
  };
};

const getAllWithdrawReqError = (error) => {
  return {
    type: GET_ALL_WITHDRAWREQ_ERROR,
    payload: error,
  };
};

// create WithdrawReq
const createWithdrawReqLoading = () => {
  return {
    type: CREATE_WITHDRAWREQ_LOADING,
  };
};

const createWithdrawReqSuccess = (data) => {
  return {
    type: CREATE_WITHDRAWREQ_SUCCESS,
    payload: data,
  };
};

const createWithdrawReqError = (error) => {
  return {
    type: CREATE_WITHDRAWREQ_ERROR,
    payload: error,
  };
};

// update WithdrawReq status
const updateWithdrawReqStatusLoading = () => {
  return {
    type: UPDATE_WITHDRAWREQ_STATUS_LOADING,
  };
};

const updateWithdrawReqStatusSuccess = (data) => {
  return {
    type: UPDATE_WITHDRAWREQ_STATUS_SUCCESS,
    payload: data,
  };
};

const updateWithdrawReqStatusError = (error) => {
  return {
    type: UPDATE_WITHDRAWREQ_STATUS_ERROR,
    payload: error,
  };
};

// delete WithdrawReq
const deleteWithdrawReqLoading = () => {
  return {
    type: DELETE_WITHDRAWREQ_LOADING,
  };
};

const deleteWithdrawReqSuccess = (data) => {
  return {
    type: DELETE_WITHDRAWREQ_SUCCESS,
    payload: data,
  };
};

const deleteWithdrawReqError = (error) => {
  return {
    type: DELETE_WITHDRAWREQ_ERROR,
    payload: error,
  };
};

// get users wallet
const getUserWalletLoading = () => {
  return {
    type: GET_USER_WALLET_LOADING,
  };
};

const getUserWalletSuccess = (data) => {
  return {
    type: GET_USER_WALLET_SUCCESS,
    payload: data,
  };
};

const getUserWalletError = (error) => {
  return {
    type: GET_USER_WALLET_ERROR,
    payload: error,
  };
};

// complete WithdrawReq
const completeWithdrawReqLoading = () => {
  return {
    type: COMPLETE_WITHDRAWREQ_LOADING,
  };
};

const completeWithdrawReqSuccess = (data) => {
  return {
    type: COMPLETE_WITHDRAWREQ_SUCCESS,
    payload: data,
  };
};

const completeWithdrawReqError = (error) => {
  return {
    type: COMPLETE_WITHDRAWREQ_ERROR,
    payload: error,
  };
};

export { 
    getAllWithdrawReqLoading, 
    getAllWithdrawReqSuccess, 
    getAllWithdrawReqError,

    createWithdrawReqLoading, 
    createWithdrawReqSuccess, 
    createWithdrawReqError,

    updateWithdrawReqStatusLoading, 
    updateWithdrawReqStatusSuccess, 
    updateWithdrawReqStatusError,

    deleteWithdrawReqLoading, 
    deleteWithdrawReqSuccess, 
    deleteWithdrawReqError,

    getUserWalletLoading, 
    getUserWalletSuccess, 
    getUserWalletError,

    completeWithdrawReqLoading, 
    completeWithdrawReqSuccess, 
    completeWithdrawReqError,
};
