export const GET_ALL_REFUNDREQ_LOADING = 'GET_ALL_REFUNDREQ_LOADING';
export const GET_ALL_REFUNDREQ_SUCCESS = 'GET_ALL_REFUNDREQ_SUCCESS';
export const GET_ALL_REFUNDREQ_ERROR = 'GET_ALL_REFUNDREQ_ERROR';

export const UPDATE_REFUNDREQ_STATUS_LOADING = 'UPDATE_REFUNDREQ_STATUS_LOADING';
export const UPDATE_REFUNDREQ_STATUS_SUCCESS = 'UPDATE_REFUNDREQ_STATUS_SUCCESS';
export const UPDATE_REFUNDREQ_STATUS_ERROR = 'UPDATE_REFUNDREQ_STATUS_ERROR';

export const APPROVE_REFUNDREQ_LOADING = 'APPROVE_REFUNDREQ_LOADING';
export const APPROVE_REFUNDREQ_SUCCESS = 'APPROVE_REFUNDREQ_SUCCESS';
export const APPROVE_REFUNDREQ_ERROR = 'APPROVE_REFUNDREQ_ERROR';
