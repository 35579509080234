import Avatar from "@material-ui/core/Avatar";
import React from "react";

export default [
  {
    name: "Bobby Sullivan",
    status: "Mollis Nullam",
    avatar: (
      <Avatar
        alt=""
        src={`${process.env.PUBLIC_URL}/static/images/face3.jpg`}
      />
    )
  },
  {
    name: "Bryan Morgan",
    status: "Risus Justo",
    avatar: (
      <Avatar
        alt=""
        src={`${process.env.PUBLIC_URL}/static/images/face4.jpg`}
      />
    )
  },
  {
    name: "Phillip Caroll",
    status: "Mollis Nibh",
    avatar: (
      <Avatar
        alt=""
        src={`${process.env.PUBLIC_URL}/static/images/face5.jpg`}
      />
    )
  },
  {
    name: "Brandon Boyd",
    status: "Dolor Mattis",
    avatar: (
      <Avatar
        alt=""
        src={`${process.env.PUBLIC_URL}/static/images/face4.jpg`}
      />
    )
  },
  {
    name: "Laura Mason",
    status: "Commodo Amet",
    avatar: (
      <Avatar
        alt=""
        src={`${process.env.PUBLIC_URL}/static/images/face3.jpg`}
      />
    )
  },
  {
    name: "Barbara Chapman",
    status: "Tellus Sollicitudin",
    avatar: (
      <Avatar
        alt=""
        src={`${process.env.PUBLIC_URL}/static/images/face2.jpg`}
      />
    )
  },
  {
    name: "Doris Baker",
    status: "Nibh Adipiscing",
    avatar: (
      <Avatar
        alt=""
        src={`${process.env.PUBLIC_URL}/static/images/face1.jpg`}
      />
    )
  }
];
