import { DASHBOARD_CHART_ERROR, DASHBOARD_CHART_LOADING, DASHBOARD_CHART_SUCCESS, DASHBOARD_FIRST_ERROR, DASHBOARD_FIRST_LOADING, DASHBOARD_FIRST_SUCCESS, DASHBOARD_SECOND_ERROR, DASHBOARD_SECOND_LOADING, DASHBOARD_SECOND_SUCCESS, DASHBOARD_TOP_ITEMS_ERROR, DASHBOARD_TOP_ITEMS_LOADING, DASHBOARD_TOP_ITEMS_SUCCESS } from "./actionTypes";

const initialState = {
    dashboardFirst: {
        loading: false,
        data: null,
        error: null
    },
    dashboardSecond: {
        loading: false,
        data: null,
        error: null
    },
    dashboardChart: {
        loading: false,
        data: null,
        error: null
    },
    topTenItems: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_FIRST_LOADING:
            return { ...state, dashboardFirst: { ...state.dashboardFirst, loading: true, data: null, error: null } };
        case DASHBOARD_FIRST_SUCCESS:
            return { ...state, dashboardFirst: { ...state.dashboardFirst, loading: false, data: action.payload, error: null } };
        case DASHBOARD_FIRST_ERROR:
            return { ...state, dashboardFirst: { ...state.dashboardFirst, loading: false, data: null, error: action.payload } };

        case DASHBOARD_SECOND_LOADING:
            return { ...state, dashboardSecond: { ...state.dashboardSecond, loading: true, data: null, error: null } };
        case DASHBOARD_SECOND_SUCCESS:
            return { ...state, dashboardSecond: { ...state.dashboardSecond, loading: false, data: action.payload, error: null } };
        case DASHBOARD_SECOND_ERROR:
            return { ...state, dashboardSecond: { ...state.dashboardSecond, loading: false, data: null, error: action.payload } };

        case DASHBOARD_CHART_LOADING:
            return { ...state, dashboardChart: { ...state.dashboardChart, loading: true, data: null, error: null } };
        case DASHBOARD_CHART_SUCCESS:
            return { ...state, dashboardChart: { ...state.dashboardChart, loading: false, data: action.payload, error: null } };
        case DASHBOARD_CHART_ERROR:
            return { ...state, dashboardChart: { ...state.dashboardChart, loading: false, data: null, error: action.payload } };

        case DASHBOARD_TOP_ITEMS_LOADING:
            return { ...state, topTenItems: { ...state.topTenItems, loading: true, data: null, error: null } };
        case DASHBOARD_TOP_ITEMS_SUCCESS:
            return { ...state, topTenItems: { ...state.topTenItems, loading: false, data: action.payload, error: null } };
        case DASHBOARD_TOP_ITEMS_ERROR:
            return { ...state, topTenItems: { ...state.topTenItems, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
