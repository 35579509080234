import { CREATE_POPUP_ERROR, CREATE_POPUP_LOADING, CREATE_POPUP_SUCCESS, DELETE_POPUP_ERROR, DELETE_POPUP_LOADING, DELETE_POPUP_SUCCESS, GET_ALL_POPUP_ERROR, GET_ALL_POPUP_LOADING, GET_ALL_POPUP_SUCCESS, UPDATE_POPUP_ERROR, UPDATE_POPUP_LOADING, UPDATE_POPUP_SUCCESS } from "./actionTypes";

const initialState = {
    allPopup: {
        loading: false,
        data: null,
        error: null
    },
    createPopup: {
        loading: false,
        data: null,
        error: null
    },
    updatePopup: {
        loading: false,
        data: null,
        error: null
    },
    deletePopup: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_POPUP_LOADING:
            return { ...state, allPopup: { ...state.allPopup, loading: true, data: null, error: null } };
        case GET_ALL_POPUP_SUCCESS:
            return { ...state, allPopup: { ...state.allPopup, loading: false, data: action.payload, error: null } };
        case GET_ALL_POPUP_ERROR:
            return { ...state, allPopup: { ...state.allPopup, loading: false, data: null, error: action.payload } };

        case CREATE_POPUP_LOADING:
            return { ...state, createPopup: { ...state.createPopup, loading: true, data: null, error: null } };
        case CREATE_POPUP_SUCCESS:
            return { ...state, createPopup: { ...state.createPopup, loading: false, data: action.payload, error: null } };
        case CREATE_POPUP_ERROR:
            return { ...state, createPopup: { ...state.createPopup, loading: false, data: null, error: action.payload } };

        case UPDATE_POPUP_LOADING:
            return { ...state, updatePopup: { ...state.updatePopup, loading: true, data: null, error: null } };
        case UPDATE_POPUP_SUCCESS:
            return { ...state, updatePopup: { ...state.updatePopup, loading: false, data: action.payload, error: null } };
        case UPDATE_POPUP_ERROR:
            return { ...state, updatePopup: { ...state.updatePopup, loading: false, data: null, error: action.payload } };

        case DELETE_POPUP_LOADING:
            return { ...state, deletePopup: { ...state.deletePopup, loading: true, data: null, error: null } };
        case DELETE_POPUP_SUCCESS:
            return { ...state, deletePopup: { ...state.deletePopup, loading: false, data: action.payload, error: null } };
        case DELETE_POPUP_ERROR:
            return { ...state, deletePopup: { ...state.deletePopup, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
