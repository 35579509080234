import { GET_ALL_ACTIVITY_LOG_ERROR, GET_ALL_ACTIVITY_LOG_LOADING, GET_ALL_ACTIVITY_LOG_SUCCESS } from "./actionTypes";

const initialState = {
    allActivityLogs: {
        loading: false,
        data: null,
        error: null
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_ACTIVITY_LOG_LOADING:
            return { ...state, allActivityLogs: { ...state.allActivityLogs, loading: true, data: null, error: null } };
        case GET_ALL_ACTIVITY_LOG_SUCCESS:
            return { ...state, allActivityLogs: { ...state.allActivityLogs, loading: false, data: action.payload, error: null } };
        case GET_ALL_ACTIVITY_LOG_ERROR:
            return { ...state, allActivityLogs: { ...state.allActivityLogs, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
