import { getAllSearchUsersError, getAllSearchUsersLoading, getAllSearchUsersSuccess, getAllUserRefCountError, getAllUserRefCountLoading, getAllUserRefCountSuccess, updateUserStatusError, updateUserStatusLoading, updateUserStatusSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getUserRefCountListAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllUserRefCountLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&from=${filter?.from}&to=${filter?.to}&name=${filter?.name}&mobile=${filter?.mobile}&status=${filter?.status}`;
      const response = await api.get(`app/user/get/all/referal-count?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllUserRefCountSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllUserRefCountError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const getSearchUsersAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllSearchUsersLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('app/user/get/all/search', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllSearchUsersSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllSearchUsersError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const updateUserStatusAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(updateUserStatusLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post('app/user/status/update', postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(updateUserStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(updateUserStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getUserRefCountListAction,
  getSearchUsersAction,
  updateUserStatusAction
};
