import { CREATE_HELP_ERROR, CREATE_HELP_LOADING, CREATE_HELP_SUCCESS, DELETE_HELP_ERROR, DELETE_HELP_LOADING, DELETE_HELP_SUCCESS, GET_ALL_HELP_ERROR, GET_ALL_HELP_LOADING, GET_ALL_HELP_SUCCESS, UPDATE_HELP_ERROR, UPDATE_HELP_LOADING, UPDATE_HELP_SUCCESS } from './actionTypes';

// Help list
const getAllHelpLoading = () => {
  return {
    type: GET_ALL_HELP_LOADING,
  };
};

const getAllHelpSuccess = (data) => {
  return {
    type: GET_ALL_HELP_SUCCESS,
    payload: data,
  };
};

const getAllHelpError = (error) => {
  return {
    type: GET_ALL_HELP_ERROR,
    payload: error,
  };
};

// create Help
const createHelpLoading = () => {
  return {
    type: CREATE_HELP_LOADING,
  };
};

const createHelpSuccess = (data) => {
  return {
    type: CREATE_HELP_SUCCESS,
    payload: data,
  };
};

const createHelpError = (error) => {
  return {
    type: CREATE_HELP_ERROR,
    payload: error,
  };
};

// update Help
const updateHelpLoading = () => {
  return {
    type: UPDATE_HELP_LOADING,
  };
};

const updateHelpSuccess = (data) => {
  return {
    type: UPDATE_HELP_SUCCESS,
    payload: data,
  };
};

const updateHelpError = (error) => {
  return {
    type: UPDATE_HELP_ERROR,
    payload: error,
  };
};

// delete Help
const deleteHelpLoading = () => {
  return {
    type: DELETE_HELP_LOADING,
  };
};

const deleteHelpSuccess = (data) => {
  return {
    type: DELETE_HELP_SUCCESS,
    payload: data,
  };
};

const deleteHelpError = (error) => {
  return {
    type: DELETE_HELP_ERROR,
    payload: error,
  };
};

export { 
    getAllHelpLoading, 
    getAllHelpSuccess, 
    getAllHelpError,

    createHelpLoading, 
    createHelpSuccess, 
    createHelpError,

    updateHelpLoading, 
    updateHelpSuccess, 
    updateHelpError,

    deleteHelpLoading, 
    deleteHelpSuccess, 
    deleteHelpError,
};
