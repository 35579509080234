import { CREATE_POPUP_ERROR, CREATE_POPUP_LOADING, CREATE_POPUP_SUCCESS, DELETE_POPUP_ERROR, DELETE_POPUP_LOADING, DELETE_POPUP_SUCCESS, GET_ALL_POPUP_ERROR, GET_ALL_POPUP_LOADING, GET_ALL_POPUP_SUCCESS, UPDATE_POPUP_ERROR, UPDATE_POPUP_LOADING, UPDATE_POPUP_SUCCESS } from './actionTypes';

// Popup list
const getAllPopupLoading = () => {
  return {
    type: GET_ALL_POPUP_LOADING,
  };
};

const getAllPopupSuccess = (data) => {
  return {
    type: GET_ALL_POPUP_SUCCESS,
    payload: data,
  };
};

const getAllPopupError = (error) => {
  return {
    type: GET_ALL_POPUP_ERROR,
    payload: error,
  };
};

// create Popup
const createPopupLoading = () => {
  return {
    type: CREATE_POPUP_LOADING,
  };
};

const createPopupSuccess = (data) => {
  return {
    type: CREATE_POPUP_SUCCESS,
    payload: data,
  };
};

const createPopupError = (error) => {
  return {
    type: CREATE_POPUP_ERROR,
    payload: error,
  };
};

// update Popup
const updatePopupLoading = () => {
  return {
    type: UPDATE_POPUP_LOADING,
  };
};

const updatePopupSuccess = (data) => {
  return {
    type: UPDATE_POPUP_SUCCESS,
    payload: data,
  };
};

const updatePopupError = (error) => {
  return {
    type: UPDATE_POPUP_ERROR,
    payload: error,
  };
};

// delete Popup
const deletePopupLoading = () => {
  return {
    type: DELETE_POPUP_LOADING,
  };
};

const deletePopupSuccess = (data) => {
  return {
    type: DELETE_POPUP_SUCCESS,
    payload: data,
  };
};

const deletePopupError = (error) => {
  return {
    type: DELETE_POPUP_ERROR,
    payload: error,
  };
};

export { 
    getAllPopupLoading, 
    getAllPopupSuccess, 
    getAllPopupError,

    createPopupLoading, 
    createPopupSuccess, 
    createPopupError,

    updatePopupLoading, 
    updatePopupSuccess, 
    updatePopupError,

    deletePopupLoading, 
    deletePopupSuccess, 
    deletePopupError,
};
