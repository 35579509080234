import { approveRefundReqError, approveRefundReqLoading, approveRefundReqSuccess, getAllRefundReqError, getAllRefundReqLoading, getAllRefundReqSuccess, updateRefundReqStatusError, updateRefundReqStatusLoading, updateRefundReqStatusSuccess } from './actions';
import api from '../api';
import { toast } from 'react-toastify';

const getAllRefundReqsAction = (page, size, filter) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(getAllRefundReqLoading());

      // Perform the asynchronous operation (e.g., API call)
      const filterData = `&from=${filter?.from}&to=${filter?.to}&name=${filter?.name}&mobile=${filter?.mobile}&status=${filter?.status}&type=${filter?.type}&item=${filter?.item}`;
      const response = await api.get(`referral/get/refund-requested?page=${page}&size=${size}${filterData}`);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(getAllRefundReqSuccess(data));
      if (data?.status) {
        // toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(getAllRefundReqError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const referralStatusUpdateAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(updateRefundReqStatusLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post(`referral/update/status`, postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(updateRefundReqStatusSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(updateRefundReqStatusError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

const approveRefundReqAction = (postData) => {
  return async (dispatch) => {
    try {
      // Start the loading state
      dispatch(approveRefundReqLoading());

      // Perform the asynchronous operation (e.g., API call)
      const response = await api.post(`referral/refund-requested/approve`, postData);
      const data = response.data;

      // Dispatch success action with the received data
      dispatch(approveRefundReqSuccess(data));
      if (data?.status) {
        toast.success(data?.message);
      }
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(approveRefundReqError(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  getAllRefundReqsAction,
  referralStatusUpdateAction,
  approveRefundReqAction
};
