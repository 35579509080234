import { CREATE_ROLE_ERROR, CREATE_ROLE_LOADING, CREATE_ROLE_SUCCESS, DELETE_ROLE_ERROR, DELETE_ROLE_LOADING, DELETE_ROLE_SUCCESS, GET_ALL_PERMISSIONS_ERROR, GET_ALL_PERMISSIONS_LOADING, GET_ALL_PERMISSIONS_SUCCESS, GET_ALL_ROLES_ERROR, GET_ALL_ROLES_LOADING, GET_ALL_ROLES_SUCCESS, SEARCH_ROLE_ERROR, SEARCH_ROLE_LOADING, SEARCH_ROLE_SUCCESS, UPDATE_ROLE_ERROR, UPDATE_ROLE_LOADING, UPDATE_ROLE_STATUS_ERROR, UPDATE_ROLE_STATUS_LOADING, UPDATE_ROLE_STATUS_SUCCESS, UPDATE_ROLE_SUCCESS } from "./actionTypes";

const initialState = {
    getAllRoles: {
        loading: false,
        data: null,
        error: null
    },
    getAllPermissions: {
        loading: false,
        data: null,
        error: null
    },
    createRole: {
        loading: false,
        data: null,
        error: null
    },
    updateRole: {
        loading: false,
        data: null,
        error: null
    },
    updateRoleStatus: {
        loading: false,
        data: null,
        error: null
    },
    deleteRole: {
        loading: false,
        data: null,
        error: null
    },
    searchRole: {
        loading: false,
        data: null,
        error: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_ROLES_LOADING:
            return { ...state, getAllRoles: { ...state.getAllRoles, loading: true, data: null, error: null } };
        case GET_ALL_ROLES_SUCCESS:
            return { ...state, getAllRoles: { ...state.getAllRoles, loading: false, data: action.payload, error: null } };
        case GET_ALL_ROLES_ERROR:
            return { ...state, getAllRoles: { ...state.getAllRoles, loading: false, data: null, error: action.payload } };

        case GET_ALL_PERMISSIONS_LOADING:
            return { ...state, getAllPermissions: { ...state.getAllPermissions, loading: true, data: null, error: null } };
        case GET_ALL_PERMISSIONS_SUCCESS:
            return { ...state, getAllPermissions: { ...state.getAllPermissions, loading: false, data: action.payload, error: null } };
        case GET_ALL_PERMISSIONS_ERROR:
            return { ...state, getAllPermissions: { ...state.getAllPermissions, loading: false, data: null, error: action.payload } };

        case CREATE_ROLE_LOADING:
            return { ...state, createRole: { ...state.createRole, loading: true, data: null, error: null } };
        case CREATE_ROLE_SUCCESS:
            return { ...state, createRole: { ...state.createRole, loading: false, data: action.payload, error: null } };
        case CREATE_ROLE_ERROR:
            return { ...state, createRole: { ...state.createRole, loading: false, data: null, error: action.payload } };

        case UPDATE_ROLE_LOADING:
            return { ...state, updateRole: { ...state.updateRole, loading: true, data: null, error: null } };
        case UPDATE_ROLE_SUCCESS:
            return { ...state, updateRole: { ...state.updateRole, loading: false, data: action.payload, error: null } };
        case UPDATE_ROLE_ERROR:
            return { ...state, updateRole: { ...state.updateRole, loading: false, data: null, error: action.payload } };

        case UPDATE_ROLE_STATUS_LOADING:
            return { ...state, updateRoleStatus: { ...state.updateRoleStatus, loading: true, data: null, error: null } };
        case UPDATE_ROLE_STATUS_SUCCESS:
            return { ...state, updateRoleStatus: { ...state.updateRoleStatus, loading: false, data: action.payload, error: null } };
        case UPDATE_ROLE_STATUS_ERROR:
            return { ...state, updateRoleStatus: { ...state.updateRoleStatus, loading: false, data: null, error: action.payload } };

        case DELETE_ROLE_LOADING:
            return { ...state, deleteRole: { ...state.deleteRole, loading: true, data: null, error: null } };
        case DELETE_ROLE_SUCCESS:
            return { ...state, deleteRole: { ...state.deleteRole, loading: false, data: action.payload, error: null } };
        case DELETE_ROLE_ERROR:
            return { ...state, deleteRole: { ...state.deleteRole, loading: false, data: null, error: action.payload } };

        case SEARCH_ROLE_LOADING:
            return { ...state, searchRole: { ...state.searchRole, loading: true, data: null, error: null } };
        case SEARCH_ROLE_SUCCESS:
            return { ...state, searchRole: { ...state.searchRole, loading: false, data: action.payload, error: null } };
        case SEARCH_ROLE_ERROR:
            return { ...state, searchRole: { ...state.searchRole, loading: false, data: null, error: action.payload } };

        default:
            return state;
    }
};

export default reducer;
