import { GET_ALL_CATEGORIES_LOADING, GET_ALL_CATEGORIES_SUCCESS, GET_ALL_CATEGORIES_ERROR, CREATE_CATEGORY_LOADING, CREATE_CATEGORY_SUCCESS, CREATE_CATEGORY_ERROR, UPDATE_CATEGORY_LOADING, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_ERROR, DELETE_CATEGORY_LOADING, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_ERROR, UPDATE_CATEGORY_STATUS_LOADING, UPDATE_CATEGORY_STATUS_SUCCESS, UPDATE_CATEGORY_STATUS_ERROR, SEARCH_CATEGORY_LOADING, SEARCH_CATEGORY_SUCCESS, SEARCH_CATEGORY_ERROR, EXPORT_ALL_CSV_CATEGORY_LOADING, EXPORT_ALL_CSV_CATEGORY_SUCCESS, EXPORT_ALL_CSV_CATEGORY_ERROR, IMPORT_ALL_CSV_CATEGORY_LOADING, IMPORT_ALL_CSV_CATEGORY_SUCCESS, IMPORT_ALL_CSV_CATEGORY_ERROR } from './actionTypes';

// get all categories
const getAllCategoriesLoading = () => {
  return {
    type: GET_ALL_CATEGORIES_LOADING,
  };
};

const getAllCategoriesSuccess = (data) => {
  return {
    type: GET_ALL_CATEGORIES_SUCCESS,
    payload: data,
  };
};

const getAllCategoriesError = (error) => {
  return {
    type: GET_ALL_CATEGORIES_ERROR,
    payload: error,
  };
};

// create category
const createCategoryLoading = () => {
  return {
    type: CREATE_CATEGORY_LOADING,
  };
};

const createCategorySuccess = (data) => {
  return {
    type: CREATE_CATEGORY_SUCCESS,
    payload: data,
  };
};

const createCategoryError = (error) => {
  return {
    type: CREATE_CATEGORY_ERROR,
    payload: error,
  };
};

// update category
const updateCategoryLoading = () => {
  return {
    type: UPDATE_CATEGORY_LOADING,
  };
};

const updateCategorySuccess = (data) => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload: data,
  };
};

const updateCategoryError = (error) => {
  return {
    type: UPDATE_CATEGORY_ERROR,
    payload: error,
  };
};

// update category status
const updateCategoryStatusLoading = () => {
  return {
    type: UPDATE_CATEGORY_STATUS_LOADING,
  };
};

const updateCategoryStatusSuccess = (data) => {
  return {
    type: UPDATE_CATEGORY_STATUS_SUCCESS,
    payload: data,
  };
};

const updateCategoryStatusError = (error) => {
  return {
    type: UPDATE_CATEGORY_STATUS_ERROR,
    payload: error,
  };
};

// delete category
const deleteCategoryLoading = () => {
  return {
    type: DELETE_CATEGORY_LOADING,
  };
};

const deleteCategorySuccess = (data) => {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    payload: data,
  };
};

const deleteCategoryError = (error) => {
  return {
    type: DELETE_CATEGORY_ERROR,
    payload: error,
  };
};

// search category
const searchCategoryLoading = () => {
  return {
    type: SEARCH_CATEGORY_LOADING,
  };
};

const searchCategorySuccess = (data) => {
  return {
    type: SEARCH_CATEGORY_SUCCESS,
    payload: data,
  };
};

const searchCategoryError = (error) => {
  return {
    type: SEARCH_CATEGORY_ERROR,
    payload: error,
  };
};

// export all csv Category
const exportAllCsvCategoryLoading = () => {
  return {
    type: EXPORT_ALL_CSV_CATEGORY_LOADING,
  };
};

const exportAllCsvCategorySuccess = (data) => {
  return {
    type: EXPORT_ALL_CSV_CATEGORY_SUCCESS,
    payload: data,
  };
};

const exportAllCsvCategoryError = (error) => {
  return {
    type: EXPORT_ALL_CSV_CATEGORY_ERROR,
    payload: error,
  };
};

// import all csv Category
const importAllCsvCategoryLoading = () => {
  return {
    type: IMPORT_ALL_CSV_CATEGORY_LOADING,
  };
};

const importAllCsvCategorySuccess = (data) => {
  return {
    type: IMPORT_ALL_CSV_CATEGORY_SUCCESS,
    payload: data,
  };
};

const importAllCsvCategoryError = (error) => {
  return {
    type: IMPORT_ALL_CSV_CATEGORY_ERROR,
    payload: error,
  };
};

export { 
    getAllCategoriesLoading, 
    getAllCategoriesSuccess, 
    getAllCategoriesError,

    createCategoryLoading, 
    createCategorySuccess, 
    createCategoryError,

    updateCategoryLoading, 
    updateCategorySuccess, 
    updateCategoryError,

    updateCategoryStatusLoading, 
    updateCategoryStatusSuccess, 
    updateCategoryStatusError,

    deleteCategoryLoading, 
    deleteCategorySuccess, 
    deleteCategoryError,

    searchCategoryLoading, 
    searchCategorySuccess, 
    searchCategoryError,

    exportAllCsvCategoryLoading, 
    exportAllCsvCategorySuccess, 
    exportAllCsvCategoryError,

    importAllCsvCategoryLoading, 
    importAllCsvCategorySuccess, 
    importAllCsvCategoryError,
};
