import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Avatar, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, MenuItem, Paper, Select, Slide, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import { getAllSellerAction, getGeneratedRefByUserAction, getReceivedRefByUserAction } from '../../store/referral/asyncActions';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import classNames from 'classnames';
import { getAllPermissionAction } from '../../store/role/asyncActions';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function Seller_list() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);
  const [rowData, setRowData] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();

  const sellerListData = useSelector((state) => state?.referral?.allSellers);
  const [sellerList, setSellerList] = useState(null);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    dispatch(getAllSellerAction(page + 1, pageSize, filter));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Seller List - Referral App';
  }, []);

  useEffect(() => {
    if (sellerListData?.data?.status && Array.isArray(sellerListData?.data?.data?.records)) {
      setSellerList(sellerListData?.data?.data?.records);
      setTotalCount(sellerListData?.data?.data?.totalCount);
    }
  }, [sellerListData]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    from: '',
    to: '',
    name: '',
    mobile: ''
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getAllSellerAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      from: '',
      to: '',
      name: '',
      mobile: ''
    }
    setFilter(reset);
    dispatch(getAllSellerAction(page + 1, pageSize, reset));
  }

  const [tab, setTab] = useState(0);

  const [modelPage, setModelPage] = useState(0);
  const [modelPageSize, setModelPageSize] = useState(10);
  const [modelTotalCount, setModelTotalCount] = useState(0);
  const handleModelPageChange = (newPage) => {
    setModelPage(newPage);
  };
  const [modelRefList, setModelRefList] = useState(null);

  useEffect(() => {
    if (tab === 1) {
      setModelRefList(null);
      dispatch(getGeneratedRefByUserAction({
        user_id: rowData?._id,
        page: modelPage + 1,
        size: modelPageSize,
      }));
    }
    if (tab === 2) {
      setModelRefList(null);
      dispatch(getReceivedRefByUserAction({
        user_id: rowData?._id,
        page: modelPage + 1,
        size: modelPageSize,
      }));
    }
  }, [tab, modelPage, modelPageSize]);

  const generatedRefByUsr = useSelector((state) => state?.referral?.generatedRefByUser);
  const receivedRefByUsr = useSelector((state) => state?.referral?.receivedRefByUser);

  useEffect(() => {
    if (generatedRefByUsr?.data?.status && Array.isArray(generatedRefByUsr?.data?.data?.records)) {
      setModelRefList(generatedRefByUsr?.data?.data?.records);
      setModelTotalCount(generatedRefByUsr?.data?.data?.totalCount);
    }
  }, [generatedRefByUsr]);

  useEffect(() => {
    if (receivedRefByUsr?.data?.status && Array.isArray(receivedRefByUsr?.data?.data?.records)) {
      setModelRefList(receivedRefByUsr?.data?.data?.records);
      setModelTotalCount(receivedRefByUsr?.data?.data?.totalCount);
    }
  }, [receivedRefByUsr]);

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Seller List
            </Typography>
            {/* <Tooltip title={'Add New Category'} placement="left">
              <Button color='default' variant='outlined' onClick={() => { resetForm(); setOpenModal(true); }}>+ Add New</Button>
            </Tooltip> */}
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        {/* {openFilter && */}
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                variant="outlined"
                label="From Date"
                type="date"
                name='from'
                className='w-100'
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={filter?.from}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                variant="outlined"
                label="To Date"
                type="date"
                name='to'
                className='w-100'
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: filter?.from, // This is the minimum date value you want to set
                  },
                }}
                value={filter?.to}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                required
                name='name'
                label="Name"
                variant="outlined"
                size="small"
                value={filter?.name}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                required
                type='number'
                name='mobile'
                label="Mobile"
                variant="outlined"
                size="small"
                value={filter?.mobile}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={4} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        {/* } */}
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Name</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Mobile</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Referral Received</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Purchased</TableCell>
                  {/* <TableCell style={{ minWidth: '150px' }}>Genuine Referral</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Fake Requirement</TableCell> */}
                  <TableCell style={{ minWidth: '100px' }}>Deal Done</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>Yet to be Purchase</TableCell>
                  <TableCell style={{ minWidth: '125px' }} align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sellerList && sellerList.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>{row?.name}</TableCell>
                      <TableCell>{row?.mobile}</TableCell>
                      <TableCell>{row?.total_received_ref}</TableCell>
                      <TableCell>{row?.purchased_ref}</TableCell>
                      {/* <TableCell>{row?.geniuin_ref}</TableCell>
                      <TableCell>{row?.fake_ref}</TableCell> */}
                      <TableCell>{row?.deal_done}</TableCell>
                      <TableCell>{row?.yet_to_be_purchase}</TableCell>
                      <TableCell align='right'>
                        {adminPermission && (adminPermission.includes('Seller Details') || adminPermission.includes('All')) &&
                          <Tooltip title={'View'} placement="left">
                            <IconButton onClick={() => { setRowData(row); setTab(0); setOpenDetailsModal(true) }}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {/* <Tooltip title={'Edit'} placement="left">
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Tooltip> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* User details view dialog */}
      <Dialog
        fullScreen
        open={openDetailsModal}
        onClose={() => { setOpenDetailsModal(false); setRowData(null) }}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => { setOpenDetailsModal(false); setRowData(null) }} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <div style={{ marginLeft: 'auto' }}>
              <Typography variant="h6" color="inherit" align='center'>
                {rowData?.name} | {rowData?.mobile}
              </Typography>
              <Typography color="inherit" align='center'>
                Status: &nbsp; {rowData?.user_status ? 'Active' : 'Inactive'}
              </Typography>
            </div>
            <Button color="inherit" style={{ marginLeft: 'auto' }}>
              {/* {categoryFormData?.id ? 'Update' : 'Save'} */}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <div className='w-100'>
            <AppBar position="static" color='default' className={`${classes.root} w-100 pb-0`}>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                centered
                value={tab}
                onChange={(e, value) => { setModelPage(0); setTab(value); }}>
                <Tab label="Profile Details" />
                <Tab label="Generated Referral" />
                <Tab label="Received Referral" />
              </Tabs>
            </AppBar>
            {tab === 0 &&
              <div className='pt-2'>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={4}>
                    <div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Avatar
                          alt="User Profile Image"
                          src={rowData?.user_profile ? rowData?.user_profile : '/static/images/avatar.png'}
                          className={classNames(classes.avatar)}
                          style={{ height: '150px', width: '150px' }}
                        />
                      </div>
                      <div className='pt-2' style={{ display: 'flex', justifyContent: 'center' }}>
                        <table style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>
                          <tr>
                            <td style={{ minWidth: '100px' }}><b>Name: </b></td>
                            <td align='right'>{rowData?.name}</td>
                          </tr>
                          <tr>
                            <td><b>Mobile: </b></td>
                            <td align='right'>{`+${rowData?.country_code} ${rowData?.mobile}`}</td>
                          </tr>
                          <tr>
                            <td><b>State: </b></td>
                            <td align='right'>{rowData?.state}</td>
                          </tr>
                          <tr>
                            <td><b>City: </b></td>
                            <td align='right'>{rowData?.city}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <Grid container spacing={2}>

                      <Grid item xs={12} md={6} lg={4}>
                        <Card className='w-100 py-2'>
                          <Typography variant="h6" color="primary" align='center'>
                            Referral Received
                          </Typography>
                          <Typography color="inherit" align='center' className='pt-2' style={{ fontSize: '20px' }}>
                            {rowData?.total_received_ref}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Card className='w-100 py-2'>
                          <Typography variant="h6" color="primary" align='center'>
                            Purchased
                          </Typography>
                          <Typography color="inherit" align='center' className='pt-2' style={{ fontSize: '20px' }}>
                            {rowData?.purchased_ref}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Card className='w-100 py-2'>
                          <Typography variant="h6" color="primary" align='center'>
                            Deal Done
                          </Typography>
                          <Typography color="inherit" align='center' className='pt-2' style={{ fontSize: '20px' }}>
                            {rowData?.deal_done}
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Card className='w-100 py-2'>
                          <Typography variant="h6" color="primary" align='center'>
                            Yet to be Purchase
                          </Typography>
                          <Typography color="inherit" align='center' className='pt-2' style={{ fontSize: '20px' }}>
                            {rowData?.yet_to_be_purchase}
                          </Typography>
                        </Card>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </div>
            }
            {tab === 1 &&
              <div className='pt-2'>
                <TableContainer className='Custom_Datatable'>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Referral Number</TableCell>
                        <TableCell>Buyer Name</TableCell>
                        <TableCell>Buyer Mobile</TableCell>
                        <TableCell>Seller Name</TableCell>
                        <TableCell>Seller Mobile</TableCell>
                        <TableCell>Requirments</TableCell>
                        <TableCell>Item Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modelRefList && modelRefList.map((row, index) => {
                        return (
                          <TableRow key={row?._id}>
                            <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.referral_number}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.buyer_name}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.buyer_mobile}</TableCell>
                            <TableCell style={{ minWidth: '100px' }}>{row?.seller_name}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.seller_mobile}</TableCell>
                            <TableCell style={{ minWidth: '250px' }}>{row?.requirments}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.item_name}</TableCell>
                            <TableCell style={{ minWidth: '100px' }}>{row?.status}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.created_at}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={modelTotalCount}
                  rowsPerPage={modelPageSize}
                  page={modelPage}
                  rowsPerPageOptions={[5, 10, 20]}
                  onChangePage={(e, page) => handleModelPageChange(page)}
                  onChangeRowsPerPage={(e) => setModelPageSize(e.target.value)}
                />
              </div>
            }
            {tab === 2 &&
              <div className='pt-2'>
                <TableContainer className='Custom_Datatable'>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Referral Number</TableCell>
                        <TableCell>Buyer Name</TableCell>
                        <TableCell>Buyer Mobile</TableCell>
                        <TableCell>Referrar Name</TableCell>
                        <TableCell>Referrar Mobile</TableCell>
                        <TableCell>Requirments</TableCell>
                        <TableCell>Item Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {modelRefList && modelRefList.map((row, index) => {
                        return (
                          <TableRow key={row?._id}>
                            <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.referral_number}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.buyer_name}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.buyer_mobile}</TableCell>
                            <TableCell style={{ minWidth: '100px' }}>{row?.referrar_name}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.referrar_mobile}</TableCell>
                            <TableCell style={{ minWidth: '250px' }}>{row?.requirments}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.item_name}</TableCell>
                            <TableCell style={{ minWidth: '100px' }}>{row?.status}</TableCell>
                            <TableCell style={{ minWidth: '150px' }}>{row?.created_at}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={modelTotalCount}
                  rowsPerPage={modelPageSize}
                  page={modelPage}
                  rowsPerPageOptions={[5, 10, 20]}
                  onChangePage={(e, page) => handleModelPageChange(page)}
                  onChangeRowsPerPage={(e) => setModelPageSize(e.target.value)}
                />
              </div>
            }
          </div>
          {/* <div>
            <table>
              <tr>
                <td style={{ width: '1.75in' }}><Typography><b>Name:</b></Typography></td>
                <td><Typography>{rowData?.name}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Mobile Number:</b></Typography></td>
                <td><Typography>{rowData?.mobile}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Referral Received:</b></Typography></td>
                <td><Typography>{rowData?.total_received_ref}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Purchased:</b></Typography></td>
                <td><Typography>{rowData?.purchased_ref}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Genuine Referral:</b></Typography></td>
                <td><Typography>{rowData?.geniuin_ref}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Fake Referral:</b></Typography></td>
                <td><Typography>{rowData?.fake_ref}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Deal Done:</b></Typography></td>
                <td><Typography>{rowData?.deal_done}</Typography></td>
              </tr>
              <tr>
                <td><Typography><b>Yet to be Purchase:</b></Typography></td>
                <td><Typography>{rowData?.yet_to_be_purchase}</Typography></td>
              </tr>
            </table>
          </div> */}
        </DialogContent>
      </Dialog>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default Seller_list
