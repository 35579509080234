import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Grow, InputLabel, Menu, MenuItem, Paper, Select, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Zoom } from '@material-ui/core';
import { useState } from 'react';
import Joi from 'joi';
import { createItemAction, deleteItemAction, getAllCsvItemsAction, getAllItemsAction, importCsvItemAction, searchItemAction, updateItemAction, updateItemStatusAction } from '../../store/item/asyncActions';
import { Autocomplete } from '@material-ui/lab';
import { searchCategoryAction } from '../../store/category/asyncActions';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { getAllPermissionAction } from '../../store/role/asyncActions';
import { exportCSV } from '../../helpers';
import CSVReader from 'react-csv-reader';
import { toast } from 'react-toastify';


const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    position: 'relative',
    clear: 'both'
  },
  appBar: {
    boxShadow: theme.shadows[0]
  }
}));

function ItemsList() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, setID] = useState(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();


  const [items, setItems] = useState(null);
  const [searchCat, setSearchCat] = useState(null);

  const itemList = useSelector((state) => state?.item?.getAllItems);
  const csvItemList = useSelector((state) => state?.item?.getAllCsvItems);
  const importItemList = useSelector((state) => state?.item?.getAllCsvImportItems);
  const categorySearch = useSelector((state) => state?.category?.searchCategory);
  const newItem = useSelector((state) => state?.item?.createItem);
  const updatedItem = useSelector((state) => state?.item?.updateItem);
  const updatedItemStatus = useSelector((state) => state?.item?.updateItemStatus);
  const deletedItem = useSelector((state) => state?.item?.deleteItem);

  const permissionsData = useSelector((state) => state?.role?.getAllPermissions);
  const [adminPermission, setAdminPermission] = useState(null);
  useEffect(() => {
    dispatch(getAllPermissionAction())
  }, []);
  useEffect(() => {
    if (permissionsData?.data?.status && Array.isArray(permissionsData?.data?.data)) {
      setAdminPermission(permissionsData?.data?.data)
    }
  }, [permissionsData]);

  useEffect(() => {
    dispatch(getAllItemsAction(page + 1, pageSize, filter));
  }, [page, pageSize]);

  useEffect(() => {
    document.title = 'Items - Referral App';
  }, []);

  useEffect(() => {
    if (itemList?.data?.status && Array.isArray(itemList?.data?.data?.records)) {
      setItems(itemList?.data?.data?.records);
      setTotalCount(itemList?.data?.data?.totalCount);
    }
  }, [itemList]);

  useEffect(() => {
    if (csvItemList?.data?.status && Array.isArray(csvItemList?.data?.data?.records)) {
      exportCSV(csvItemList?.data?.data?.records, 'items.csv');
      setCsvLoader(false);
      csvItemList.data = null;
    }
  }, [csvItemList]);

  useEffect(() => {
    if (importItemList?.data?.status) {
      setCsvLoader(false);
      dispatch(getAllItemsAction(page + 1, pageSize, filter));
      setFileData(null);
      setFileDetails(null);
      importItemList.data = null;
    }
  }, [importItemList]);

  useEffect(() => {
    if (categorySearch?.data?.status && Array.isArray(categorySearch?.data?.data)) {
      setSearchCat(categorySearch?.data?.data)
    }
  }, [categorySearch]);

  useEffect(() => {
    if (newItem?.data?.status) {
      setOpenModal(false);
      dispatch(getAllItemsAction(page + 1, pageSize, filter));
      resetForm();
      newItem.data = null;
    }
  }, [newItem]);

  useEffect(() => {
    if (updatedItem?.data?.status) {
      setOpenModal(false);
      dispatch(getAllItemsAction(page + 1, pageSize, filter));
      resetForm();
      updatedItem.data = null;
    }
  }, [updatedItem]);

  useEffect(() => {
    if (updatedItemStatus?.data?.status) {
      dispatch(getAllItemsAction(page + 1, pageSize, filter));
      updatedItemStatus.data = null;
    }
  }, [updatedItemStatus]);

  useEffect(() => {
    if (deletedItem?.data?.status) {
      dispatch(getAllItemsAction(page + 1, pageSize, filter));
      setDeleteModal(false);
      deletedItem.data = null;
    }
  }, [deletedItem]);

  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    name: '',
    category: '',
    status: ''
  });

  const filterHandleChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  }

  const filterApply = () => {
    dispatch(getAllItemsAction(page + 1, pageSize, filter));
  }

  const filterClear = () => {
    const reset = {
      name: '',
      category: '',
      status: ''
    }
    setFilter(reset);
    setFilterCategory(null);
    dispatch(getAllItemsAction(page + 1, pageSize, reset));
  }

  const [itemFormData, setItemFormData] = useState({
    item_name: '',
    category: '',
    description: '',
    price: null
  });

  const [category, setCategory] = useState(null);
  const [filterCategory, setFilterCategory] = useState(null);
  const [type, setType] = useState('service');

  useEffect(() => {
    dispatch(searchCategoryAction({ type: type, qry: '' }));
  }, [type]);

  const resetForm = () => {
    setErrors({});
    setCategory(null);
    setItemFormData({
      item_name: '',
      category: '',
      description: '',
      price: null
    });
  }

  const DataHandleChange = (e) => {
    setItemFormData({
      ...itemFormData,
      [e.target.name]: e.target.value
    });
  }

  const addNew = () => {
    resetForm();
    dispatch(searchCategoryAction({ type: 'service', qry: '' }))
    setOpenModal(true);
  }

  const editItem = (row) => {
    setErrors({});
    setItemFormData({
      id: row?._id,
      item_name: row?.item_name,
      category: row?.category,
      description: row?.description,
      price: row?.price
    });
    setType(row?.categoryData[0] ? row?.categoryData[0]?.type : 'service');
    setCategory(row?.categoryData[0] ? row?.categoryData[0] : null);
    setOpenModal(true);
  }

  const createItem = () => {
    dispatch(createItemAction(itemFormData))
  }

  const updateItem = () => {
    dispatch(updateItemAction(itemFormData))
  }

  const updateItemStatus = (data) => {
    {
      adminPermission && (adminPermission.includes('Edit Item') || adminPermission.includes('All')) &&
        dispatch(updateItemStatusAction(data))
    }
  }

  const save = () => {
    const validationResult = validationSchema.validate(itemFormData, { abortEarly: false });
    if (validationResult.error) {
      const validationErrors = {};
      validationResult.error.details.forEach((err) => {
        validationErrors[err.path[0]] = err.message;
      });
      setErrors(validationErrors);
    } else {
      setErrors({});
      if (itemFormData?.id && itemFormData?.id !== '' && itemFormData?.id !== null) {
        updateItem()
      } else {
        createItem()
      }
    }
  }

  const deleteItem = (id) => {
    dispatch(deleteItemAction({ id: id }));
  }

  const [errors, setErrors] = useState({});
  const validationSchema = Joi.object({
    id: Joi.string().allow(null, ''),
    item_name: Joi.string().required(),
    category: Joi.string().required(),
    description: Joi.string().allow(null, ''),
    price: Joi.number().required()
  });


  const [importItemModal, setImportItemModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [csvLoader, setCsvLoader] = useState(false);
  const [fileDetails, setFileDetails] = useState(null);
  const [fileData, setFileData] = useState(null);
  const exportCsvData = () => {
    setCsvLoader(true);
    dispatch(getAllCsvItemsAction(filter));
    setAnchorEl(null);
  }
  const handleCSVData = (data, fileInfo) => {
    if (fileInfo?.type !== 'text/csv') {
      toast.error('Invalid file format!');
      setAnchorEl(null);
      return;
    }
    if (data && Array.isArray(data)) {
      setFileData(data);
      setFileDetails(fileInfo);
      setAnchorEl(null);
    }
  };
  const importCsvData = () => {
    setImportItemModal(false);
    setCsvLoader(true);
    dispatch(importCsvItemAction({ itemData: fileData }));
  }
  const closeImpCsvModal = () => {
    setImportItemModal(false);
    setFileDetails(null);
    setFileData(null);
  }

  const itemsDemoCsv = () => {
    const demo = [
      {
        _id: "650af7e88a3ea2e1e91b880a",
        item_name: `"Inspection, measurement and testing equipment"`,
        description: `"description..."`,
        category: "650ae7b58f69cd6f4f48a965",
        price: 500
      },
      {
        _id: "650af7e88a3ea2e1e91b87f6",
        item_name: `"Coffee, Tea & Beverages"`,
        description: `"description..."`,
        category: "650ae7b58f69cd6f4f48a963",
        price: 500
      }
    ];
    exportCSV(demo, 'items_demo.csv');
  }

  return (
    <div>
      <Card className='pageCover'>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant='h5' color="textSecondary" className="flexSpacer">
              Items
            </Typography>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                color='default' variant='outlined' className='mr-1'
                aria-owns={anchorEl ? 'csv-menu' : null}
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                disabled={csvLoader}
              >
                Export / Import
                {csvLoader && <CircularProgress style={{ width: '20px', height: '20px' }} className='ml-1' />}
              </Button>
              <Menu
                id="csv-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => exportCsvData()}>Export CSV</MenuItem>
                <MenuItem style={{ width: '133px' }} onClick={() => setImportItemModal(true)}>
                  Import CSV
                </MenuItem>
              </Menu>
            </div>

            {adminPermission && (adminPermission.includes('Add Item') || adminPermission.includes('All')) &&
              <Tooltip title={'Add New Item'} placement="left">
                <Button color='default' variant='outlined' onClick={() => addNew()}>+ Add New</Button>
              </Tooltip>
            }
            <Tooltip title={'Apply Filter On Data'} placement="left">
              <Button color='default' variant='outlined' className='ml-1' onClick={() => setOpenFilter(!openFilter)}>
                <span className='mr-1'>Filter</span> {openFilter ? <ExpandLessRoundedIcon className='btnIco' /> : <ExpandMoreRoundedIcon className='btnIco' />}
              </Button>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box className={`filterOuter ${openFilter && 'activeOpen'}`}>
          <Grid container style={{ width: '100%', paddingTop: '25px' }}>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <TextField
                className='w-100'
                name='name'
                label="Name"
                variant="outlined"
                size="small"
                value={filter?.name}
                onChange={(e) => filterHandleChange(e)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <Autocomplete
                options={searchCat || []}
                getOptionLabel={(option) => option.category_name}
                value={filterCategory}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Category"
                    placeholder="Search name"
                    size='small'
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  dispatch(searchCategoryAction({ type: 'all', qry: newInputValue }))
                }}
                onChange={(event, selectedOptions) => {
                  setFilterCategory(selectedOptions);
                  setFilter({
                    ...filter,
                    category: selectedOptions?._id
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1'>
              <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select
                  name='status'
                  label="Status"
                  value={filter?.status}
                  onChange={(e) => filterHandleChange(e)}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={2} xl={2} className='mb-2 px-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button color='primary' variant='contained' onClick={() => filterApply()}>Apply</Button>
              <Button color='default' variant='outlined' className='ml-1' onClick={() => filterClear()}>Clear</Button>
            </Grid>
          </Grid>
        </Box>
        <Paper>
          <TableContainer className='Custom_Datatable'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Name</TableCell>
                  <TableCell style={{ minWidth: '75px' }}>Type</TableCell>
                  {/* <TableCell style={{ minWidth: '225px' }}>Description</TableCell> */}
                  <TableCell style={{ minWidth: '125px' }}>Category</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Price</TableCell>
                  <TableCell style={{ minWidth: '100px' }}>Status</TableCell>
                  <TableCell style={{ minWidth: '125px' }} align='right'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items && items.map((row, index) => {
                  return (
                    <TableRow key={row?._id}>
                      <TableCell>{index + 1 + (page) * pageSize}</TableCell>
                      <TableCell>{row?.item_name}</TableCell>
                      <TableCell>{row?.categoryData[0] ? row?.categoryData[0]?.type : 'service'}</TableCell>
                      {/* <TableCell>{row?.description}</TableCell> */}
                      <TableCell>
                        {row?.categoryData?.[0] ? row?.categoryData?.[0]?.category_name : null}
                      </TableCell>
                      <TableCell>{row?.price}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row?.status}
                              onChange={(e) => updateItemStatus({ id: row?._id, status: e.target.checked })}
                              color="primary"
                              disabled={!adminPermission || (!adminPermission.includes('Edit Item') && !adminPermission.includes('All'))}
                            />
                          }
                          label={row?.status ? 'Active' : 'Inactive'}
                        />
                      </TableCell>
                      <TableCell align='right'>
                        {adminPermission && (adminPermission.includes('Edit Item') || adminPermission.includes('All')) &&
                          <Tooltip title={'Edit Item'} placement="left">
                            <IconButton onClick={() => editItem(row)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        }
                        {adminPermission && (adminPermission.includes('Delete Item') || adminPermission.includes('All')) &&
                          <Tooltip title={'Delete Item'} placement="left">
                            <IconButton onClick={() => { setID(row?._id); setDeleteModal(true) }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={page}
            rowsPerPageOptions={[5, 10, 20]}
            onChangePage={(e, page) => handlePageChange(page)}
            onChangeRowsPerPage={(e) => setPageSize(e.target.value)}
          />
        </Paper>
      </Card>

      {/* Item form dialog */}
      <Dialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Slide}
      >
        <AppBar>
          <Toolbar>
            <IconButton color="inherit" onClick={() => setOpenModal(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" color="inherit" style={{ marginLeft: 'auto' }}>
              {itemFormData?.id ? 'Edit Item' : 'Create Item'}
            </Typography>
            <Button color="inherit" style={{ marginLeft: 'auto' }} onClick={() => save()}>
              {itemFormData?.id ? 'Update' : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ paddingTop: '75px', display: 'flex', justifyContent: 'center' }}>
          <Box>
            <Grid container style={{ maxWidth: '450px', paddingTop: '25px' }}>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='item_name'
                  label="Item Name"
                  variant="outlined"
                  size="small"
                  value={itemFormData?.item_name}
                  error={errors?.item_name}
                  helperText={errors?.item_name}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <FormControl variant="outlined" size="small" error={errors?.type} required style={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                  <Select
                    name='type'
                    label="Type"
                    value={type}
                    onChange={(e) => {
                      setType(e?.target?.value);
                      setCategory(null);
                      setItemFormData({
                        ...itemFormData,
                        category: ''
                      });
                    }}
                  >
                    <MenuItem value="service">Service</MenuItem>
                    <MenuItem value="product">Product</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.type}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <Autocomplete
                  options={searchCat || []}
                  getOptionLabel={(option) => option.category_name}
                  value={category}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Category"
                      placeholder="Search name"
                      size='small'
                      error={errors?.category}
                      helperText={errors?.category}
                    />
                  )}
                  onInputChange={(event, newInputValue) => {
                    dispatch(searchCategoryAction({ type: type, qry: newInputValue }))
                  }}
                  onChange={(event, selectedOptions) => {
                    setCategory(selectedOptions);
                    setItemFormData({
                      ...itemFormData,
                      category: selectedOptions?._id
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='price'
                  label="Price"
                  variant="outlined"
                  size="small"
                  type='number'
                  value={itemFormData?.price}
                  error={errors?.price}
                  helperText={errors?.price}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={12} className='mb-2 px-1'>
                <TextField
                  className='w-100'
                  required
                  name='description'
                  label="Description"
                  variant="outlined"
                  size="small"
                  rows={4}
                  multiline
                  value={itemFormData?.description}
                  error={errors?.description}
                  helperText={errors?.description}
                  onChange={(e) => DataHandleChange(e)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      {/* delete confirm dialog */}
      <Dialog
        open={deleteModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this record?
            This action is irreversible and will permanently remove the data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button color="primary" style={{ color: '#f44336' }} onClick={() => deleteItem(ID)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* import file dialog */}
      <Dialog
        open={importItemModal}
        TransitionComponent={Collapse}
        // keepMounted
        onClose={() => closeImpCsvModal()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Import Items
        </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: '25px' }}>
            <div className='CSVReaderInp' style={{ marginBottom: '10px' }}>
              <span className='FileNam'>
                {fileDetails && fileDetails?.name}
              </span>
              <Button color="primary" variant='contained' style={{ marginLeft: 'auto' }}>
                Browse
              </Button>
              <CSVReader
                onFileLoaded={handleCSVData}
                parserOptions={{ header: true }}
              />
            </div>
            <div className='SampleCsv'>
              Download <span onClick={() => itemsDemoCsv()}>Sample.csv</span>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="default" variant='contained' onClick={() => closeImpCsvModal()}>
            Cancel
          </Button>
          {fileDetails &&
            <Button color="primary" variant='contained' onClick={() => importCsvData()}>
              Import
            </Button>
          }
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default ItemsList
