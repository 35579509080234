export const GET_ALL_USERS_REF_COUNT_LOADING = 'GET_ALL_USERS_REF_COUNT_LOADING';
export const GET_ALL_USERS_REF_COUNT_SUCCESS = 'GET_ALL_USERS_REF_COUNT_SUCCESS';
export const GET_ALL_USERS_REF_COUNT_ERROR = 'GET_ALL_USERS_REF_COUNT_ERROR';

export const GET_ALL_SEARCH_USER_LOADING = 'GET_ALL_SEARCH_USER_LOADING';
export const GET_ALL_SEARCH_USER_SUCCESS = 'GET_ALL_SEARCH_USER_SUCCESS';
export const GET_ALL_SEARCH_USER_ERROR = 'GET_ALL_SEARCH_USER_ERROR';

export const UPDATE_USER_STATUS_LOADING = 'UPDATE_USER_STATUS_LOADING';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_ERROR = 'UPDATE_USER_STATUS_ERROR';
